import { CatalogType, IProductDetails } from '../../../models/interface'
import React, { FC } from 'react'
import _ from 'lodash'
import ProductAttributeValuesPanel from '../ProductAttributes'

type Props = {
  catalogType: CatalogType
  title: string
  product: IProductDetails
  showVariantOptions: () => void
  showVariants: () => void
}

const ProductOptionsContainer: FC<Props> = ({
  catalogType,
  title,
  product,
  showVariantOptions,
  showVariants,
}) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="font-bold text-gray-800/90">{title}</div>
        <div className="flex space-x-2">
          <button
            onClick={showVariantOptions}
            className="bg-pixie text-sm font-medium text-white/90 px-4 py-1 mt-4 rounded-md hover:bg-opacity-40">
            {product.attributes.length > 0 ? 'Manage' : 'Manage'}
          </button>
        </div>
      </div>
      {product.attributes.length > 0 ? (
        <>
          <div className="space-y-6 my-4">
            {_.concat(product.attributes)
              ?.sort(function (a, b) {
                return a.displayOrder > b.displayOrder ? 1 : -1
              })
              .map((productAttributeMapping) => {
                return (
                  <div key={productAttributeMapping.id}>
                    <span className="font-semibold text-gray-700 text-sm">
                      {productAttributeMapping.attribute?.name}
                    </span>
                    <ProductAttributeValuesPanel
                      productAttributeMapping={productAttributeMapping}
                    />
                  </div>
                )
              })}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProductOptionsContainer
