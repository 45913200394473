import React from 'react'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { Link, useHistory } from 'react-router-dom'
import MediaitemCard from './mediaitem-card.component'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { useMediaItems } from '../../hooks'

export const MediaPage = () => {
  const history = useHistory()
  const onAdd = () => history.push('/mediadetails/add', { add: true })
  const { data: items, loading } = useMediaItems()
  // const onImageEditor = () => history.push('/imageeditor')

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-auto">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex-grow">
          {loading ? (
            <LoadingIndicatorPanel message="Loading media items ..." />
          ) : (
            <>
              <CrudListHeader
                headerLabel={'MEDIA'}
                subheaderLabel={'Add Media'}
                addAction={onAdd}
              />
              {/*<button onClick={onImageEditor} className="focus:outline-none bg-transparent border-0 bg-pixie text-gray-100 font-medium text-sm rounded-md px-4 py-2">*/}
              {/*    Image Editor*/}
              {/*</button>*/}
              <div className="grid grid-cols-6 ">
                {items?.map((item) => (
                  <div key={item.id} className="cursor-pointer hover:border-gray-200">
                    <Link
                      to={{
                        pathname: `/mediadetails/${item.id}`,
                        state: { add: false },
                      }}>
                      <MediaitemCard key={item.id} item={item} />
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
