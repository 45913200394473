import React, {FC} from 'react';
import {ILoadingIndicator} from "../models/interface";
import { ReactComponent as LoadingSvg } from '../assets/images/loadingsvg/loading-bars.svg';
import {appConfigPrimaryColor} from "../config/appconfig";

const LoadingIndicator : FC<ILoadingIndicator> = ({size= "small", fill}) => {
    return (
        <div className="flex items-center justify-center p-4 bg-transparent">
            {/*<img className={size === "large" ? "w-20 h-20" : "w-12 h-12"} src={loadingImage} alt="loading" />*/}
            {/*<Loading type="bars" color="#d6372c" height={60} width={200} />*/}
            <LoadingSvg fill={appConfigPrimaryColor} height={60} width={200} />
        </div>
    )
}

export default LoadingIndicator;
