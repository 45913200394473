import React, { useContext, useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { LogisticsSlotDispatchContext, LogisticsSlotStateContext } from './LogisticsSlotsComponent'

const SlotsPerHourControl = () => {
  const logisticsSlotDispatchContext = useContext(LogisticsSlotDispatchContext)
  const logisticsSlotStateContext = useContext(LogisticsSlotStateContext)

  const [currentSlotsPerHourCount, setCurrentSlotsPerHourCount] = useState(1)

  useEffect(() => {
    logisticsSlotDispatchContext({ type: 'slotsPerHourChanged', slotsPerHourCount: currentSlotsPerHourCount })
  }, [currentSlotsPerHourCount])

  return (
    <div className="relative">
      <Listbox value={logisticsSlotStateContext.slotsPerHourCount} onChange={setCurrentSlotsPerHourCount}>
        <Listbox.Button>
          <div className="font-bold">Number of Slots: {logisticsSlotStateContext.slotsPerHourCount}</div>
        </Listbox.Button>
        <Listbox.Options>
          <div className="z-10 bg-white absolute w-48 border">
            {[1, 2, 3, 4].map((slotCount) => (
              <Listbox.Option key={slotCount} value={slotCount}>
                <div className="hover:bg-primary">{slotCount}</div>
              </Listbox.Option>
            ))}
          </div>
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export default SlotsPerHourControl
