import _ from "underscore";
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import WorkingHoursDay from "./components/working_hours_day";
import { getWorkingHoursSelectedRanges } from "./util";

// select working hours in half-hour intervals for each day of the week
const WorkingHours = (props) => {

    const [days, setDays] = useState([]);
    //const [data, setData] = useState(props.data);
    const [newState, setNewState] = useState({});
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectionOldCellStates, setSelectionOldCellStates] = useState([]);
    const [selectionState, setSelectionState] = useState(true);
    const [selectionFromCell, setSelectionFromCell] = useState(null);
    const [previousUpdateSelectionToCell, setPreviousUpdateSelectionToCell] = useState(null);
    const num = 48;

    useEffect(() => {
            setDays(_.map(props.days, (day, d) => ({
                timeCells: _.range(num).map(i => newTimeCellDefinition(d, i))
            })))
        }, [props.data]);

    const newTimeCellDefinition = (d, i) => {
        // restore saved values from database on init

        const data = (props.data || {})[props.days[d].key];
        //console.log(this.props.days[d].key, this.props.data, this.props.data['tue'], data)
        //const data = ['3.5', '4']
        console.log(data)

        // from and to hours, half-hour flag
        const [hourFrom, hourTo, isHalf] = [
            Math.floor(i / 2),
            Math.ceil(i / 2),
            i % 2 === 1
        ];

        // from and to times as floating point
        const [f0, f1] = [
            hourFrom + (isHalf ? 0.5 : 0),
            hourTo + (isHalf ? 0 : 0.5)
        ];

        //console.log(f0, f1, data[0], data[1], (data[0] <= f0 && data[1] >= f1) )

        return {
            // unique time cell id
            id: d * num + i,
            // day of the week (0-6)
            dayIndex: d,
            // time cell index in current day
            index: i,
            // initial selection state for cell
            selected:
                data === undefined
                    ? false
                    : _.any(
                    data,
                    ts =>
                        (ts[0] <= f0 && ts[1] >= f1) ||
                        (ts[0] > ts[1] &&
                            ((f0 >= ts[0] && f1 <= 24.0) || (f1 <= ts[1] && f0 >= 0)))
                    ),
            // is this a full-hour or half-hour cell
            hour: !isHalf,
            // from time as string (hh:mm)
            timeFrom:
                (hourFrom < 10 ? `0${hourFrom}` : hourFrom) +
                (isHalf ? ":30" : ":00"),
            // to time as string (hh:mm)
            timeTo:
                (hourTo < 10 ? `0${hourTo}` : hourTo) + (!isHalf ? ":30" : ":00")
        };
    };

    useEffect(() => {
        const mouseUpEventHandler = mouseUp;
        const mouseMoveEventHandler = mouseMove;
        const touchStartEventHandler = touchStart;
        const touchEndEventHandler = touchEnd;
        const touchMoveEventHandler = touchMove;
        document.addEventListener("mouseup", mouseUpEventHandler, false);
        document.addEventListener("mousemove", mouseMoveEventHandler, false);
        document.addEventListener("touchstart", touchStartEventHandler, {
            capture: false,
            passive: false
        });
        document.addEventListener("touchend", touchEndEventHandler, {
            capture: false,
            passive: false
        });
        document.addEventListener("touchmove", touchMoveEventHandler, {
            capture: false,
            passive: false
        });

        return () => {
            document.removeEventListener("mouseup", mouseUpEventHandler, false);
            document.removeEventListener(
                "mousemove",
                mouseMoveEventHandler,
                false
            );
            document.removeEventListener("touchstart", touchStartEventHandler, {
                capture: false,
                passive: false
            });
            document.removeEventListener("touchend", touchEndEventHandler, {
                capture: false,
                passive: false
            });
            document.removeEventListener("touchmove", touchMoveEventHandler, {
                capture: false,
                passive: false
            });
        }

    }, []);

    const mouseUp = (e) => {
        if (!isSelecting) return;

        endSelect();
        e.preventDefault();
    }

    const mouseMove= (e) => {
        if (!isSelecting) return;

        updateSelect(e.clientX, e.clientY);
        e.preventDefault();
    }

    const touchStart= (e) => {
        const x = e.targetTouches[0].clientX;
        const y = e.targetTouches[0].clientY;

        for (let d = 0; d < days.length; d += 1) {
            for (let i = 0; i < days[d].timeCells.length; i += 1) {
                const timecell = days[d].timeCells[i];
                const domNode = ReactDOM.findDOMNode(timecell.elementRef);
                const rc = domNode.getBoundingClientRect();

                if (y >= rc.y && y < rc.y + rc.height) {
                    if (x >= rc.x && x < rc.x + rc.width) {
                        startSelect(timecell, domNode);
                        e.preventDefault();
                        break;
                    }
                }
            }
        }
    }

    // trigger end selection when dragging
    const touchEnd = (e) => {
        if (!isSelecting) return;

        endSelect();
        e.preventDefault();
    }

    // trigger update selection when dragging
    const touchMove = (e) => {
        if (!isSelecting) return;

        updateSelectTouch(e);
        e.preventDefault();
    }

    // start time cell selection
    const startSelect = (state, el) => {
        setIsSelecting(true);

        setSelectionOldCellStates( _.map(days, day => ({
            timeCells: _.map(day.timeCells, item => ({
                index: item.index,
                selected: item.selected
            }))
        })));

        setSelectionFromCell(state)
        setSelectionState(!state.selected)

        updateSelectInternal(state, el);
    }

    // end time cell selection
    const endSelect = () => {
        setIsSelecting(false);
        setSelectionOldCellStates([]);
        setPreviousUpdateSelectionToCell(null);
    }

    // update time cell selection (get state for time cell closest to x-coordinate)
    const updateSelect = (x, y) => {
        for (let d = 0; d < days.length; d += 1) {
            for (let i = 0; i < days[d].timeCells.length; i += 1) {
                const timecell = days[d].timeCells[i];
                const domNode = ReactDOM.findDOMNode(timecell.elementRef);
                const rc = domNode.getBoundingClientRect();

                if (
                    (y >= rc.y && y < rc.y + rc.height) ||
                    (d === 0 && y < rc.y) ||
                    (d === days.length - 1 && y > rc.y + rc.height - 1)
                ) {
                    if (
                        (x >= rc.x && x < rc.x + rc.width) ||
                        (i === 0 && x < rc.x) ||
                        (i === days[d].timeCells.length - 1 &&
                            x > rc.x + rc.width - 1)
                    ) {
                        updateSelectInternal(days[d].timeCells[i], domNode);
                        break;
                    }
                }
            }
        }
    }

    // update time cell selection (from touch event)
    const updateSelectTouch = (e) => {
        updateSelect(e.targetTouches[0].clientX, e.targetTouches[0].clientY);
    }

    // update time cell selection based on start and end elements (state)
    const updateSelectInternal = (state) => {
        if (state === previousUpdateSelectionToCell) return;
        setPreviousUpdateSelectionToCell(state)

        const [fromCell, toCell] = [selectionFromCell, state];
        let [fromY, toY, fromX, toX] = [
            fromCell.dayIndex,
            toCell.dayIndex,
            fromCell.index,
            toCell.index
        ];

        // swap from and to, if from > to
        if (fromY > toY) [fromY, toY] = [toY, fromY];
        if (fromX > toX) [fromX, toX] = [toX, fromX];

        // set selection status for all time cells
        // current selection is set based on the state of the start element
        // time cells not in current selection are reset to cached state
        const newState = {
            days: _.map(days, d => ({
                timeCells: _.map(d.timeCells, c => Object.assign({}, c))
            }))
        };
        for (let d = 0; d < days.length; d += 1) {
            for (let i = 0; i < days[d].timeCells.length; i += 1) {
                const oldState = selectionOldCellStates[d].timeCells[i];

                if (d >= fromY && d <= toY && i >= fromX && i <= toX) {
                    newState.days[d].timeCells[i].selected = selectionState;
                } else {
                    newState.days[d].timeCells[i].selected = oldState.selected;
                }
            }
        }

        setNewState(newState)
        props.onSlotsUpdated()
    }

    // reset working hours for every day of the week
    const resetAll = (e) => {
        const newState = {
            days: _.map(days, d => ({
                timeCells: _.map(d.timeCells, c => Object.assign({}, c))
            }))
        };
        for (let d = 0; d < days.length; d += 1) {
            for (let i = 0; i < days[d].timeCells.length; i += 1) {
                newState.days[d].timeCells[i].selected = false;
            }
        }

        setNewState(newState)
        props.onSlotsUpdated()
        e.preventDefault();
    }

    // reset working hours for one given day of the week
    const resetDay = (e, index) => {
        const newState = {
            days: _.map(days, d => ({
                timeCells: _.map(d.timeCells, c => Object.assign({}, c))
            }))
        };
        for (let i = 0; i < days[index].timeCells.length; i += 1) {
            newState.days[index].timeCells[i].selected = false;
        }

        setNewState(newState)

        e.preventDefault();
    }

    // render days
    const renderDays = _.map(props.days, (day, i) => (
        days.length > 0
            ? <WorkingHoursDay
            key={day.name}
            name={day.name}
            index={i}
            timeCells={days[i].timeCells}
            resetDay={resetDay}
            startSelect={startSelect}
        />
            : <></>
    ));

    // render headers
    const timeHeaders = _.chain(_.range(24))
        .map(i => [
            <td key={i} className="header">
                <span>{i < 10 ? `0${i}` : i}</span>
            </td>,
            <td key={`${i}-part`} className="header part">
                <span>30</span>
            </td>
        ])
        .flatten()
        .value();

        // translate time cell indices to actual timespan ranges
        const dayWorkingHoursOutputs = [];
        for (let d = 0; d < days.length; d += 1) {
            const timeCells = days[d].timeCells;
            const ranges = getWorkingHoursSelectedRanges(timeCells);
            const day = props.days[d];

            if (ranges.length === 0) {
                dayWorkingHoursOutputs.push(
                    <input
                        key={`who-${day.name}-0-from-hours`}
                        name={`${props.fieldName}[${day.key}]`}
                        type="hidden"
                        value=""
                    />
                );
            }

        _.each(ranges, (r, index) => {
            const from = timeCells[r.start].timeFrom.split(":");
            const to = timeCells[r.end].timeTo.split(":");
            dayWorkingHoursOutputs.push(
                <input
                    key={`who-${day.name}-${index}-from-hours`}
                    name={`${props.fieldName}[${day.key}][][from][hours]`}
                    type="hidden"
                    value={from[0]}
                />
            );
            dayWorkingHoursOutputs.push(
                <input
                    key={`who-${day.name}-${index}-from-minutes`}
                    name={`${props.fieldName}[${day.key}][][from][minutes]`}
                    type="hidden"
                    value={from[1]}
                />
            );
            dayWorkingHoursOutputs.push(
                <input
                    key={`who-${day.name}-${index}-to-hours`}
                    name={`${props.fieldName}[${day.key}][][to][hours]`}
                    type="hidden"
                    value={to[0]}
                />
            );
            dayWorkingHoursOutputs.push(
                <input
                    key={`who-${day.name}-${index}-to-minutes`}
                    name={`${props.fieldName}[${day.key}][][to][minutes]`}
                    type="hidden"
                    value={to[1]}
                />
            );
        });

        return (
            <div className="w-full">
                <table className="working-hours">
                    <thead>
                    <tr className="hours">
                        <td />
                        {timeHeaders}
                        <td />
                        <td />
                    </tr>
                    </thead>
                    <tbody>
                    {days}
                    <tr>
                        <td className="reset-all" colSpan="49">
                            <button
                                className="bg-pixie border border-transparent rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-xs font-medium text-white hover:bg-pixie hover:bg-opacity-50 focus:outline-none"
                                onClick={resetAll}
                            >
                                Reset All
                            </button>
                        </td>
                        <td />
                    </tr>
                    </tbody>
                </table>
                {dayWorkingHoursOutputs}
            </div>
        );
    }
}

WorkingHours.propTypes = {
    fieldName: PropTypes.string.isRequired,
    days: PropTypes.array.isRequired,
    data: PropTypes.object,
    onSlotsUpdated: PropTypes.func
};

export default WorkingHours;
