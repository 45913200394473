import { IAppConfig } from '../models/interface'
import { WhiteLabelConfig } from '../Utils/api/api.config'
import { defaultNavigation } from '../menus/menuNavigation'

const HourMarketAppConfig: IAppConfig = {
  identifier: 'HourMarket',
  showIcon: false,
  showLogo: true,
  icon: undefined,
  logo: 'logo-hourmarket.png',
  primaryColor: '#D4019C',
  // icon: 'icon-pixie.png',
  // logo: 'logo-pixie1.png',
  // primaryColor: '#d6372c',
  logoAsName: undefined,
  eventsEnabled: true,
  productCatalogEnabled: true,
  fulfillmentEnabled: true,
  collectionsEnabled: true,
  localDeliveryEnabled: true,
  nationalDeliveryEnabled: true,
  isIFramed: false,
  menuNavigation: defaultNavigation,
}

const PixieAppConfig: IAppConfig = {
  identifier: 'Pixie',
  showIcon: true,
  showLogo: true,
  icon: 'icon-pixie.png',
  logo: 'logo-pixie1.png',
  logoAsName: undefined,
  primaryColor: '#d6372c',
  eventsEnabled: false,
  productCatalogEnabled: true,
  fulfillmentEnabled: true,
  collectionsEnabled: true,
  localDeliveryEnabled: true,
  nationalDeliveryEnabled: true,
  isIFramed: false,
  menuNavigation: defaultNavigation,
}

const RoundAppConfig: IAppConfig = {
  identifier: 'Round',
  showIcon: true,
  showLogo: true,
  icon: '',
  logo: 'logo-round.png',
  logoAsName: undefined,
  primaryColor: '#FD3808',
  eventsEnabled: false,
  productCatalogEnabled: true,
  fulfillmentEnabled: false,
  collectionsEnabled: false,
  localDeliveryEnabled: false,
  nationalDeliveryEnabled: false,
  isIFramed: false,
  menuNavigation: defaultNavigation,
}

const GlowDayAppConfig: IAppConfig = {
  identifier: 'GlowDay',
  showIcon: true,
  showLogo: true,
  icon: undefined,
  logo: 'logo-glowday.svg',
  logoAsName: undefined,
  primaryColor: '#B99435',
  eventsEnabled: true,
  productCatalogEnabled: true,
  fulfillmentEnabled: true,
  collectionsEnabled: true,
  localDeliveryEnabled: true,
  nationalDeliveryEnabled: true,
  isIFramed: false,
  menuNavigation: defaultNavigation,
}

const UnDefinedAppConfig: IAppConfig = {
  identifier: 'Undefined',
  showIcon: false,
  showLogo: false,
  icon: '',
  logo: '',
  logoAsName: undefined,
  primaryColor: '#FD3808',
  eventsEnabled: false,
  productCatalogEnabled: false,
  fulfillmentEnabled: false,
  collectionsEnabled: false,
  localDeliveryEnabled: false,
  nationalDeliveryEnabled: false,
  isIFramed: false,
  menuNavigation: defaultNavigation,
}

const configs: IAppConfig[] = [
  HourMarketAppConfig,
  PixieAppConfig,
  RoundAppConfig,
  GlowDayAppConfig,
]
export const AppConfig: IAppConfig =
  configs.find((config) => config.identifier === WhiteLabelConfig) ?? UnDefinedAppConfig

export const appConfigPrimaryColor: string = AppConfig.primaryColor
export const appConfigShowIcon = AppConfig.showIcon && AppConfig.icon
export const appConfigShowLogo = AppConfig.showLogo && AppConfig.logo
export const appConfigIcon = `${process.env.PUBLIC_URL}/${AppConfig.icon}`
export const appConfigLogo = `${process.env.PUBLIC_URL}/${AppConfig.logo}`
export const appConfigEventsEnabled = AppConfig.eventsEnabled
export const appConfigProductCatalogEnabled = AppConfig.productCatalogEnabled
export const appConfigFulFilmentEnabled = AppConfig.fulfillmentEnabled
export const appConfigCollectionsEnabled = AppConfig.collectionsEnabled
export const appConfigLocalDeliveryEnabled = AppConfig.localDeliveryEnabled
export const appConfigNationalDeliveryEnabled = AppConfig.nationalDeliveryEnabled
export const appIsIFramedEnabled = AppConfig.isIFramed
