import { FC, useState } from 'react'

interface Props {
  id: string | undefined
  label: string | undefined
  isChild: boolean
  selected: boolean
  disabled?: boolean
  onSelected?: (id: string, isSelected: boolean) => void
}

const ProductTypeLozenge: FC<Props> = ({
  id,
  label,
  isChild,
  selected,
  onSelected,
  disabled = false,
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(selected)

  const clicked = () => {
    const newValue = !isSelected
    setIsSelected(newValue)
    if (onSelected && id) {
      onSelected(id, newValue)
    }
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className={[
        'px-6 py-2  rounded-md',
        isSelected
          ? 'bg-pixie hover:bg-pixie hover:bg-opacity-60'
          : 'bg_white border border-gray-300 hover:bg-gray-100',
        isChild
          ? 'bg_white border border-gray-300 hover:bg-gray-100'
          : 'bg_white border border-gray-300 hover:bg-gray-100',
      ].join(' ')}
      onClick={clicked}>
      <div
        className={[
          'font-semibold text-sm',
          isChild ? 'text-gray-700' : 'text-gray-700',
          isSelected ? 'text-white' : null,
        ].join(' ')}>
        {label}
      </div>
    </button>
  )
}

export default ProductTypeLozenge
