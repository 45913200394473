import React, { useEffect, useRef, useState } from 'react'
//import "./working_hours.scss";
import WorkingHours from '../../components/working_hours'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { ICreateLogisticSlots } from '../../models/interface'

const Logistics = () => {
  return (
    <div className="m-10 ml-16 max-w-7xl p-4 rounded-md">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 sm:p-6">
            <div>
              <h1 className="text-xl leading-6 font-black text-gray-900">Logistics Information</h1>
              <p className="mt-1 text-sm text-gray-500">Set your delivery and collection slots.</p>
            </div>
            <Delivery />
            <Collection />
          </div>
        </div>
      </div>
    </div>
  )
}

const Delivery = () => {
  let days = [
    { key: 'mon', name: 'Monday' },
    { key: 'tue', name: 'Tuesday' },
    { key: 'wed', name: 'Wednesday' },
    { key: 'thu', name: 'Thursday' },
    { key: 'fri', name: 'Friday' },
    { key: 'sat', name: 'Saturday' },
    { key: 'sun', name: 'Sunday' },
  ]

  let data = {}
  let fieldName = 'location[working_hours]'

  return (
    <div className="mt-10">
      <h2 className="text-lg leading-6 font-bold text-gray-800">Delivery</h2>
      <WorkingHours days={days} fieldName={fieldName} data={data} />
    </div>
  )
}

const Collection = () => {
  const [slots, setSlots] = useState([])
  // const [data, setData] = useState({
  //     'mon': [['4.5', '5.0']],
  //     'wed': [[7.0, 7.5],[10.5, 16.0]]
  // });
  const [data, setData] = useState({
    mon: [['4.5', '5.0']],
    tue: [['3.5', '4']],
    wed: [
      [7.0, 7.5],
      [10.5, 16.0],
    ],
  })

  useEffect(() => {
    hourMarketApi.getLogisticSlots().then((r) => {
      const slotData = r.data[0].slots.map((item) => {
        return {
          dayIndex: item.day,
          timeFrom: item.timeFrom,
          timeTo: item.timeTo,
        }
      })

      let groupedSlots = slotData?.reduce((r, a) => {
        r[a.dayIndex] = [...(r[a.dayIndex] || []), a]
        return r
      }, {})

      setData({
        mon: [['4.5', '5.0']],
        tue: [['3.5', '4']],
        wed: [
          [7.0, 7.5],
          [10.5, 16.0],
        ],
      })
    })
  }, [])

  let days = [
    { key: 'mon', name: 'Monday' },
    { key: 'tue', name: 'Tuesday' },
    { key: 'wed', name: 'Wednesday' },
    { key: 'thu', name: 'Thursday' },
    { key: 'fri', name: 'Friday' },
    { key: 'sat', name: 'Saturday' },
    { key: 'sun', name: 'Sunday' },
  ]

  let fieldName = 'location[working_hours]'

  const myRef = useRef()

  const saveSlots = () => {
    const slots = myRef.current.state.days
      .flatMap((x) => x.timeCells)
      .filter((x) => x.selected)
      .map((item) => {
        return {
          Day: item.dayIndex,
          TimeFrom: item.timeFrom,
          TimeTo: item.timeTo,
        }
      })

    const dataToSave = {
      ScheduleType: 1,
      DateFrom: '2021-04-12',
      DateTo: '2021-05-12',
      slots: slots,
    }

    setSlots(slots)

    hourMarketApi.createLogisticSlots(dataToSave).then((r) => console.log(r.data))
  }

  const onSlotsUpdated = () => {
    const newSlots = myRef?.current?.state.days.flatMap((x) => x.timeCells).filter((x) => x.selected)
    setSlots(newSlots)
  }

  return (
    <div className="mt-10">
      <h2 className="text-lg leading-6 font-bold text-gray-800">Collection</h2>
      <WorkingHours ref={myRef} days={days} fieldName={fieldName} data={data} onSlotsUpdated={onSlotsUpdated} />
      <div className="text-sm">Slots: {slots.length}</div>
      <div className="flex justify-end mt-10">
        <button
          onClick={saveSlots}
          className="bg-pixie border border-transparent rounded-md shadow-sm py-1 px-2 inline-flex justify-center text-sm font-medium text-white hover:bg-pixie hover:bg-opacity-50 focus:outline-none">
          Save
        </button>
      </div>
    </div>
  )
}

export default Logistics
