import React, { FC } from 'react'
import ProductTypeLozenge from './productTypeLozenge'
import { IProductTypeTree } from '../../../models/interface'

interface Props {
  productType: IProductTypeTree
  onSelected: (id: string | undefined, selected: boolean) => void
  selected?: boolean
}

const ProductTypeTreeItem: FC<Props> = ({ productType, onSelected, selected = false }) => {
  return (
    <div className="mr-2 mt-2">
      <ProductTypeLozenge
        key={productType.id}
        label={productType.title}
        id={productType.id}
        onSelected={onSelected}
        isChild={false}
        selected={selected}
      />
    </div>
  )
}

export default ProductTypeTreeItem
