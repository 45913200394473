import React, {useEffect, useState} from 'react';
import {defaultDeliverySettings, IDeliverySettings} from "../../../models/interface";
import {hourMarketApi} from "../../../Utils/api/hourmarket-api";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {useForm} from "react-hook-form";
import SaveButton from "../../../components/SaveButton";
import {
    InputCheckBoxElement,
    InputNumberElement,
    InputTextElement
} from "../../../components/form-elements/input-element";
import CancelButton from "../../../components/CancelButton";

const NationalDelivery = () => {
    const [loading, setLoading] = useState(true)
    const { register, handleSubmit, reset, formState } = useForm<IDeliverySettings>({defaultValues: defaultDeliverySettings})

    useEffect(() => {
        getDeliverySettings()
    }, [])

    const getDeliverySettings = () => {
        setLoading(true);
        hourMarketApi.getDeliverySettings().then((r) => {
            if (r.status === 200) {
                reset(r.data)
            }
        }).finally(() => setLoading(false))
    }

    const onSubmit = (data: any) => {
        return hourMarketApi
            .saveDeliverySettings(data)
            .then( () => reset(data))
    }

    const disabled =  (!(formState.isDirty && Object.keys(formState.dirtyFields).length > 0 )) || formState.isSubmitting

    return (
        <div className="flex flex-col flex-grow w-full overflow-hidden">
            <div className="flex-1 overflow-y-scroll">
                <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md">
                    <h1 className="text-xl font-bold text-gray-900 mb-6">
                        National Delivery
                    </h1>
                    <div className="p-4 bg-white rounded-md">
                        {loading
                            ? <LoadingIndicator size={"small"} fill={true}/>
                            :
                            <div>
                                <InputCheckBoxElement label={"We offer a national delivery service"} id={"offerNationalDelivery"} register={register("offerNationalDelivery")} />
                                <InputTextElement label="Delivery Notes" id={"nationalDeliveryNotes"} register={register("nationalDeliveryNotes")} rows={3} />
                                <InputNumberElement label={"Minimum Order Value (£)"} id={"nationalDeliveryMinAmount"} register={register("nationalDeliveryMinAmount")} />
                                <InputNumberElement label={"Delivery Charge (£)"} id={"nationalDeliveryCharge"} register={register("nationalDeliveryCharge")} />
                                <InputCheckBoxElement label={"Enable Delivery Free Above Value"} id={"nationalDeliveryOfferFreeAbove"} register={register("nationalDeliveryOfferFreeAbove")} />
                                <InputNumberElement label={"Delivery Free Above Value (£)"} id={"nationalDeliveryFreeAbove"} register={register("nationalDeliveryFreeAbove")} />
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* Footer */}
            {!loading && <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
                <div className="flex space-x-4 justify-end">
                    <CancelButton
                        disabled={false}
                        isCancelling={false}
                        onCancel={() => console.log("cancel")}
                    />
                    <SaveButton
                        disabled={disabled}
                        isSubmitting={formState.isSubmitting}
                        onSubmit={handleSubmit(onSubmit)}
                    />
                </div>
            </div>}
        </div>
    )
}

export {
    NationalDelivery
}
