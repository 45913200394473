import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'

export interface ICategory {
  id?: string
  title?: string
  description?: string
  displayOrder: number
  active?: boolean
  parentId?: string
  image?: string
  thumbImage?: string
  mediaItems?: IMediaItem[]
}

export interface ICategoryTree {
  id?: string
  title?: string
  description?: string
  displayOrder: number
  active?: boolean
  parentId?: string
  image?: string
  thumbImage?: string
  mediaItems?: IMediaItem[]
  childCategories?: ICategoryTree[]
}

export interface IProductAttribute {
  id?: string
  name?: string
  description?: string
}

export interface IProduct {
  id?: string
  categoryId?: string
  name?: string
  description?: string
  image?: string
  thumbImage?: string
  price?: number
  displayOrder?: number
  activeDescription?: string
  activeFrom?: string
  activeTo?: string
  locationCoordinates?: string
  locationDescription?: string
  productCode?: string
  productType?: string
  mediaItems?: IMediaItem[]
  categories?: ICategory[]
  attributes?: IProductAttributeMapping[]
}

export interface ICategoryProducts {
  category?: ICategory
  products?: IProduct[]
}

export interface ICrudHeaderList {
  headerLabel: string
  headerIcon: IconDefinition
  subheaderLabel: string
  addLinkTo: string
}

export interface LocationState {
  add: boolean | undefined
}

export interface IMediaItemUpload {
  MediaType: number
  Title: string
  Description?: string
  ContentType?: string
  Filename?: string
  MediaFile: Blob
}

export interface IEntityMediaItemUpload {
  Id: string
  MediaFile: Blob
  MediaType: number
  Title: string
  Description?: string
  ContentType?: string
  Filename?: string
  DisplayOrder?: number
}

export interface IEntityMediaItemDelete {
  ProductId: string
  MediaItemId: string
}

export interface IMediaItemUploadResponse extends IMediaItem {}

export interface IDeleteCategoryMediaItem {
  categoryId: string
  mediaItemId: string
}

export interface ITenant {
  id?: string
  name?: string
  title?: string
  description?: string
  category1?: string
  category2?: string
  category3?: string
  image?: string
  mediaItems?: IMediaItem[]
}

export interface ITenantDetails {
  id?: string
  name?: string
  title?: string
  description?: string
  category1?: string
  category2?: string
  category3?: string
  image?: string
  mediaItems?: IMediaItem[]
  settings?: ITenantSetting[]
  logo?: IMediaItem
  paymentSettings?: IPaymentSettings
  openingHours?: IBusinessOpeningHours
}

export interface IDeleteTenantMediaItem {
  MediaItemId: string
}

export interface ITenantSetting {
  key: string
  value: string
}

export interface ITenantUpdate {
  Name?: string
  Title?: string
  Description?: string
  Category1?: string
  Category2?: string
  Category3?: string
}

export interface IAuthToken {
  unique_name: string
  AppUserId: string
  TenantId: string
}

export interface IRegister {
  username: string
  email: string
  password: string
  name: string
  description: string
  location: string
  postcode: string
}

export interface IShopifyConnection {
  shopId?: string
  name?: string
  url?: number
  authorised?: boolean
}

export interface IShopifyConnectionAuth {
  authurl: string
}

export interface IShopifyShopInfo {
  name: string
  domain: string
  city: string
}

export interface IShopifyCustomCatalog {
  customCollection: IShopifySmartCollection
  products: Array<IShopifyProduct>
}

export interface IShopifySmartCatalog {
  smartCollection: IShopifySmartCollection
  products: Array<IShopifyProduct>
}

export interface IShopifySmartCollection {
  id: number
  image?: IShopifyImage
  title?: string
  handle?: string
}

export interface IShopifyProduct {
  id: number
  image?: IShopifyImage
  title?: string
  handle?: string
  images: Array<IShopifyProductImage>
}

export interface IShopifyProductImage {
  productId?: number
  src?: string
}

export interface IShopifyApiKeys {
  id?: string
  accessToken?: string
  shopifyShopId?: string
}

export interface IShopifyApiKeysResponse {}

export interface IShopifyImage {
  alt?: string
  src?: string
  height: number
  width: number
}

export interface ICreateProductAttributeMapping {
  ProductId: string
  ProductAttributeId: string
}

export interface IProductAttributeMapping {
  id: string
  productId?: string
  displayOrder: number
  displayType?: string
  attribute?: IProductAttribute
  attributeValues: IProductAttributeValue[]
}

export interface IProductAttribute {
  id?: string
  name?: string
  description?: string
}

export interface IProductAttributeValue {
  id?: string
  name?: string
  priceAdjustment?: number
  displayOrder: number
  colorSquaresRgb: string
  imageSquaresPictureId: string
}

export interface ICreateProductAttributeValues {
  Id: string
  attributeValues: IProductAttributeValue[]
}

export interface ICreateLogisticSlots {
  Id?: string
  ScheduleType: number
  SlotsPerHour: number
  DateFrom: string
  DateTo: string
  Slots: ILogisticSlot[]
}

export interface IGetCollectionDeliverySlotsByWeek {
  ScheduleType: number
  DateFrom: string
}

export interface IGetCollectionDeliveryTemplate {
  ScheduleType: number
}

export interface ICreateLogisticSlotsResponse {
  Id: string
  ScheduleType: number
  DateFrom: string
  DateTo: string
  Slots: ILogisticSlot[]
}

export interface IGetLogisticSlotsResponse {
  id: string
  scheduleType: number
  slotsPerHour: number
  dateFrom: string
  dateTo: string
  slots: ILogisticSlot[]
}

export interface ILogisticSlot {
  id?: string
  day: number
  date: string
  timeFrom: string
  timeTo: string
}

export interface ILoadingIndicator {
  size: string
  fill: boolean
}

export interface IDeliverySettings {
  offerLocalDelivery: boolean
  offerLocalDeliverySlots: boolean
  localDeliveryLeadTimeInMins: number
  localDeliveryNotes: string
  localDeliveryMinAmount: number
  localDeliveryCharge: number
  localDeliveryOfferFreeAbove: boolean
  localDeliveryFreeAbove: number
  offerCollection: boolean
  collectionLocation: string
  offerCollectionSlots: boolean
  collectionLeadTimeInMins: number
  collectionNotes: string
  offerNationalDelivery: boolean
  nationalDeliveryNotes: string
  nationalDeliveryMinAmount: number
  nationalDeliveryCharge: number
  nationalDeliveryOfferFreeAbove: boolean
  nationalDeliveryFreeAbove: number
}

export interface ILoyaltySetting {
  loyaltyPercentage?: number
}

export interface IIsInDeliveryArea {
  IsInArea: boolean
}

export interface IDeliveryPolygon {
  Polygon: IDeliveryLocation[]
}

export interface IDeliveryLocation {
  Latitude: number
  Longitude: number
}

export interface IOrder {
  id: string
  created: string
  orderNumber: string
  orderStatusId: number
  orderStatus: number
  shippingStatus: number
  paymentStatus: number
  orderTotal: number
  shippingMethod: string
  billingAddress: IAddress
  shippingAddress: IAddress
  orderItems: IOrderItem[]
}

export interface IOrderItem {
  id: string
  product: IProduct
  quantity: number
  unitPriceInclTax: number
}

export interface IExperience {
  id?: string
  created?: string
  title?: string
  image?: string
  subTitle?: string
  description?: string
  startDateTime?: string
  endDateTime?: string
  price?: number
  eventAttendanceMode?: string
  maximumAttendeeCapacity?: number
  mediaItems?: IMediaItem[]
  attributes?: IExperienceAttributeMapping[]
}

export interface IExperienceTag {
  id?: string
  name?: String
}

export interface IExperienceMediaItemDelete {
  ExperienceId: string
  MediaItemId: string
}

export interface IExperienceAttribute {
  id?: string
  name?: string
  description?: string
}

export interface ICreateExperienceAttributeMapping {
  experienceId: string
  experienceAttributeId: string
}

export interface IExperienceAttributeMapping {
  id: string
  experienceId?: string
  displayOrder: number
  attribute?: IExperienceAttribute
  attributeValues: IExperienceAttributeValue[]
}

export interface IExperienceAttributeValue {
  id?: string
  name?: string
  priceAdjustment?: number
  displayOrder: number
}

export interface ICreateExperienceAttributeMapping {
  experienceId: string
  experienceAttributeId: string
}

export interface ICreateExperienceAttributeValues {
  Id: string
  attributeValues: IExperienceAttributeValue[]
}

export const defaultDeliverySettings: IDeliverySettings = {
  offerLocalDelivery: false,
  offerLocalDeliverySlots: false,
  localDeliveryLeadTimeInMins: 0,
  localDeliveryNotes: '',
  localDeliveryMinAmount: 0,
  localDeliveryCharge: 0,
  localDeliveryOfferFreeAbove: false,
  localDeliveryFreeAbove: 0,
  offerCollection: false,
  collectionLocation: '',
  offerCollectionSlots: false,
  collectionLeadTimeInMins: 0,
  collectionNotes: '',
  offerNationalDelivery: false,
  nationalDeliveryNotes: '',
  nationalDeliveryMinAmount: 0,
  nationalDeliveryCharge: 0,
  nationalDeliveryOfferFreeAbove: false,
  nationalDeliveryFreeAbove: 0,
}

export interface IMediaItem {
  id?: string
  created: string
  title?: string
  description?: string
  altText?: string
  location?: string
  filename?: string
  mediaType?: string
  displayOrder: number
  size: number
}

export const defaultCategory: ICategory = {
  title: '',
  displayOrder: 0,
  active: true,
}

export const defaultExperience: IExperience = {
  title: '',
  subTitle: '',
  description: '',
  startDateTime: `${moment(new Date()).format()}`,
  endDateTime: `${moment(new Date()).format()}`,
  eventAttendanceMode: 'OfflineEventAttendanceMode',
}

export interface IExperienceSortImages {
  ExperienceId?: string
  SortedMediaItems?: IExperienceSortImage[]
}

export interface IExperienceSortImage {
  MediaItemId: string
  DisplayOrder: number
}

export interface IExperienceSortImages {
  ExperienceId?: string
  SortedMediaItems?: IExperienceSortImage[]
}

export interface IProductSortImages {
  ProductId?: string
  SortedMediaItems?: IProductSortImage[]
}

export interface IProductSortImage {
  MediaItemId: string
  DisplayOrder: number
}

export interface ICredentials {
  username: string
  password: string
}

export interface ILoginResponse {
  id: string
  jwtToken: string
  tenantId: string
  refreshToken: string
}

export enum ProductStatus {
  Active = 'Active',
  Draft = 'Draft',
}

export interface IProductDetails {
  id?: string
  catalogType: string
  categoryId?: string
  name?: string
  description?: string
  image?: string
  thumbImage?: string
  price?: number
  displayOrder?: number
  active?: boolean
  activeDescription?: string
  activeFrom?: string
  activeTo?: string
  locationCoordinates?: string
  locationDescription?: string
  productCode?: string
  productType?: string
  productStatus?: string
  doesNotRequireBooking?: boolean
  mediaItems?: IMediaItem[]
  attributes: IProductAttributeMapping[]
  categories: ICategory[]
  variants: IProductVariant[]
  relatedProducts: IRelatedProduct[]
  productTypes?: IProductType[]
  venue?: IVenue
  inventoryStrategy?: number
  inventoryStartQuantity?: number
  inventoryQuantity?: number
  inventoryAllocatedQuantity?: number
}

export const defaultProduct: IProductDetails = {
  catalogType: '',
  name: '',
  description: '',
  categoryId: '',
  price: 0,
  image: '',
  active: true,
  productStatus: ProductStatus.Active,
  mediaItems: [],
  attributes: [],
  categories: [],
  variants: [],
  relatedProducts: [],
}

export const defaultProductAttribute: IProductAttribute = {
  name: '',
  description: '',
}

export interface ICategorySortImages {
  CategoryId?: string
  SortedMediaItems?: ICategorySortImage[]
}

export interface ICategorySortImage {
  MediaItemId: string
  DisplayOrder: number
}

export interface IProductVariant {
  id?: string
  name?: string
  sku?: string
  displayOrder?: number
  price?: number
  salePrice?: number
  width?: number
  height?: number
  depth?: number
  cost?: number
  msrp?: number
  inStock?: boolean
  upc?: string
  binPickingNumber?: string
  mpn?: string
  tag?: string
  mediaItem?: IMediaItem
  attributeValues?: IProductAttributeValue[]
}

export const defaultProductVariant: IProductVariant = {
  id: '',
  name: '',
  sku: '',
  displayOrder: undefined,
  price: undefined,
  salePrice: undefined,
  width: undefined,
  height: undefined,
  depth: undefined,
  cost: undefined,
  msrp: undefined,
  inStock: undefined,
  upc: undefined,
  binPickingNumber: undefined,
  mpn: undefined,
  tag: undefined,
  mediaItem: undefined,
  attributeValues: [],
}

export interface IDropDownOptionItem {
  value: any
  label: string
}

export const defaultMediaItem: IMediaItem = {
  id: '',
  created: '',
  title: '',
  location: '',
  mediaType: '',
  displayOrder: 0,
  size: 0,
}

export const defaultTenant: ITenant = {
  id: '',
  name: '',
  title: '',
  description: '',
  category1: '',
  category2: '',
  category3: '',
  image: '',
  mediaItems: [],
}

export const defaultTenantDetails: ITenantDetails = {
  id: '',
  name: '',
  title: '',
  description: '',
  category1: '',
  category2: '',
  category3: '',
  image: '',
  mediaItems: [],
  settings: [],
  logo: defaultMediaItem,
}

export interface IClassification {
  Id?: string
  Key?: any
  Name?: string
  Children?: IClassification[]
}

export interface IPaymentSettings {
  stripeAccount?: string
  stripeApiKey?: string
  stripeSecret?: string
}

export const defaultPaymentSettings: IPaymentSettings = {
  stripeAccount: '',
  stripeApiKey: '',
  stripeSecret: '',
}

export interface IStripePayment {
  mode: string
  payment_method_types: string[]
  line_items: IStripeLineItem[]
  cancel_url: string
  success_url: string
}

export interface IStripeLineItem {
  Quantity: number
  Amount: number
  Currency: string
  Name: string
}

export interface IAppConfig {
  identifier: string
  showIcon: boolean
  showLogo: boolean
  icon?: string
  logo?: string
  logoAsName?: boolean
  primaryColor: string
  eventsEnabled: boolean
  productCatalogEnabled: boolean
  fulfillmentEnabled?: Boolean
  collectionsEnabled?: Boolean
  localDeliveryEnabled?: Boolean
  nationalDeliveryEnabled?: Boolean
  isIFramed?: Boolean
  menuNavigation?: any
}

export interface IBusinessOpeningHours {
  days: IBusinessOpeningHourLine[]
}

export interface IBusinessOpeningHourLine {
  dayNumber: number
  dayLabel: String
  open: boolean
  timeFrom: string
  timeTo: string
}

export interface ITenantSortImages {
  SortedMediaItems?: ITenantSortImage[]
}

export interface ITenantSortImage {
  MediaItemId: string
  DisplayOrder: number
}

export const EmptyGuid: string = '00000000-0000-0000-0000-000000000000'

export enum CatalogType {
  Ecommerce,
  Experience,
}

export interface IRelatedProduct {
  id: string
  relatedProductId: string
  name: string
  image: string
  price: number
}

export interface IProductType {
  id?: string
  title?: string
  catalogType?: string
  description?: string
  displayOrder: number
  active?: boolean
  parentId?: string
  isChild?: boolean
  isSystem?: boolean
}

export interface IProductTypeTree {
  id?: string
  title?: string
  catalogType?: string
  description?: string
  displayOrder: number
  active?: boolean
  parentId?: string
  image?: string
  isChild?: boolean
  isSystem?: boolean
  childProductTypes?: IProductType[]
}

export const defaultProductType: IProductTypeTree = {
  displayOrder: 1,
  title: '',
  description: '',
  active: true,
}

export interface IAddress {
  id: string
  firstName: string
  lastName: string
  company: string
  county: string
  city: string
  address1: string
  address2: string
  zipPostalCode: string
  country: string
  phoneNumber: string
  mobileNumber: string
  faxNumber: string
  email: string
  customAttributes: string
}

export interface IVenue {
  id?: string
  name?: string
  description?: string
  address1?: string
  address2?: string
  city?: string
  county?: string
  postcode?: string
  country?: string
  latitude?: number
  longitude?: number
}

export interface IProductInventory {
  inventoryStrategy: number
  productId: string
  startQuantity?: number
  quantity?: number
  allocatedQuantity?: number
}

export const defaultVenue: IVenue = {
  name: '',
  description: '',
}
