import React, { Dispatch, FC, useContext, useEffect, useState } from 'react'
import { ScheduleType, SlotScheduleDay } from '../models'
import { useImmerReducer } from 'use-immer'
import {
  LogisticsSlotsActions,
  LogisticsSlotsReducer,
  LogisticsSlotState,
  LogisticsSlotStateInitialState
} from '../LogisticsSlotsReducer'
import SlotsPerHourControl from './SlotsPerHourControl'
import { GenerateSchedule } from '../SlotUtils'
import { DarkButton } from '@pixieme/pixie-react-components'
import LogisticsApi from '../LogisticsApi'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { format, isValid } from 'date-fns'

export const LogisticsSlotStateContext = React.createContext<LogisticsSlotState>({} as LogisticsSlotState)
export const LogisticsSlotDispatchContext = React.createContext<Dispatch<LogisticsSlotsActions>>({} as Dispatch<LogisticsSlotsActions>)

const LogisticsSlotsComponent: FC<LogisticsSlotsComponentProps> = ({ ScheduleType }) => {
  const [state, dispatch] = useImmerReducer(LogisticsSlotsReducer, LogisticsSlotStateInitialState)

  useEffect(() => {
    const schedule = GenerateSchedule(state.slotSchedule?.DateFrom ?? new Date(), state.slotsPerHourCount)
    dispatch({ type: 'loadedSchedule', schedule: schedule })

    LogisticsApi.getSlots(ScheduleType, new Date()).then((r) => {
      dispatch({ type: 'loadedOpenSlotSchedule', schedule: r })
    })
  }, [state.slotsPerHourCount])

  const [scheduleData, setScheduleData] = useState<any>(new Date())

  const formattedScheduleDate = (dateAsString: string) => {
    if (isValid(dateAsString)) {
      return format(new Date(dateAsString), 'dd-MM-yyyy')
    }
    return ''
  }

  const handleScheduleDateChange = (date: any) => {
    setScheduleData(date)
    if (date) {
      setScheduleData(date)
      LogisticsApi.getSlots(ScheduleType, date).then((r) => {
        const schedule = GenerateSchedule(date, state.slotsPerHourCount)
        dispatch({ type: 'loadedSchedule', schedule: schedule })
        dispatch({ type: 'loadedOpenSlotSchedule', schedule: r })
      })
    }
  }

  const DateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <div className="flex justify-between bg-inputBackground">
      <input
        {...props}
        className="cursor-pointer z-10 bg-transparent focus:outline-none focus:ring-0 focus:border-gray-800  border-0 border-b border-gray-300  w-full px-0 py-0 placeholder-gray-400 -mr-4"
        type="text"
      />
      <FontAwesomeIcon icon={faCalendar} size="1x" color="grey" />
    </div>
  )

  const SaveSlots = async () => {
    const scheduleId = await LogisticsApi.SaveSlots(state.slotSchedule!)
    dispatch({ type: 'saveScheduleSuccess', id: scheduleId })
  }

  const ShowDateSelector: Boolean = ScheduleType === 'Delivery' || ScheduleType === 'Collection'

  if (!state.slotSchedule) {
    return <div>No schedule</div>
  }

  return (
    <LogisticsSlotDispatchContext.Provider value={dispatch}>
      <LogisticsSlotStateContext.Provider value={state}>
        <div>
          <div className="flex items-start space-x-4 py-4">
            <DarkButton label="Save" size="small" onClick={() => SaveSlots()} />
            <div className="">
              <SlotsPerHourControl />
            </div>
            {
              ShowDateSelector &&
              <div className="">
                <DatePicker
                  dateFormat="dd/mm/yyyy"
                  value={formattedScheduleDate(scheduleData)}
                  onChange={(date) => handleScheduleDateChange(date)}
                  selectsStart
                  startDate={scheduleData}
                  showYearDropdown
                  dropdownMode="select"
                  customInput={React.createElement(React.forwardRef(DateInput))}
                />
              </div>
            }
          </div>
          <div className="grid grid-cols-7">
            {state.slotSchedule?.DayScheduleTypes?.map((day) => (
              <LogisticsSlotsLineComponent key={day.DayType.Number} Day={day} />
            ))}
          </div>
        </div>
      </LogisticsSlotStateContext.Provider>
    </LogisticsSlotDispatchContext.Provider>
  )
}

export default LogisticsSlotsComponent

const LogisticsSlotsLineComponent: FC<LogisticsSlotsLineComponentProps> = ({ Day }) => {
  const dispatch = useContext(LogisticsSlotDispatchContext)

  return (
    <div className="space-y-2">
      <div className="font-bold w-24 text-center">{Day.DayType.Display}</div>
      {Day.Times.map((time) => (
        <button
          type="button"
          key={`${Day.DayType.Number}-${time.DateFrom}`}
          onClick={() =>
            dispatch({
              type: 'toggleSlot',
              dateFrom: time.DateFrom,
              open: true,
            })
          }
          className={[time.Open ? 'bg-primary text-white' : '', 'font-normal bg-gray-100 w-24 text-center hover:cursor-pointer'].join(' ')}>
          {time.Display}
        </button>
      ))}
    </div>
  )
}

/* types */
type LogisticsSlotsComponentProps = {
  ScheduleType: ScheduleType
}

type LogisticsSlotsLineComponentProps = {
  Day: SlotScheduleDay
}
