import React, {useRef, useState} from 'react';
import {LoadingIndicatorPanel} from "../../components/LoadingIndicatorPanel";
import {
    Input,
    InputCheckBoxElement,
    InputElement,
    InputHtmlElement,
    InputLabelElement
} from "../../components/form-elements/input-element";
import {useForm} from "react-hook-form";
import {defaultMediaItem, defaultProduct, IMediaItem, IProductDetails} from "../../models/interface";
import {ColorResult, SketchPicker} from "react-color";
import ImageUpload from "../../components/imagetool/imageUpload";
import CancelButton from "../../components/CancelButton";
import SaveButton from "../../components/SaveButton";
import {useHistory} from "react-router-dom";

export interface IWhiteLabel {
    id?: string,
    identifier?: string,
    name?: string;
    title?: string;
    description?: string;
    primaryColor: string,
    logo: IMediaItem,
    mediaItems: IMediaItem[],
    productCatalogEnabled?: Boolean,
    eventsEnabled?: Boolean,
    fulfillmentEnabled?: Boolean,
    collectionsEnabled?: Boolean,
    localDeliveryEnabled?: Boolean,
    nationalDeliveryEnabled?: Boolean,
}

const defaultWhiteLabel: IWhiteLabel = {
    id: "",
    identifier: "",
    name: "",
    title: "",
    description: "",
    primaryColor: "",
    logo: defaultMediaItem,
    mediaItems: [],
    productCatalogEnabled: false,
    eventsEnabled: false,
    fulfillmentEnabled: false,
    collectionsEnabled: false,
    localDeliveryEnabled: false,
    nationalDeliveryEnabled: false,
}

const WhiteLabelScreen = () => {
    const [whiteLabel, setWhiteLabel] = useState<IWhiteLabel>(defaultWhiteLabel);
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, reset, formState, control, setValue, getValues } = useForm<IWhiteLabel>({})
    const [color, setColor] = useState<string>()
    const [showColorPicker, setShowColorPicker] = useState<Boolean>(false)
    const history = useHistory()
    const imageUploadRef = useRef<HTMLInputElement>()

    const handleColorChange = (color: ColorResult) => {
        setColor(color.hex)
        setValue('primaryColor', color.hex, { shouldDirty: true})
    }

    const displayColorPicker = () => {
        setShowColorPicker(true)
    }

    const handleClose = () => {
        setShowColorPicker(false)
    };

    function onSubmit (data: any) {
        /* FORM FIELDS */
        console.log("onSubmit", getValues())

        /* IMAGE FILES */
        const input = imageUploadRef.current
        Array.from(input?.files ?? []).forEach(item => console.log(item))
    }

    const disabled = !(formState.isDirty)  || formState.isSubmitting

    return (
        <div className="flex flex-col flex-grow w-full overflow-hidden">
            <div className="flex-1 overflow-y-scroll">
                {loading
                    ? <LoadingIndicatorPanel message="Loading info ..." />
                    : <>
                        <div className="mx-auto mt-8 max-w-7xl flex flex-col flex flex-col flex-grow overflow-hidden">
                            <div className="max-w-7xl p-8 mb-8 rounded-md shadow-sm bg-white">
                                <div className="font-bold text-gray-800/90 mb-2">WHITE LABEL DETAILS</div>
                                <form action="#" method="POST">
                                    <InputElement label={"Identifier"} id={"identifier"} type={"text"} register={register("identifier")} inputWidthCss={"w-96"} />
                                    <InputElement label={"Name"} id={"name"} type={"text"} register={register("name")} inputWidthCss={"w-96"} />
                                    <InputElement label={"Tag Line"} id={"title"} type={"text"} register={register("title")} inputWidthCss={"w-96"} />
                                    <InputHtmlElement label={"Description"} id={"description"} name="description" control={control} register={register("description")}/>
                                    <div className="mt-6">
                                        <InputLabelElement label="Primary Colour" id={'primaryColor'} />
                                        <div className="flex items-center space-x-2">
                                            <Input id="color" type="text" register={register("primaryColor")}  additionalCss="w-24" />
                                            <div onClick={() => displayColorPicker()} className="w-8 h-8 border border-gray-300" style={{backgroundColor: color}}></div>
                                        </div>
                                        {showColorPicker &&
                                        <div style={{ position: 'absolute', zIndex: 2 }}>
                                            <div style={{ position: 'fixed',
                                                top: '0px',
                                                right: '0px',
                                                bottom: '0px',
                                                left: '0px'}} onClick={handleClose}/>
                                                <SketchPicker color={color} onChangeComplete={handleColorChange}/>
                                        </div>
                                        }
                                    </div>
                                    <div className="mt-6">
                                        <InputLabelElement label="Logo" id={'logo'} />
                                        <ImageUpload title={"Drop image or click to upload image"} multiple={false} setref={imageUploadRef} mediaItems={whiteLabel.mediaItems} />
                                    </div>
                                </form>
                            </div>
                            <div className="max-w-7xl p-8 mb-8 rounded-md shadow-sm bg-white">
                                <div className="font-bold text-gray-800/90 mb-2">SETTINGS</div>
                                <InputCheckBoxElement label={"Product Catalog Enabled"} id={"active"} register={register("productCatalogEnabled")} orientation="landscape" />
                                <InputCheckBoxElement label={"Events Enabled"} id={"active"} register={register("eventsEnabled")} orientation="landscape" />
                                <InputCheckBoxElement label={"Fulfillment Enabled"} id={"active"} register={register("fulfillmentEnabled")} orientation="landscape" />
                                <InputCheckBoxElement label={"Collections Enabled"} id={"active"} register={register("collectionsEnabled")} orientation="landscape" />
                                <InputCheckBoxElement label={"Local Delivery Enabled"} id={"active"} register={register("localDeliveryEnabled")} orientation="landscape" />
                                <InputCheckBoxElement label={"National Delivery Enabled"} id={"active"} register={register("nationalDeliveryEnabled")} orientation="landscape" />
                            </div>
                        </div>
                    </>
                }
            </div>
            {/* Footer */}
            {!loading && <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
                <div className="flex space-x-4 justify-end items-center">
                    <div className="flex space-x-4">
                        <CancelButton
                            disabled={false}
                            isCancelling={false}
                            onCancel={history.goBack}
                        />
                        <SaveButton
                            disabled={disabled}
                            isSubmitting={formState.isSubmitting}
                            onSubmit={handleSubmit(onSubmit)}
                            buttonText="Save"
                        />
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default WhiteLabelScreen
