import { IProductTypeTree } from '../../../models/interface'

function flattenProductTypes(array: IProductTypeTree[]): IProductTypeTree[] {
  let result: any = []
  array.forEach(function (a: IProductTypeTree) {
    result.push(a)
    if (Array.isArray(a.childProductTypes)) {
      result = result.concat(flattenProductTypes(a.childProductTypes))
    }
  })
  return result
}

export { flattenProductTypes }
