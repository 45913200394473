import React, { FC, useCallback, useContext, useState } from 'react'
import { LoadingIndicatorPanel } from '../../../components/LoadingIndicatorPanel'
import SaveCancelFooter from '../Footer/SaveCancelFooter'
import ProductTypesTree from './productTypesTree'
import { ProductDispatchContext } from '../productdetails'
import { CatalogType, IProductType, IProductTypeTree } from '../../../models/interface'
import { useProductTypesManage } from '../../../hooks'

interface Props {
  catalogType: CatalogType
  title: string
  loadingText: string
  saveText: string
  onCancel: () => void
  // These are product types that need to be marked as selected
  preSelectedProductTypes?: IProductType[]
}

const ProductTypesManage: FC<Props> = ({
  catalogType,
  title,
  loadingText,
  onCancel,
  saveText,
  preSelectedProductTypes,
}) => {
  const productDispatch = useContext(ProductDispatchContext)
  const [selectedSystemProductTypes, setSelectedSystemProductTypes] = useState<IProductType[]>([])
  const [selectedCustomProductTypes, setSelectedCustomProductTypes] = useState<IProductType[]>([])
  const { businessDefinedProductTypes, systemDefinedProductTypes, loading } =
    useProductTypesManage(catalogType)

  const onSystemProductTypesChanged = useCallback((productTypes: IProductTypeTree[]) => {
    setSelectedSystemProductTypes([
      ...productTypes.map(
        (item) =>
          ({
            id: item.id,
            title: item.title,
            isSystem: item.isSystem,
            catalogType: item.catalogType,
          } as IProductType)
      ),
    ])
  }, [])

  const onCustomProductTypesChanged = useCallback((productTypes: IProductTypeTree[]) => {
    setSelectedCustomProductTypes([
      ...productTypes.map(
        (item) =>
          ({
            id: item.id,
            title: item.title,
            isSystem: item.isSystem,
            catalogType: item.catalogType,
          } as IProductType)
      ),
    ])
  }, [])

  const onSave = () => {
    const values = selectedSystemProductTypes.concat(selectedCustomProductTypes)
    productDispatch({
      type: 'changeProductTypes',
      productTypes: values,
    })
    onCancel()
  }

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mx-8 p-4 max-h-screen flex flex-col flex-grow">
          <div className="max-w-full px-4 py-8 mt-4 mb-24 h-screen bg-white rounded-md  shadow-lg  overflow-x-auto overflow-y-auto">
            <div className="font-bold text-gray-800/90 mb-4">{title}</div>
            {loading ? (
              <LoadingIndicatorPanel message={loadingText} />
            ) : (
              systemDefinedProductTypes &&
              businessDefinedProductTypes && (
                <>
                  <ProductTypesTree
                    productTypes={systemDefinedProductTypes}
                    onProductTypesChanged={onSystemProductTypesChanged}
                    selectedProductTypes={preSelectedProductTypes?.filter(
                      (x) => x.isSystem === true
                    )}
                  />
                  <ProductTypesTree
                    productTypes={businessDefinedProductTypes}
                    onProductTypesChanged={onCustomProductTypesChanged}
                    selectedProductTypes={preSelectedProductTypes?.filter(
                      (x) => x.isSystem !== true
                    )}
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
      <SaveCancelFooter
        onCancel={onCancel}
        saveText={saveText}
        cancelIsDisabled={false}
        isCancelling={false}
        onSubmit={onSave}
      />
    </div>
  )
}

export default ProductTypesManage
