import { SlotSchedule, SlotTimeType } from './models'
import { getDay, getHours, getMinutes } from 'date-fns'

const LogisticsSlotsReducer = (state: LogisticsSlotState, action: LogisticsSlotsActions) => {
  switch (action.type) {
    case 'reset':
      state = LogisticsSlotStateInitialState
      return
    case 'loadingOpenSlotSchedule':
      state.isLoadingOpenSlotSchedule = true
      return
    case 'loadedOpenSlotSchedule':
      state.isLoadingOpenSlotSchedule = false
      state.slotSchedule!.Id = action.schedule.Id
      state.openSlotSchedule = action.schedule

      const times = action.schedule.DayScheduleTypes.flatMap((day) => day?.Times?.filter((time) => time.Open))
      times?.forEach((time) => {
        const dayFound = state.slotSchedule!.DayScheduleTypes.find((x) => x.DayType.Number === getDay(time.DateFrom!))?.DayType.Number

        const slot = state.slotSchedule?.DayScheduleTypes[dayFound!]?.Times?.find(
          (x) => getHours(x.DateFrom!) === getHours(time.DateFrom!) && getMinutes(x.DateFrom!) === getMinutes(time.DateFrom!)
        )
        if (slot) {
          slot.Open = true
        }
      })
      return
    case 'loadedOpenSlotScheduleError':
      state.isLoadingOpenSlotSchedule = false
      return
    case 'loadingSchedule':
      state.isLoadingSchedule = true
      return
    case 'loadedSchedule':
      state.isLoadingSchedule = false
      state.slotSchedule = action.schedule
      return
    case 'loadedScheduleError':
      state.isLoadingSchedule = false
      return
    case 'slotsPerHourChanged':
      state.slotsPerHourCount = action.slotsPerHourCount
      return
    case 'toggleSlot':
      const slot = state.slotSchedule?.DayScheduleTypes[getDay(action.dateFrom)].Times.find(
        (x) => getHours(x.DateFrom!) === getHours(action.dateFrom) && getMinutes(x.DateFrom!) === getMinutes(action.dateFrom)
      ) as SlotTimeType
      if (slot) {
        slot.Open = !slot.Open
      }
      return
    case 'saveSchedule':
      return
    case 'saveScheduleSuccess':
      state.slotSchedule!.Id = action.id
      return
    case 'saveScheduleError':
      return
    default:
      return
  }
}

export type LogisticsSlotState = {
  isLoadingSchedule: boolean
  isLoadingOpenSlotSchedule: boolean
  slotSchedule?: SlotSchedule
  openSlotSchedule?: SlotSchedule
  slotsPerHourCount: number
}

const LogisticsSlotStateInitialState: LogisticsSlotState = {
  isLoadingSchedule: false,
  isLoadingOpenSlotSchedule: false,
  slotSchedule: undefined,
  openSlotSchedule: undefined,
  slotsPerHourCount: 1,
}

export type LogisticsSlotsActions =
  | { type: 'reset' }
  | { type: 'loadingOpenSlotSchedule' }
  | { type: 'loadedOpenSlotSchedule'; schedule: SlotSchedule }
  | { type: 'loadedOpenSlotScheduleError' }
  | { type: 'loadingSchedule' }
  | { type: 'loadedSchedule'; schedule: SlotSchedule }
  | { type: 'loadedScheduleError' }
  | { type: 'toggleSlot'; dateFrom: Date; open: boolean }
  | { type: 'slotsPerHourChanged'; slotsPerHourCount: number }
  | { type: 'saveSchedule'; schedule: SlotSchedule }
  | { type: 'saveScheduleSuccess'; id: string }
  | { type: 'saveScheduleError' }

export { LogisticsSlotsReducer, LogisticsSlotStateInitialState }
