import React, { FC } from 'react'

interface Props {
  searchTitle: string
}

const FilterContainer: FC<Props> = ({ searchTitle }) => {
  return (
    <div className="w-1/5 bg-white border mr-3 px-4 shadow-sm rounded-lg">
      <div className="text-base font-medium mt-6 mb-2">{searchTitle}</div>
    </div>
  )
}

export default FilterContainer
