import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { IMediaItem, IProductVariant } from '../../../models/interface'
import classNames from 'classnames'

const ProductVariantLineImagePicker: FC<ProductVariantLineImagePickerProps> = ({ mediaItems, productVariant, showDialog, OnImageSelected, onClosed }) => {
  const [isOpen, setIsOpen] = useState(showDialog)
  const [pickerMediaItems, setPickerMediaItems] = useState<IMediaItem[]>(mediaItems)
  let cancelButtonRef = useRef(null)

  useEffect(() => {
    setIsOpen(showDialog)
  }, [showDialog])

  console.log('ProductVariantLineImagePicker', showDialog, isOpen)

  const items: { mediaItem: IMediaItem; selected: boolean }[] = pickerMediaItems.map((item) => ({
    mediaItem: item,
    selected: item.id === productVariant.mediaItem?.id,
  }))

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={() => {
          onClosed()
          setIsOpen(false)
        }}>
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-4">
                    SELECT IMAGE
                  </Dialog.Title>
                </div>
              </div>
              <ProductImages props={items} OnImageSelected={OnImageSelected} />
              <div className="mt-5 sm:mt-6">
                <button
                  ref={cancelButtonRef}
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-500 text-base font-medium text-white focus:outline-none sm:text-sm"
                  onClick={() => {
                    setIsOpen(false)
                    onClosed()
                  }}>
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ProductVariantLineImagePicker

const ProductImages: FC<ProductImagesProps> = ({ OnImageSelected, props }) => {
  console.log('ProductImages', props)
  return (
    <>
      <div className="overflow-y-auto overflow-hidden h-96">
        {props?.map((image) => (
          <ImageCard OnImageSelected={OnImageSelected} selected={image.selected} mediaItem={image.mediaItem} />
        ))}
      </div>
    </>
  )
}

const ImageCard: FC<{ OnImageSelected?: (mediaItem: IMediaItem) => void; mediaItem: IMediaItem; selected: boolean }> = ({
  OnImageSelected,
  mediaItem,
  selected,
}) => {
  return (
    <div className="flex justify-between items-center space-x-2 mb-2 mx-2">
      <div className="flex space-x-2">
        <img
          src={mediaItem.location}
          className="h-48 w-48 hover:opacity-60 cursor-pointer border-2 border-gray-200 object-cover hover:border-2 hover:border-gray-400"
          alt="upload file"
        />
        <div className="flex flex-col space-y-2 justify-start">
          <div className="text-sm font-normal text-gray-800">{mediaItem.filename}</div>
          <div className="text-sm font-normal text-gray-800">{mediaItem.title}</div>
        </div>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault()
          OnImageSelected && OnImageSelected(mediaItem)
        }}>
        <div
          className={classNames(
            selected
              ? 'mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'
              : 'mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-200'
          )}>
          <CheckIcon className={classNames(selected ? 'h-6 w-6 text-green-600' : 'hidden')} aria-hidden="true" />
        </div>
      </button>
    </div>
  )
}

/* Types */
interface ProductVariantLineImagePickerProps {
  showDialog: boolean
  productVariant: IProductVariant
  mediaItems: IMediaItem[]
  OnImageSelected?: (mediaItem: IMediaItem) => void
  onClosed: () => void
}

interface ProductImagesProps {
  OnImageSelected?: (mediaItem: IMediaItem) => void
  props: {
    mediaItem: IMediaItem
    selected: boolean
  }[]
}
