import React, { FC } from 'react'

interface Props {
  title: string
  manageVariants: () => void
}

const ProductVariantsContainer: FC<Props> = ({ title, manageVariants }) => {
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-bold text-gray-800/90 mb-5">{title}</div>
        <button
          onClick={manageVariants}
          className="bg-pixie text-sm font-medium text-white/90 px-4 py-1 rounded-md hover:bg-opacity-40">
          Manage
        </button>
      </div>
    </div>
  )
}

export default ProductVariantsContainer
