import React, { FC } from 'react'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { Link } from 'react-router-dom'
import OrderCard from './OrderCard'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { useOrders } from '../../hooks'
import FilterContainer from './Filter/FilterContainer'

const OrdersScreen: FC = () => {
  const { loading, data: orders } = useOrders()

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md flex flex-col flex-grow">
          {loading ? (
            <LoadingIndicatorPanel message="Loading orders ..." />
          ) : (
            <>
              <CrudListHeader headerLabel={'Orders'} subheaderLabel={''} addAction={''} />
              <div className="flex flex-row">
                <FilterContainer searchTitle={'Filter Orders'} />
                <div className="flex-1">
                  <div className="min-w-full divide-y flex-1">
                    {orders?.map((order) => (
                      <div
                        key={order.id}
                        className="overflow-hidden bg-white cursor-pointer hover:border-gray-200 hover:bg-gray-50">
                        <Link
                          to={{
                            pathname: `/orderdetails/${order.id}`,
                            state: { id: order.id },
                          }}>
                          <OrderCard order={order} />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrdersScreen
