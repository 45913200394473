// https://stackoverflow.com/questions/65770050/making-the-react-usecallback-has-a-missing-dependency-warning-disappear-with-r

import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from '@fortawesome/pro-duotone-svg-icons'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { faGripVertical, faTrash } from '@fortawesome/pro-light-svg-icons'
import { IMediaItem } from '../../models/interface'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { XYCoord } from 'dnd-core'

type ImageUploadProps = {
  title: string
  setref: any
  multiple: boolean
  mediaItems: IMediaItem[]
  onImageUpload?: (files: File, onUploadProgress?: (e: ProgressEvent) => void) => any
  onImageDeleted?: (id: string) => void
  onResortImages?: (images: IMediaItem[]) => void
}

type WrappedMediaItem = {
  id: string
  mediaItem: IMediaItem
  displayOrder: number
  uploadedFile?: any
}

const ImageUpload = (props: ImageUploadProps) => {
  const [wrappedMediaItems, setWrappedMediaItems] = useState<WrappedMediaItem[]>([])
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)

  /* Move card on dragging */
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = wrappedMediaItems[dragIndex]

      setWrappedMediaItems((prevState) => {
        const copiedStateArray = [...prevState]
        const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem)
        copiedStateArray.splice(dragIndex, 1, prevItem[0])

        console.log('moveCard')
        console.log(copiedStateArray)

        props?.onResortImages && props?.onResortImages(copiedStateArray.map((item) => item.mediaItem))

        return copiedStateArray
      })
    },
    [wrappedMediaItems]
  )

  useEffect(() => {
    const existingItems = props.mediaItems.map<WrappedMediaItem>(
      (item: IMediaItem) => ({ id: item.id, mediaItem: item, displayOrder: item.displayOrder } as WrappedMediaItem)
    )
    setWrappedMediaItems(existingItems)
  }, [])

  const onDrop = useCallback(
    async (accepted: any, rejected: any, event: any) => {
      console.log('onDrop - accepted', accepted)
      console.log('onDrop - rejected', rejected)
      console.log('onDrop - event', event)

      const files = accepted.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )

      if (props.multiple) {
        setUploadedFiles([...uploadedFiles, ...files])
      } else {
        setUploadedFiles(files)
      }

      if (props.onImageUpload) {
        setUploading(true)
        for (const file of files) {
          const result = await props.onImageUpload(file, (progressEvent) => {
            setUploadProgress(progressEvent.loaded)
            console.log('onImageUpload Progress', progressEvent.loaded)
          })
          console.log('props.onImageUpload', result?.data)
          const newUploadedItem = { id: uuidv4(), uploadedFile: file, mediaItem: result.data } as WrappedMediaItem
          console.log('newUploadedItem', newUploadedItem)
          setWrappedMediaItems((wrappedMediaItems) => [...wrappedMediaItems, newUploadedItem])
        }
        setUploading(false)
      }

      if (event.type === 'drop') {
        const dt = new DataTransfer()
        const existfiles = Array.from(inputRef!.current!.files!)
        if (props.multiple) {
          for (let existfile of existfiles) {
            dt.items.add(existfile)
          }
        }
        for (let file of accepted) {
          dt.items.add(file) // Add only file name not matched files
        }
        inputRef!.current!.files! = dt.files
      } else {
        const dt = new DataTransfer()
        if (props.multiple) {
          for (let existfile of uploadedFiles) {
            dt.items.add(existfile) // Add only file name not matched files
          }
        }
        for (let existfile of accepted) {
          dt.items.add(existfile) // Add only file name not matched files
        }
        inputRef!.current!.files! = dt.files // Overwrite files
      }
    },
    [uploadedFiles, props.multiple, wrappedMediaItems]
  )

  /* Initialise DropZone */
  const { getRootProps, getInputProps, inputRef, isDragActive, isDragAccept, isDragReject } = useDropzone({
    noKeyboard: true,
    maxSize: 20971520,
    multiple: props.multiple,
    maxFiles: props.multiple ? 0 : 1,
    accept: 'image/*',
    onDrop,
  })

  const handleRemoveFile = React.useCallback(
    (e, index) => {
      e.preventDefault()
      const dt = new DataTransfer()
      let files = Array.from(inputRef!.current!.files!)
      files.splice(index, 1)
      for (let file of files) {
        dt.items.add(file)
      }
      inputRef!.current!.files! = dt.files // Overwrite files
      setUploadedFiles(Array.from(dt.files)) // Set states to render file list
    },
    [inputRef]
  )

  const handleRemoveMediaFile = React.useCallback(
    (e, wrappedMediaItem, index) => {
      e.preventDefault()

      props.onImageDeleted && props.onImageDeleted(wrappedMediaItem.mediaItem.id)

      const newItems = wrappedMediaItems.filter((item) => item.mediaItem.id !== wrappedMediaItem.mediaItem.id).map((itemx) => itemx)
      console.log('new items', wrappedMediaItem.mediaItem.id, newItems)
      setWrappedMediaItems(newItems)

      // const dt = new DataTransfer();
      // let files = Array.from(inputRef!.current!.files!);
      // files.splice(index, 1);
      // for (let file of files) {
      //     dt.items.add(file);
      // }
      // inputRef!.current!.files! = dt.files; // Overwrite files
      // setUploadedFiles(Array.from(dt.files)); // Set states to render file list
    },
    [inputRef, wrappedMediaItems]
  )

  // const uploadedFilesPanel = React.useMemo<any>(
  //     () =>
  //         uploadedFiles?.map((file: any, index: number) => (
  //             <tr key={file.name} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
  //                 <td>
  //                   <img src={file.preview} className="block h-16 w-16 m-4 hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400" alt="upload file" />
  //                 </td>
  //                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.name}</td>
  //                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{file.size}</td>
  //                 <td>
  //                     <button onClick={(e) => handleRemoveFile(e, index)} className="cursor-pointer focus:outline-none ml-10">
  //                         <FontAwesomeIcon icon={faTrash} size="1x" className="text-gray-400"  />
  //                     </button>
  //                 </td>{" "}
  //             </tr>
  //         )),
  //     [handleRemoveFile, uploadedFiles]
  // );
  type ImageCardProps = {
    item: WrappedMediaItem
    index: number
    moveCard: (dragIndex: number, hoverIndex: number) => void
  }

  interface DragItem {
    index: number
    id: string
    type: string
  }

  const ImageCard: FC<ImageCardProps> = ({ item, index, moveCard }) => {
    const ref = useRef<HTMLTableRowElement>(null)
    const id = item.mediaItem.id

    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      // <span data-handler-id={handlerId} ref={ref} style={{ opacity }}>
      <tr data-handler-id={handlerId} ref={ref} style={{ opacity }} key={item.mediaItem.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
        <td>
          <button className="cursor-pointer focus:outline-none mr-4">
            <FontAwesomeIcon icon={faGripVertical} size="1x" />
          </button>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
          <img
            src={item.mediaItem.location}
            className="block h-16 w-16 m-4 object-cover hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400"
            alt="upload file"
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.mediaItem.filename}</td>
        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.mediaItem.size}</td>
        <td>
          <button onClick={(e) => handleRemoveMediaFile(e, item, index)} className="cursor-pointer focus:outline-none ml-10">
            <FontAwesomeIcon icon={faTrash} size="1x" className="text-gray-400" />
          </button>
        </td>
      </tr>
      // </span>
    )
  }

  const files = React.useMemo<any>(
    () =>
      wrappedMediaItems
        // ?.sort(function (a, b) { return ((a.displayOrder !== undefined && b.displayOrder !== undefined) && a.displayOrder > b.displayOrder) ? 1 : -1})
        .map((item, index: number) => (
          <>
            {item.mediaItem && (
              <>
                <ImageCard key={item.id} item={item} index={index} moveCard={moveCard} />
                {/* <tr key={item.mediaItem.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>*/}
                {/*     <td>*/}
                {/*         <button className="cursor-pointer focus:outline-none mr-4">*/}
                {/*             <FontAwesomeIcon icon={faGripVertical} size="1x" />*/}
                {/*         </button>*/}
                {/*     </td>*/}
                {/*    <td>*/}
                {/*        <img src={item.mediaItem.location} className="block h-16 w-16 m-4 hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400" alt="upload file" />*/}
                {/*    </td>*/}
                {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.mediaItem.filename}</td>*/}
                {/*    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.mediaItem.size}</td>*/}
                {/*    <td>*/}
                {/*        <button onClick={(e) => handleRemoveMediaFile(e, item, index)} className="cursor-pointer focus:outline-none ml-10">*/}
                {/*            <FontAwesomeIcon icon={faTrash} size="1x" className="text-gray-400"  />*/}
                {/*        </button>*/}
                {/*    </td>*/}
                {/*</tr>*/}
              </>
            )}
            {/*{item.uploadedFile && <>*/}
            {/*    <tr key={item.uploadedFile?.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>*/}
            {/*        <td>*/}
            {/*            <img src={item.uploadedFile?.preview} className="block h-16 w-16 m-4 hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400" alt="upload file" />*/}
            {/*        </td>*/}
            {/*        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.uploadedFile?.name}</td>*/}
            {/*        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.uploadedFile?.size}</td>*/}
            {/*        <td>*/}
            {/*            <button onClick={(e) => handleRemoveFile(e, index)} className="cursor-pointer focus:outline-none ml-10">*/}
            {/*                <FontAwesomeIcon icon={faTrash} size="1x" className="text-gray-400"  />*/}
            {/*            </button>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*    </>}*/}
          </>
        )),
    [handleRemoveFile, wrappedMediaItems]
  )

  useEffect(() => {
    if (inputRef.current !== props.setref.current) {
      props.setref.current = inputRef.current
    }
  }, [props.setref, inputRef, uploadedFiles])

  return (
    <div className="mt-2 mb-4 w-full">
      <div className="h-32 overflow-hidden rounded-md p-4 bg-gray-100 hover:bg-pixie hover:bg-opacity-10 cursor-pointer focus:outline-none">
        {uploading ? (
          uploadProgress > 0 && (
            <div className="flex flex-col items-center justify-center w-full h-full">
              <div className="text-sm">Upload Progress {uploadProgress}</div>
            </div>
          )
        ) : (
          <div {...getRootProps({ className: 'dropzone' })} className="flex flex-col items-center justify-center">
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faCloudUpload} size="2x" className="text-grey-300 mt-4" />
            <div className="text-sm mt-2 text-gray-500 p-1">{props.title}</div>
          </div>
        )}
      </div>

      <DndProvider backend={HTML5Backend}>
        {files.length > 0 ? (
          <div className="mt-5">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {' '}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    File Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Length (bytes)
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {' '}
                  </th>
                </tr>
              </thead>
              <tbody>{files}</tbody>
            </table>
          </div>
        ) : (
          ''
        )}
      </DndProvider>
    </div>
  )
}

export default ImageUpload

const ItemTypes = {
  CARD: 'card',
}
