import React, { Dispatch, FC, RefObject, useContext, useEffect, useRef, useState } from 'react'
import {
  defaultProduct,
  defaultProductVariant,
  IProductAttribute,
  IProductAttributeMapping,
  IProductAttributeValue,
  IProductDetails,
  IProductVariant,
} from '../../../models/interface'
import { useParams } from 'react-router'
import { hourMarketApi } from '../../../Utils/api/hourmarket-api'
import parse from 'html-react-parser'
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { numberFormat } from '../../../Utils/app.utils'
import { LoadingIndicatorPanel } from '../../../components/LoadingIndicatorPanel'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { useImmerReducer } from 'use-immer'
import _ from 'lodash'

const ProductPreviewReducer = (state: ProductPreviewState, action: ProductPreviewAction) => {
  switch (action.type) {
    case 'setProduct':
      console.log('setProduct', action.product)
      state.product = action.product
      state.hasProductVariants = action.product?.variants && action.product.variants.length > 0
      return
    case 'setAttribute':
      const attributeId = action.attribute.id
      const attributeValueId = action.attributeValue.id!

      const existingAttribute = state.attributeSelectedValues.find((item) => item.attributeId === attributeId)

      if (existingAttribute) {
        console.log('setAttribute update', action.attribute.name, action.attributeValue.name, attributeId, attributeValueId)
        existingAttribute.attributeValueId = attributeValueId
      } else {
        console.log('setAttribute add', action.attribute.name, action.attributeValue.name, attributeId, attributeValueId)
        state.attributeSelectedValues.push({
          attributeId: attributeId,
          attributeValueId: attributeValueId,
        } as AttributeSelectedValue)
      }

      state.attributeSelectedValues.forEach((item) => console.log(item.attributeId, item.attributeValueId))

      const targetSelectedProductVariants = state.attributeSelectedValues.map((item) => {
        return item.attributeValueId
      })

      console.log('targetSelectedProductVariants', targetSelectedProductVariants)

      // state.product!.variants.forEach(item => console.log(item.attributeValues?.map(x => x.id)))

      const productVariant = state
        .product!.variants.slice()
        .find((item) => item.attributeValues!.every((attributeValue) => targetSelectedProductVariants.includes(attributeValue.id!))) as IProductVariant
      //targetSelectedProductVariants?.every(attributeValue => item.attributeValues!.map(item => item.id).includes(attributeValue!))

      console.log('productVariant selected', productVariant?.mediaItem?.filename)

      if (productVariant) {
        state.productVariant = productVariant
      }

      return
  }
}

type AttributeSelectedValue = {
  attributeId: string
  attributeValueId: string
}

export type ProductPreviewState = {
  product: IProductDetails
  hasProductVariants: boolean
  productVariant: IProductVariant
  attributeSelectedValues: AttributeSelectedValue[]
}

export type ProductPreviewAction =
  | { type: 'reset' }
  | { type: 'setProduct'; product: IProductDetails }
  | { type: 'setAttribute'; attribute: IProductAttribute; attributeValue: IProductAttributeValue }

const ProductPreviewStateContext = React.createContext<ProductPreviewState>({} as ProductPreviewState)
const ProductPreviewDispatchContext = React.createContext<Dispatch<ProductPreviewAction>>({} as Dispatch<ProductPreviewAction>)

const ProductPreview = () => {
  const [quantity, setQuantity] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const [state, dispatch] = useImmerReducer(ProductPreviewReducer, {
    product: defaultProduct,
    productVariant: defaultProductVariant,
    attributeSelectedValues: [],
    hasProductVariants: false,
  })

  useEffect(() => {
    setLoading(true)
    hourMarketApi
      .getProductPreview(id)
      .then((result) => {
        dispatch({ type: 'setProduct', product: result.data })
      })
      .finally(() => setLoading(false))
  }, [id])

  useEffect(() => {}, [])

  console.log('product details', state.product)

  return (
    <>
      {loading ? (
        <LoadingIndicatorPanel message={'Loading product ...'} />
      ) : (
        <ProductPreviewDispatchContext.Provider value={dispatch}>
          <ProductPreviewStateContext.Provider value={state}>
            <div className="flex flex-col flex-grow w-full">
              <div className="mx-auto h-full mb-8 mt-2  max-w-7xl p-4 flex flex-col flex-grow overflow-hidden w-full">
                <button onClick={history.goBack} className="mb-4 flex items-center focus:outline-none">
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" className="text-gray-500 cursor-pointer" />
                  <span className="ml-2 text-gray-500">Back</span>
                </button>
                <div className="font-medium text-gray-400 text-sm my-2">Product Preview</div>
                <div className="flex-1 overflow-y-auto bg-white p-4 rounded-lg shadow-lg">
                  <div className="sm:flex sm:space-x-8">
                    <ProductImages product={state.product!} productVariant={state.productVariant} />
                    <div className="px-4 sm:px-0 sm:w-1/2 mt-4 sm:mt-0">
                      <div className="text-2xl font-semibold text-gray-800">{state.product?.name!}</div>
                      <PricePanel product={state.product} productVariant={state.productVariant} hasProductVariants={state.hasProductVariants} />
                      <ProductAttributes product={state.product!} />
                      <div className="mt-12">
                        <div className="text-sm">Qty</div>
                        <input type="number" onChange={(e) => setQuantity(parseInt(e.target.value))} className="my-2 w-20" value={quantity} />
                      </div>
                      <AddToCartButton hasProductVariants={state.hasProductVariants} productVariant={state.productVariant} />
                      <div className="mt-4 text-gray-800 leading-7">{parse(state.product?.description ?? '')}</div>
                      {state.productVariant.sku && state.productVariant.sku.length > 0 && (
                        <div className="flex space-x-2 mt-4">
                          <div className="text-gray-800 font-semibold italic text-sm">Product code:</div>
                          <div className="text-gray-800 italic  text-sm">{state.productVariant.sku}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ProductPreviewStateContext.Provider>
        </ProductPreviewDispatchContext.Provider>
      )}
    </>
  )
}

export default ProductPreview

type PricePanelProps = {
  product: IProductDetails
  productVariant: IProductVariant
  hasProductVariants: boolean
}

const PricePanel: FC<PricePanelProps> = ({ product, productVariant, hasProductVariants }) => {
  return (
    <>
      {hasProductVariants &&
        (productVariant?.salePrice === 0 ? (
          <div className="text-xl font-extrabold text-gray-800 mt-2">
            {productVariant?.price && productVariant?.price !== undefined && numberFormat(productVariant?.price)}
          </div>
        ) : (
          <div className="flex space-x-4">
            <div className="text-xl font-extrabold line-through text-gray-400 mt-2">
              {productVariant?.price && productVariant?.price !== undefined && numberFormat(productVariant?.price)}
            </div>
            <div className="text-xl font-extrabold text-gray-800 mt-2">
              {productVariant?.salePrice && productVariant?.salePrice !== undefined && numberFormat(productVariant?.salePrice)}
            </div>
          </div>
        ))}
      {!hasProductVariants && (
        <div>
          {numberFormat(product.price)} {hasProductVariants}
        </div>
      )}
    </>
  )
}

type AddToCartButtonProps = {
  hasProductVariants: boolean
  productVariant: IProductVariant
}

const AddToCartButton: FC<AddToCartButtonProps> = ({ hasProductVariants, productVariant }) => {
  return (
    <div>
      {hasProductVariants &&
        (productVariant?.inStock ? (
          <button
            onClick={() => console.log('')}
            className="mx-auto sm:mx-0 mt-4 sm:mt-2 text-white bg-gray-700 text-sm sm:w-64 border-0 font-bold py-3 px-6 focus:outline-none hover:bg-gray-400 rounded">
            ADD TO CART
          </button>
        ) : (
          <button className="mx-auto sm:mx-0 mt-4 sm:mt-2 text-white bg-gray-300 text-sm sm:w-64 border-0 font-bold py-3 px-6 focus:outline-none rounded">
            OUT OF STOCK
          </button>
        ))}
      {!hasProductVariants && (
        <button
          onClick={() => console.log('')}
          className="mx-auto sm:mx-0 mt-4 sm:mt-2 text-white bg-gray-700 text-sm sm:w-64 border-0 font-bold py-3 px-6 focus:outline-none hover:bg-gray-400 rounded">
          ADD TO CART
        </button>
      )}
    </div>
  )
}

type ProductImagesProps = {
  product: IProductDetails
  productVariant: IProductVariant
}

export const ProductImages: FC<ProductImagesProps> = ({ product, productVariant }) => {
  const imageGalleryRef = useRef<ImageGallery>(null)

  const images = product?.mediaItems?.map((mediaItem) => {
    return {
      original: mediaItem.location,
      thumbnail: mediaItem.location,
      width: 100,
      height: 100,
    } as ReactImageGalleryItem
  })

  useEffect(() => {
    if (productVariant.mediaItem) {
      const index = images?.findIndex((image) => image.original === productVariant?.mediaItem?.location)
      console.log('ProductImages', productVariant?.mediaItem?.location, productVariant?.mediaItem?.filename, index)
      if (index != undefined && index !== -1) {
        imageGalleryRef?.current?.slideToIndex(index)
      }
    }
  }, [productVariant])

  return (
    <div className="px-4 sm:px-0 sm:w-1/2 mt-2 sm:mt-0">
      {images && <ImageGallery ref={imageGalleryRef} items={images} showFullscreenButton={false} showPlayButton={false} additionalClass="" />}
    </div>
  )
}

type ProductAttributesProps = {
  product: IProductDetails
}

const ProductAttributes: FC<ProductAttributesProps> = ({ product }) => {
  const productPreviewDispatch: Dispatch<ProductPreviewAction> = useContext(ProductPreviewDispatchContext)

  useEffect(() => {
    // Save each attribute and attribute value in
    product?.attributes.forEach((attribute) => {
      if (attribute && attribute.attributeValues && attribute.attribute && attribute.attribute.id && attribute.attributeValues.length > 0) {
        productPreviewDispatch({
          type: 'setAttribute',
          attribute: attribute.attribute,
          attributeValue: _.first(attribute.attributeValues)!,
        })
      }
    })
  }, [product])

  return (
    <>
      {product?.attributes
        .slice()
        .sort(function (a, b) {
          return a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((attribute) => {
          return (
            <div key={attribute.id}>
              <div className="mt-6 text-sm">{attribute?.attribute?.name}</div>
              {(attribute?.displayType === undefined || attribute?.displayType === 'Dropdown') && <ProductAttributesDropDown attribute={attribute} />}
              {attribute?.displayType === 'Swatch' && <ProductAttributesSwatch attribute={attribute} />}
            </div>
          )
        })}
    </>
  )
}

type ProductAttributesDropDownProps = {
  attribute: IProductAttributeMapping
}

const ProductAttributesDropDown: FC<ProductAttributesDropDownProps> = ({ attribute }) => {
  const productPreviewDispatch: Dispatch<ProductPreviewAction> = useContext(ProductPreviewDispatchContext)

  const onDropDownChange = (e: React.BaseSyntheticEvent) => {
    console.log('onDropDownChange', e.target.value)
    const attributeValue = attribute.attributeValues.find((item) => item.id === e.target.value)
    if (attribute && attribute.attribute && attribute.attribute.id && attributeValue) {
      productPreviewDispatch({
        type: 'setAttribute',
        attribute: attribute.attribute,
        attributeValue: attributeValue,
      })
    }
  }

  return (
    <>
      <select onChange={onDropDownChange} className="mt-1">
        {attribute.attributeValues
          .slice()
          .sort(function (a, b) {
            return a.displayOrder > b.displayOrder ? 1 : -1
          })
          .map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
      </select>
    </>
  )
}

type ProductAttributesSwatchProps = {
  attribute: IProductAttributeMapping
}

const ProductAttributesSwatch: FC<ProductAttributesSwatchProps> = ({ attribute }) => {
  const productPreviewDispatch: Dispatch<ProductPreviewAction> = useContext(ProductPreviewDispatchContext)

  const onSwatchSelected = (id?: string) => {
    console.log('onDropDownChange', id)
    const attributeValue = attribute.attributeValues.find((item) => item.id === id)
    if (attribute && attribute.attribute && attribute.attribute.id && attributeValue) {
      productPreviewDispatch({
        type: 'setAttribute',
        attribute: attribute.attribute,
        attributeValue: attributeValue,
      })
    }
  }

  return (
    <div className="flex flex-wrap">
      {attribute.attributeValues
        .slice()
        .sort(function (a, b) {
          return a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((value) => {
          return (
            <div key={value.id} className="flex flex-col items-center space-y-1 mr-2 mt-1">
              <button
                onClick={() => onSwatchSelected(value.id)}
                className="w-20 h-20 border border-gray-300"
                style={{ backgroundColor: value?.colorSquaresRgb }}></button>
              <div className="text-xs">{value?.name}</div>
            </div>
          )
        })}
    </div>
  )
}
