import { CatalogType, IProductTypeTree } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import useFetchAll from './useFetchAll'

interface ProductTypesManageProps {
  businessDefinedProductTypes?: IProductTypeTree[]
  systemDefinedProductTypes?: IProductTypeTree[]
  error?: any
  loading: boolean
}

/*
 * Return business domain product types defined by the business and also the global system product attributes.
 * These will allow business to select either from their defined product attributes and/or the global ones.
 */
const useProductTypesManage = (catalogType: CatalogType): ProductTypesManageProps => {
  const result = useFetchAll<IProductTypeTree[], IProductTypeTree[]>(
    catalogType === CatalogType.Ecommerce
      ? hourMarketApi.getProductTypeFullTree()
      : hourMarketApi.getExperienceProductTypeFullTree(),
    catalogType === CatalogType.Ecommerce
      ? hourMarketApi.getSystemProductTypes()
      : hourMarketApi.getSystemExperienceTypes()
  )

  const { data1, data2, loading, error } = result

  return {
    businessDefinedProductTypes: data1,
    systemDefinedProductTypes: data2,
    loading: loading,
    error: error,
  }
}

export default useProductTypesManage
