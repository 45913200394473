// https://reactjs.org/docs/forms.html#the-select-tag
// https://www.npmjs.com/package/reactjs-tag-input

import React, {FC, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {
    ICreateExperienceAttributeMapping,
    ICreateExperienceAttributeValues,
    IExperience,
    IExperienceAttribute,
    IExperienceAttributeMapping,
    IExperienceAttributeValue,
} from "../../models/interface";
import {hourMarketApi} from "../../Utils/api/hourmarket-api";
import TagInput from "../../controls/tags/taginput";

interface ExperienceExperienceAttributesProps {
    experienceAttributeMapping: IExperienceAttributeMapping
}

interface ExperienceAttributeMappingProps {
    experience: IExperience
}

const ExperienceExperienceAttributes: FC<ExperienceAttributeMappingProps> = ({experience}) => {
    const [attributes, setAttributes] = useState<IExperienceAttribute[]>([])
    const [addAttribute, setAddAttribute] = useState(false)
    const [attribute, setAttribute] = useState("")
    const [attributeMappings, setAttributeMappings] = useState<IExperienceAttributeMapping[]>([])

    useEffect(() => {
        // hourMarketApi.getExperienceAttributes().then(r => {
        //     setAttributes(r.data)
        //     if (r.data && r.data.length > 0)
        //     {
        //         setAttribute(r.data[0].id)
        //     }
        // })
    }, [])

    useEffect(() => {
        console.log("Set attributes")
        setAttributeMappings(experience.attributes!)
        console.log(experience.attributes)
    }, [experience])

    const onAddAttribute = () => {
        setAddAttribute(true)
    }

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        console.log("onSubmit");
        const experienceAttributeMapping: ICreateExperienceAttributeMapping = {
            experienceId: experience.id!,
            experienceAttributeId: attribute
        }

        console.log(experienceAttributeMapping)
        hourMarketApi.createExperienceAttributeMapping(experienceAttributeMapping).then(r => {
            console.log(r)

            setAttributeMappings([...attributeMappings,r.data.attributeMapping])
        })
    }

    const handleAttributeSelectedChange = (e: React.BaseSyntheticEvent) => {
        console.log("handleAttributeSelectedChange")
        setAttribute(e.target.value);
    }

    return (
        <>
            <div className="font-bold text-xl my-2 text-gray-900">Event Attributes</div>

            <button className="focus:outline-none bg-gray-100 border border-gray-200 px-2 text-gray-600 font-medium text-sm rounded-md py-1 mb-4" onClick={onAddAttribute}>
                <FontAwesomeIcon
                    icon={faPlus}
                    size="lg"
                    className="mr-2 text-pixie hover:text-gray-600"
                />
                Add Attribute
            </button>
            <div className={addAttribute ? "visible mt-2 mb-6" : "hidden"}>
                <form onSubmit={onSubmit}>
                    <select className="block" value={attribute} onChange={handleAttributeSelectedChange}>
                        {attributes && attributes?.length > 0 &&
                        attributes
                            .map(attribute => {
                                return <option key={attribute.id} value={attribute?.id} className="text-sm">{attribute?.name}</option>
                            })
                        }
                    </select>
                    <button className="mt-2 bg-gray-800 px-4 py-1 text-sm text-white rounded-md" type="submit">Save</button>
                </form>
            </div>
            <div>
                {attributeMappings && attributeMappings?.length > 0 &&
                    attributeMappings
                        .sort(function(a,b){ return (a.displayOrder > b.displayOrder) ? 1 : -1})
                        .map(attributeMapping => {
                        return <ExperienceAttribute key={attributeMapping.id} experienceAttributeMapping={attributeMapping} />
                    })
                }
            </div>
        </>
    )
}

export default ExperienceExperienceAttributes;

interface ITagInputType {
    id: string,
    displayValue: string,
    index: number
}

const ExperienceAttribute: FC<ExperienceExperienceAttributesProps> = ({experienceAttributeMapping}) => {

    const [tags, setTags] = useState<ITagInputType[]>([])
    const [updatedTags, setUpdatedTags] = useState<ITagInputType[]>([])
    const [addAttributeValue, setAddAttributeValue] = useState(true)

    const onAddAttributeValue = () => {
        setAddAttributeValue(!addAttributeValue)
    }
    let input = React.createRef();
    useEffect(() => {

        const items: ITagInputType[] = []
        experienceAttributeMapping.attributeValues.forEach(experienceAttributeValue => {
            const item: ITagInputType = { id: experienceAttributeValue.id!, displayValue: experienceAttributeValue.name ?? "", index: experienceAttributeValue.displayOrder }
            items.push(item)
        });

        setTags(items)
        console.log(items)
        console.log(tags)

    },[experienceAttributeMapping])

    const onTagsChanged = (tags: ITagInputType[]) => {
        console.log("onTagsChanged 2")
        console.log(tags)
        setUpdatedTags( tags );
    };

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        let attributeValues: IExperienceAttributeValue[] = []
        updatedTags.map(tag => {
            attributeValues.push({ id: tag.id, name: tag.displayValue, displayOrder: tag.index, priceAdjustment: 0 })
        })

        const createExperienceAttributeValues: ICreateExperienceAttributeValues = {
            Id: experienceAttributeMapping.id,
            attributeValues: attributeValues
        }

        console.log("onSubmit");
        console.log(createExperienceAttributeValues)

        hourMarketApi.createExperienceAttributeValues(createExperienceAttributeValues).then(r => {
            console.log(r)
        })
    }

    return (<>
        <div className="mb-2">
            <div className="font-semibold">{experienceAttributeMapping.attribute?.name}
                {/*<button className="focus:outline-none ml-2" onClick={onAddAttributeValue}>*/}
                {/*    <FontAwesomeIcon*/}
                {/*        icon={faPlus}*/}
                {/*        size="sm"*/}
                {/*        className="text-gray-800 hover:text-gray-400"*/}
                {/*    />*/}
                {/*</button>*/}
            </div>
            <div className={addAttributeValue ? "visible mb-10" : "hidden"}>
                {/*{tags &&*/}
                {/* <TagInput props={{tags: tags, onInputChanged: ''}} tags={tags} onTagsChanged={onTagsChanged}></TagInput>*/}
                {/*}*/}
                <TagInput tags={tags} onTagsChanged={onTagsChanged} onInputChange={() => {}} ></TagInput>
                <button onClick={onSubmit} className="bg-gray-500 text-white px-4 py-1 rounded-md">Save</button>
            </div>
            {/*<ProductAttributeValues productAttributeMapping={productAttributeMapping} />*/}
        </div>
    </>)
}

const ProductAttributeValues: FC<ExperienceExperienceAttributesProps> = ({experienceAttributeMapping}) => {
    return (<>
        <div className="pl-4">
            {experienceAttributeMapping?.attributeValues && experienceAttributeMapping?.attributeValues?.length > 0 &&
            experienceAttributeMapping?.attributeValues
                    .sort(function(a,b){ return (a.displayOrder > b.displayOrder) ? 1 : -1})
                    .map(attributeValue => {
                    return (<div key={attributeValue.id} className="text-sm">{attributeValue.name}</div>)
                })
            }
        </div>
    </>)
}
