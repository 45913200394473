import React, {useCallback, useEffect, useRef, useState} from 'react';
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import {hourMarketApi} from "../../../Utils/api/hourmarket-api";
import LoadingIndicator from "../../../components/LoadingIndicator";

const DeliveryArea = () => {
    const [loading, setLoading] = useState(true)
    const [testPostCode, setTestPostCode] = useState("")

    useEffect(() => {
        getDeliveryPolygon()
    }, []);

    // Store Polygon path in state
    const [path, setPath] = useState([
        { lat: 51.393768979024195, lng: -2.385755978408867 },
        { lat: 51.403193742404504, lng: -2.3409523619315333 },
        { lat: 51.37357417203841, lng: -2.3237003946672723 },
        { lat: 51.36741217857329, lng: -2.4052395434287037 }
    ]);

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Call setPath with new edited path
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map(latLng => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    }, [setPath]);

    // Clean up refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);


    // Bind refs to current Polygon and listeners
    const onLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener("set_at", onEdit),
                path.addListener("insert_at", onEdit),
                path.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    const isPostcodeInDeliveryArea = () => {
        hourMarketApi.isPostCodeInDeliveryArea(testPostCode)
            .then(r => {
                if (r.data.isInArea) {
                    alert(`Postcode ${testPostCode} is in this delivery area` )
                } else {
                    alert(`Postcode ${testPostCode} is not in this delivery area` )
                }
            })
    }
    const getDeliveryPolygon = () => {
        setLoading(true)
        hourMarketApi
            .getDeliveryPolygonSettings()
            .then(r => {
                if (r.data.polygon) {
                    const points = r.data.polygon.map(point =>
                        ({lat: point.latitude, lng: point.longitude})
                    )
                    setPath(points)
                }

                setLoading(false)
            })
    }

    const saveDeliveryArea = () => {

        const points = path.map(point =>
             ({latitude: point.lat, longitude: point.lng})
        )

        const deliveryArea = {
            polygon : points
        }

        hourMarketApi
            .saveDeliveryPolygonSettings(deliveryArea)
            .then(r => {
            })
    }

    return (
        <>
        {loading
            ? <LoadingIndicator size={"small"} fill={true} />
            : <div className="flex space-x-4">
        <div style={{height: 800}} className="w-10/12">
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyBLJjuSpzT5vFQfBtcbI2l2p4xag2wyun0"
                    language="en"
                    region="us"
                >
                    <GoogleMap
                        mapContainerClassName="App-map"
                        center={{ lat: 51.381235320261794, lng: -2.3601784349026493 }}
                        zoom={12}
                        version="weekly"
                        on
                    >
                        <Polygon
                            // Make the Polygon editable / draggable
                            editable
                            draggable
                            path={path}
                            // Event used when manipulating and adding points
                            onMouseUp={onEdit}
                            // Event used when dragging the whole Polygon
                            onDragEnd={onEdit}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
            <div className="flex-1 text-xs">
                <button
                    onClick={saveDeliveryArea}
                    className="mb-5 bg-pixie border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pixie hover:bg-opacity-50 focus:outline-none"
                >Save Delivery Area</button>
                <div className="text-sm underline mb-2">Test Postcode is in Delivery Polygon</div>
                <div className="flex flex-col space-y-2 mb-4">
                    <input className="border p-2 rounded-md"  value={testPostCode}
                           onChange={(e) => setTestPostCode(e.target.value)} />
                    <button
                        onClick={isPostcodeInDeliveryArea}
                        className="mt-5 bg-pixie border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pixie hover:bg-opacity-50 focus:outline-none"
                    >Test Postcode</button>
                </div>
                {/*<div className="text-sm underline mb-2">Polygon Points</div>*/}
                {/*{path.map(item =>*/}
                {/*    `Lat:${item.lat} Lng:${item.lng} `  )}*/}
            </div>
            </div>
        }
        </>
    )
};

export default DeliveryArea;
