import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

export interface ReturnedFetchData<T, Y> {
  data1?: T
  data2?: Y
  error?: any
  loading: boolean
}

const useFetchAll = <FetchedData1, FetchedData2>(
  apiCall1: Promise<AxiosResponse<FetchedData1>>,
  apiCall2: Promise<AxiosResponse<FetchedData2>>
): ReturnedFetchData<FetchedData1, FetchedData2> => {
  const [fetchedData, setFetchedData] = useState<ReturnedFetchData<FetchedData1, FetchedData2>>({
    loading: true,
  })

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [r1, r2]: [AxiosResponse<FetchedData1>, AxiosResponse<FetchedData2>] =
          await Promise.all([apiCall1, apiCall2])

        setFetchedData({ data1: r1.data, data2: r2.data, loading: false, error: undefined })
      } catch (error: any) {
        setFetchedData({ data1: undefined, data2: undefined, loading: false, error: error })
      }
    }

    fetchAllData().finally()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return fetchedData
}

export default useFetchAll
