//https://codesandbox.io/s/shy-moon-3q33r?file=/src/Card.jsx
import React, { Dispatch, FC, useCallback, useContext, useEffect, useState } from 'react'
import { ProductOptionLinePanel } from './productOptionLinePanel'
import update from 'immutability-helper'
import _uniqueId from 'lodash/uniqueId'
import { IProductAttributeMapping, IProductAttributeValue } from '../../../models/interface'
import { ProductActions, ProductState } from '../context/productReducer'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'
import _ from 'lodash'

interface ProductOptionLinesPanelProps {
  productAttributeMapping: IProductAttributeMapping
}

class ProductOptionLine {
  id: any
  name: string
  productAttributeValue: IProductAttributeValue

  constructor(id: any, name: string, productAttributeValue: IProductAttributeValue) {
    this.id = id
    this.name = name
    this.productAttributeValue = productAttributeValue
  }
}

export const ProductOptionLinesPanel: FC<ProductOptionLinesPanelProps> = ({
  productAttributeMapping,
}) => {
  const [attributeValueLines, setAttributeValueLines] = useState<ProductOptionLine[]>([])
  const productOptionsDispatch: Dispatch<ProductActions> = useContext(ProductDispatchContext)
  const productOptionsState = useContext(ProductStateContext)
  const attributeIndex = productOptionsState?.product?.attributes?.findIndex(
    (x) => x?.attribute?.id === productAttributeMapping?.attribute?.id!
  )

  useEffect(() => {
    if (attributeIndex !== undefined) {
      console.log('productOptionsState.product changed', attributeIndex)
      if (_.size(productOptionsState.product?.attributes?.[attributeIndex]?.attributeValues) > 0) {
        const attributeValues = _.concat(
          productOptionsState.product?.attributes?.[attributeIndex]?.attributeValues!
        )
        const productOptionLines = attributeValues
          ?.sort(function (a, b) {
            return a.displayOrder > b.displayOrder ? 1 : -1
          })
          ?.map((attributeValue) => {
            return new ProductOptionLine(_uniqueId(), attributeValue?.name ?? '', attributeValue!)
          })
        setAttributeValueLines(productOptionLines ?? [])
      }
    }
  }, [productOptionsState.product])

  const onAddAttributeValueLine = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const attributeIndex = productOptionsState?.product?.attributes?.findIndex(
      (x) => x?.attribute?.id === productAttributeMapping?.attribute?.id!
    )
    if (attributeIndex !== undefined && attributeIndex !== -1) {
      const attributeValues =
        productOptionsState.product?.attributes?.[attributeIndex].attributeValues
      const maxDisplayOrder =
        _.size(attributeValues) === 0
          ? 0
          : Math.max.apply(
              Math,
              attributeValues!.map(function (o) {
                return o.displayOrder
              })
            )

      const newValue: IProductAttributeValue = {
        name: '',
        displayOrder: maxDisplayOrder + 1,
        id: '00000000-0000-0000-0000-000000000000',
        colorSquaresRgb: '',
        imageSquaresPictureId: '',
      }
      productOptionsDispatch({
        type: 'addAttributeValue',
        productAttributeValue: newValue,
        productAttributeId: productAttributeMapping?.attribute?.id!,
      })
    }
  }

  const moveAttributeValueLine = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = attributeValueLines[dragIndex]
      const attributeValueLinesSorted = update(attributeValueLines, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
      productOptionsDispatch({
        type: 'reSortAttributeValues',
        productAttributeId: productAttributeMapping?.attribute?.id!,
        productAttributeValues: attributeValueLinesSorted.map((item) => {
          return item.productAttributeValue ?? {}
        }),
      })
    },
    [attributeValueLines]
  )

  return (
    <div className="flex flex-col space-y-2">
      {attributeValueLines.map((attributeValueLine, index) => (
        <ProductOptionLinePanel
          key={attributeValueLine.id}
          id={attributeValueLine.id}
          index={index}
          name={attributeValueLine?.name}
          attribute={productAttributeMapping?.attribute!}
          attributeMapping={productAttributeMapping}
          attributeValue={attributeValueLine.productAttributeValue}
          moveLine={moveAttributeValueLine}
        />
      ))}
      <button
        onClick={onAddAttributeValueLine}
        className="mt-5 text-left underline text-blue-600 font-medium text-xs focus:outline-none">
        Add another value
      </button>
    </div>
  )
}
