import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/outline'
import React, { FC, Fragment, useState } from 'react'
import { IVenue } from '../../models/interface'

interface Props {
  venues: IVenue[] | undefined
  initiaVenue: IVenue | undefined
  onVenueSelected: (venue: IVenue) => void
}

const VenuePicker: FC<Props> = ({ venues, initiaVenue, onVenueSelected }) => {
  const [selected, setSelected] = useState<IVenue | undefined>(initiaVenue)

  const venueSelected = (venue: IVenue) => {
    setSelected(venue)
    onVenueSelected(venue)
  }

  return (
    <>
      {venues && venues.length > 0 && (
        <div className="w-72 top-16">
          <Listbox value={selected} onChange={venueSelected}>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md border h-10 border-gray-300 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <span className="block truncate">{selected?.name ?? ''}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {venues?.map((venue, venueIdx) => (
                    <Listbox.Option
                      key={venueIdx}
                      className={({ active }) =>
                        `cursor-default select-none relative py-2 pl-10 pr-4 ${
                          active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'
                        }`
                      }
                      value={venue}>
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}>
                            {venue.name}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      )}
    </>
  )
}

export default VenuePicker
