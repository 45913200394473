import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CatalogType, IProductType } from '../../models/interface'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { ProductTypeCardComponent } from './producttype-card.component'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import NavigateBackLayout from '../../layouts/navigateBackLayout'

interface Props {
  catalogType: CatalogType
  title: string
  addTitle: string
  loadingTitle: string
  parentProductTypeId?: string
  parentProductTypeTitle?: string
}

const ProductTypesPage: FC<Props> = ({
  catalogType = CatalogType.Ecommerce,
  loadingTitle = 'Loading product types ...',
  addTitle = 'Add product type',
  title = 'Product Types',
  parentProductTypeId,
  parentProductTypeTitle,
}) => {
  const history = useHistory()
  const [productTypes, setProductTypes] = useState<IProductType[]>([])
  const [loading, setLoading] = useState(false)

  const addProductType = () =>
    history.push('/producttypedetails/add', {
      add: true,
      parentProductTypeId: parentProductTypeId,
      parentProductTypeTitle: parentProductTypeTitle,
      catalogType: catalogType,
    })

  // TODO: Put this in a custom hook.
  useEffect(() => {
    setLoading(true)
    if (!parentProductTypeId) {
      if (catalogType === CatalogType.Ecommerce) {
        hourMarketApi
          .getProductTypeTreeFirstLevel()
          .then((r) => {
            setProductTypes(r.data)
          })
          .finally(() => setLoading(false))
      }
      if (catalogType === CatalogType.Experience) {
        hourMarketApi
          .getExperienceProductTypeTreeFirstLevel()
          .then((r) => {
            setProductTypes(r.data)
          })
          .finally(() => setLoading(false))
      }
    } else {
      if (catalogType === CatalogType.Ecommerce) {
        hourMarketApi
          .getProductTypeTreeChildLevel(parentProductTypeId!)
          .then((r) => {
            setProductTypes(r.data)
          })
          .finally(() => setLoading(false))
      }
      if (catalogType === CatalogType.Experience) {
        hourMarketApi
          .getExperienceProductTypeTreeChildLevel(parentProductTypeId!)
          .then((r) => {
            setProductTypes(r.data)
          })
          .finally(() => setLoading(false))
      }
    }
  }, [catalogType, parentProductTypeId])

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow pb-8">
          {loading ? (
            <LoadingIndicatorPanel message={loadingTitle} />
          ) : (
            <>
              {parentProductTypeTitle && (
                <NavigateBackLayout title={parentProductTypeTitle} onPressed={history.goBack} />
              )}
              <CrudListHeader
                headerLabel={
                  !parentProductTypeTitle ? `${title}` : `SUB TYPES FOR ${parentProductTypeTitle}`
                }
                subheaderLabel={addTitle}
                addAction={addProductType}
              />
              <div className="bg-white min-w-full divide-y flex-1 rounded-md shadow-sm overflow-hidden">
                {productTypes
                  .sort(function (a, b) {
                    return a.displayOrder > b.displayOrder ? 1 : -1
                  })
                  .map((productType) => (
                    <div
                      key={productType.id}
                      className="bg-white cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                      <Link
                        to={{
                          pathname: `/producttypedetails/${productType.id}`,
                          state: { add: false, catalogType: catalogType },
                        }}>
                        <ProductTypeCardComponent
                          key={productType.id}
                          catalogType={catalogType}
                          productType={productType}
                        />
                      </Link>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductTypesPage
