import React, { FC, useEffect, useState } from 'react'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { Link, useHistory } from 'react-router-dom'
import { CatalogType, IProduct } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { ProductCard } from './product-card.component'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { useAppSelector } from '../../redux/Hooks'
import FilterContainer from './Filter/FilterContainer'

interface Props {
  catalogType: CatalogType
  title: string
  addTitle: string
  loadingTitle: string
  searchPlaceHolder: string
}

export const ProductsPage: FC<Props> = ({
  catalogType = CatalogType.Ecommerce,
  title = 'Products',
  addTitle = 'Add Product',
  loadingTitle = 'Loading products ...',
  searchPlaceHolder = 'name',
}) => {
  const history = useHistory()
  const [products, setProducts] = useState<IProduct[]>([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const addProduct = () => history.push('/products/add', { add: true, catalogType: catalogType })
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])
  const { searchProductName, filteredProductCategories } = useAppSelector(
    ({ appReducer: reducer }) => reducer
  )

  useEffect(() => {
    if (filteredProductCategories.length > 0 && searchProductName) {
      const filteredProducts = products.filter(
        (product) =>
          filteredProductCategories.some((categoryId) =>
            product.categories?.some((x) => x.id === categoryId)
          ) && product.name?.toLowerCase().includes(searchProductName.toLowerCase())
      )
      setFilteredProducts(filteredProducts)
    } else if (searchProductName) {
      const filteredProducts = products.filter((product) =>
        product.name?.toLowerCase().includes(searchProductName.toLowerCase())
      )
      setFilteredProducts(filteredProducts)
    } else if (filteredProductCategories.length > 0) {
      const filteredProducts = products.filter((product) =>
        filteredProductCategories.some((categoryId) =>
          product.categories?.some((x) => x.id === categoryId)
        )
      )
      setFilteredProducts(filteredProducts)
    } else {
      setFilteredProducts(products)
    }
  }, [filteredProductCategories, searchProductName, products])

  useEffect(() => {
    setLoading(true)
    if (catalogType === CatalogType.Ecommerce) {
      hourMarketApi
        .getProducts()
        .then((r) => {
          setFilteredProducts(r.data)
          setProducts(r.data)
        })
        .finally(() => setLoading(false))
    }
    if (catalogType === CatalogType.Experience) {
      hourMarketApi
        .getExperiences()
        .then((r) => {
          setFilteredProducts(r.data)
          setProducts(r.data)
        })
        .finally(() => setLoading(false))
    }
  }, [catalogType, refresh])

  const deleteProduct = async (id: string) =>
    await hourMarketApi.deleteProduct(id).finally(() => setRefresh(true))

  const showProductPreview = async (id: string) => history.push(`/products/preview/${id}`)

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow pb-8">
          {loading ? (
            <LoadingIndicatorPanel message={loadingTitle} />
          ) : (
            <>
              <CrudListHeader
                headerLabel={title}
                subheaderLabel={addTitle}
                addAction={addProduct}
              />
              <div className="flex flex-row">
                <FilterContainer
                  catalogType={catalogType}
                  searchTitle={
                    catalogType === CatalogType.Ecommerce ? 'Search Products' : 'Search Events'
                  }
                  filterCollectionTitle={
                    catalogType === CatalogType.Ecommerce
                      ? 'Filter By Collection'
                      : 'Filter By Collection'
                  }
                  filterProductTypesTitle={
                    catalogType === CatalogType.Ecommerce
                      ? 'Filter By Product Type'
                      : 'Filter By Event Type'
                  }
                  searchPlaceHolder={searchPlaceHolder}
                />
                <div className="flex-1">
                  <div className="bg-white divide-y flex-1 rounded-lg shadow-sm overflow-hidden">
                    {filteredProducts.map((product) => (
                      <div
                        key={product.id}
                        className=" cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                        <Link
                          to={{
                            pathname: `/products/${product.id}`,
                            state: { add: false, catalogType: catalogType },
                          }}>
                          <ProductCard
                            key={product.id}
                            product={product}
                            deleteProduct={deleteProduct}
                            showProductPreview={showProductPreview}
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
