import { post } from 'axios';
import {BaseUrl} from "../../Utils/api/api.config";

const uploadImage = (filename, blobDetails) => {
    const url = `${BaseUrl}/HourMarketAdmin/upload`;
    const formData = new FormData();
    formData.append('files', blobDetails, filename)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return  post(url, formData, config)
}

export {
    uploadImage
}
