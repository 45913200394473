import React, { FC } from 'react'
import { CatalogType } from '../../../models/interface'
import { ProductSearchPanel } from './ProductSearchPanel'
import CollectionsFilterContainer from './CollectionsFilterContainer'
import { ProductTypesFilterContainer } from './ProductTypesFilterContainer'

interface Props {
  catalogType: CatalogType
  searchTitle: string
  filterCollectionTitle: string
  filterProductTypesTitle: string
  searchPlaceHolder: string
  productTypesFilterEnabled?: boolean
}

const FilterContainer: FC<Props> = ({
  catalogType,
  searchTitle,
  filterCollectionTitle,
  filterProductTypesTitle,
  searchPlaceHolder,
  productTypesFilterEnabled = false,
}) => {
  return (
    <div className="w-1/5 bg-white border mr-3 px-4 pb-4 shadow-sm rounded-lg">
      <div className="text-base font-medium mt-4 mb-2">{searchTitle}</div>
      <ProductSearchPanel placeHolder={searchPlaceHolder} />
      <CollectionsFilterContainer title={filterCollectionTitle} catalogType={catalogType} />
      {productTypesFilterEnabled && (
        <ProductTypesFilterContainer title={filterProductTypesTitle} catalogType={catalogType} />
      )}
    </div>
  )
}

export default FilterContainer
