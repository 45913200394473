import addDays from 'date-fns/addDays'
import { EmptyGuid } from '../../models/interface'

export type SlotSchedule = {
  Id: string
  DateFrom: Date
  DateTo: Date
  SlotsPerHour: number
  ScheduleType: ScheduleType
  DayScheduleTypes: SlotScheduleDay[]
}

export type SlotScheduleDay = {
  DayType: SlotDayType
  Times: SlotTimeType[]
}

export type SlotDayType = {
  Number: number
  Display: string
}

export type SlotTimeType = {
  Open?: boolean
  Hours: number
  Minutes: number
  Display: string
  DateFrom: Date
  DateTo: Date
}

export const EmptySchedule = (scheduleType: ScheduleType, dateFrom: Date): SlotSchedule => {
  return {
    Id: EmptyGuid,
    DateFrom: dateFrom,
    DateTo: addDays(dateFrom, 7),
    SlotsPerHour: 1,
    ScheduleType: scheduleType,
    DayScheduleTypes: [],
  } as SlotSchedule
}

export type ScheduleType = 'Collection' | 'Delivery' | 'CollectionTemplate' | 'DeliveryTemplate'
