import {
  defaultProduct,
  ICategory,
  IProductAttribute,
  IProductAttributeMapping,
  IProductAttributeValue,
  IProductDetails,
  IProductType,
  IProductVariant,
  IVenue,
} from '../../../models/interface'
import { UseFormSetValue } from 'react-hook-form/dist/types/form'
import ProductInventoryStrategy from '../../../Types/Products/ProductInventoryTypes'

const ProductReducerInitialState: ProductState = {
  isLoading: false,
  product: defaultProduct,
  productAttributes: [],
  productVariants: [],
  venues: [],
  setValue: (name) => {},
  dirty: false,
}

const ProductReducer = (state: ProductState, action: ProductActions) => {
  switch (action.type) {
    case 'reset':
      state = ProductReducerInitialState
      return
    case 'getProduct':
      state.product = action.data
      return
    case 'getAttributes':
      state.productAttributes = action.data
      return
    case 'getProductVariants':
      state.productVariants = action.data
      return
    case 'addCategory':
      state.product?.categories.push(action.category)
      state.setValue('categories', [], { shouldDirty: true })
      return
    case 'deleteCategory':
      if (state.product?.categories !== undefined) {
        state.product!.categories = state.product?.categories?.filter(
          (item) => item.id !== action.category.id
        )
        state.setValue('categories', [...state.product?.categories!], { shouldDirty: true })
      }
      return
    case 'changeName':
      if (state.product !== undefined) {
        state.product!.name = action.value
      }
      return
    case 'changeActiveFrom':
      if (state.product !== undefined) {
        state.product!.activeFrom = action.value
      }
      return
    case 'changeActiveTo':
      if (state.product !== undefined) {
        state.product!.activeTo = action.value
      }
      return
    case 'changeActiveDescription':
      if (state.product !== undefined) {
        state.product!.activeDescription = action.value
      }
      return
    case 'changeLocationDescription':
      if (state.product !== undefined) {
        state.product!.locationDescription = action.value
      }
      return
    case 'changeLocationCoordinates':
      if (state.product !== undefined) {
        state.product!.locationCoordinates = action.value
      }
      return
    case 'changeProductCode':
      if (state.product !== undefined) {
        state.product!.productCode = action.value
      }
      return
    case 'changeProductType':
      if (state.product !== undefined) {
        state.product!.productType = action.value
      }
      return
    case 'changeDescription':
      if (state.product !== undefined) {
        state.product!.description = action.value
      }
      return
    case 'changePrice':
      if (state.product !== undefined) {
        state.product!.price = action.value
      }
      return
    case 'changeActive':
      if (state.product !== undefined) {
        state.product!.active = action.value
      }
      return
    case 'changeDoesNotRequireBooking':
      if (state.product !== undefined) {
        state.product!.doesNotRequireBooking = action.value
      }
      return
    case 'changeDisplayOrder':
      if (state.product !== undefined) {
        const categoryIndex = state.product?.categories?.findIndex(
          (x) => x?.id === action.categoryId
        )
        if (categoryIndex !== undefined && categoryIndex !== -1) {
          state.product.categories[categoryIndex].displayOrder = action.displayOrder
        }
      }
      return
    case 'addAttribute':
      state.product?.attributes.push(action.productAttributeMapping)
      state.dirty = true
      return
    case 'deleteAttribute':
      const deleteIndex = state.product?.attributes?.findIndex(
        (x) => x?.attribute?.id === action.productAttribute.id
      )
      if (deleteIndex !== undefined && deleteIndex !== -1) {
        state.product?.attributes.splice(deleteIndex, 1)
        state.dirty = true
      }
      return
    case 'updateAttribute':
      const updateAttIndex = state.product?.attributes?.findIndex(
        (x) => x.id === action.productAttributeMapping.id
      )
      if (updateAttIndex !== undefined && updateAttIndex !== -1) {
        state.product!.attributes[updateAttIndex]!.attribute!.id! = action.productAttributeId
        state.dirty = true
      }
      return
    case 'updateAttributeDisplayType':
      const updateAttributeDisplayTypeIndex = state.product?.attributes?.findIndex(
        (x) => x.id === action.productAttributeMapping.id
      )
      if (updateAttributeDisplayTypeIndex !== undefined && updateAttributeDisplayTypeIndex !== -1) {
        state.product!.attributes[updateAttributeDisplayTypeIndex]!.displayType =
          action.productAttributeMapping.displayType
      }
      state.dirty = true
      return
    case 'reSortAttributes':
      state.product?.attributes.forEach((attributeMapping) => {
        attributeMapping.displayOrder = action.productAttributes.findIndex(
          (x) => x.id === attributeMapping.attribute?.id
        )
        state.dirty = true
      })
      return
    case 'addAttributeValue':
      const index = state.product?.attributes?.findIndex(
        (x) => x?.attribute?.id === action.productAttributeId
      )
      if (index !== undefined && index !== -1) {
        state.product?.attributes![index].attributeValues?.push(action.productAttributeValue)
        state.dirty = true
      }
      return
    case 'deleteAttributeValue':
      const deleteAttributeIndex = state.product?.attributes?.findIndex(
        (x) => x?.attribute?.id === action.productAttributeId
      )
      if (deleteAttributeIndex !== undefined && deleteAttributeIndex !== -1) {
        const deleteAttributeValueIndex = state.product?.attributes[
          deleteAttributeIndex
        ].attributeValues?.findIndex(
          (x) =>
            x?.displayOrder === action.productAttributeValue.displayOrder &&
            x.id === action.productAttributeValue.id
        )

        if (deleteAttributeValueIndex !== undefined && deleteAttributeValueIndex !== -1) {
          state.product?.attributes[deleteAttributeIndex].attributeValues?.splice(
            deleteAttributeValueIndex,
            1
          )
          state.dirty = true
        }
      }
      return
    case 'updateAttributeValue':
      const updateAttributeIndex = state.product?.attributes?.findIndex(
        (x) => x?.attribute?.id === action.productAttributeId
      )
      if (updateAttributeIndex !== undefined && updateAttributeIndex !== -1) {
        const updateAttributeValueIndex = state.product?.attributes![
          updateAttributeIndex
        ].attributeValues?.findIndex(
          (x) =>
            x?.displayOrder === action.productAttributeValue.displayOrder &&
            x.id === action.productAttributeValue.id
        )

        if (updateAttributeValueIndex !== undefined && updateAttributeValueIndex !== -1) {
          state.product!.attributes![updateAttributeIndex].attributeValues[
            updateAttributeValueIndex
          ].name = action.optionAttributeValue.name
          state.product!.attributes![updateAttributeIndex].attributeValues[
            updateAttributeValueIndex
          ].colorSquaresRgb = action.optionAttributeValue.colorSquaresRgb
          state.dirty = true
        }
      }
      return
    case 'updateProductVariant':
      const updateProductVariantIndex = state.product?.variants?.findIndex(
        (x) => x?.id === action.productVariant.id
      )
      if (updateProductVariantIndex !== undefined && updateProductVariantIndex !== -1) {
        state.product!.variants![updateProductVariantIndex] = action.productVariant
        state.dirty = true
      }
      return
    case 'reSortAttributeValues': {
      const resortAttributeIndex = state.product?.attributes?.findIndex(
        (x) => x?.attribute?.id === action.productAttributeId
      )
      if (resortAttributeIndex !== undefined && resortAttributeIndex !== -1) {
        state.product?.attributes![resortAttributeIndex].attributeValues.forEach(
          (attributeValue) => {
            attributeValue.displayOrder = action.productAttributeValues.findIndex(
              (x) => x.name === attributeValue?.name
            )
            state.setValue('name', state.product?.name, { shouldDirty: true })
          }
        )
        state.dirty = true
      }
      return
    }
    case 'changeProductTypes': {
      state.dirty = true
      const length = state.product?.productTypes?.length ?? 0
      if (state.product) {
        state.product.productTypes = [...action.productTypes.map((item) => item)]
      }

      return
    }
    case 'addProductType': {
      const productTypeExists = state.product?.productTypes?.some(
        (x) => x.id === action.productType.id
      )
      if (!productTypeExists) {
        state.product?.productTypes?.push(action.productType)
      }

      state.setValue('name', state.product?.name, { shouldDirty: true })
      return
    }
    case 'deleteProductType': {
      const productTypeIndex = state.product?.productTypes?.findIndex(
        (x) => x.id === action.productType.id
      )
      if (productTypeIndex && productTypeIndex >= 0) {
        state.product?.productTypes?.splice(productTypeIndex, 1)
      }
      state.setValue('name', state.product?.name, { shouldDirty: true })
      return
    }
    case 'changeVenue':
      if (state.product !== undefined) {
        state.product.venue = action.venue
        state.dirty = true
      }
      return
    case 'changeInventoryStrategy':
      if (state.product !== undefined) {
        state.product.inventoryStrategy = action.inventoryStrategy.id
        state.dirty = true
      }
      return
    case 'changeInventory':
      if (state.product !== undefined) {
        state.product.inventoryStartQuantity = action.startQuantity
        state.product.inventoryQuantity = action.quantity
        state.product.inventoryAllocatedQuantity = action.allocatedQuantity
      }
      return
    case 'getVenues':
      state.venues = action.venues
      return
    default:
      return
  }
}

export type ProductState = {
  isLoading: boolean
  product?: IProductDetails
  productAttributes: IProductAttribute[]
  productVariants: IProductVariant[]
  setValue: UseFormSetValue<IProductDetails>
  venues: IVenue[]
  dirty: boolean
}

export type ProductActions =
  | { type: 'reset' }
  | { type: 'getAttributes'; data: IProductAttribute[] }
  | { type: 'getProduct'; data: IProductDetails }
  | { type: 'getProductVariants'; data: IProductVariant[] }
  | { type: 'getVenues'; venues: IVenue[] }
  | { type: 'addCategory'; category: ICategory }
  | { type: 'deleteCategory'; category: ICategory }
  | { type: 'changeName'; value: string }
  | { type: 'changeDescription'; value: string }
  | { type: 'changePrice'; value: number }
  | { type: 'changeActive'; value: boolean }
  | { type: 'changeActiveFrom'; value: string }
  | { type: 'changeActiveTo'; value: string }
  | { type: 'changeActiveDescription'; value: string }
  | { type: 'changeDoesNotRequireBooking'; value?: boolean }
  | { type: 'changeLocationCoordinates'; value: string }
  | { type: 'changeLocationDescription'; value: string }
  | { type: 'changeProductCode'; value: string }
  | { type: 'changeProductType'; value: string }
  | { type: 'changeDisplayOrder'; displayOrder: number; categoryId: string }
  | { type: 'addAttribute'; productAttributeMapping: IProductAttributeMapping }
  | { type: 'deleteAttribute'; productAttribute: IProductAttribute }
  | {
      type: 'updateAttribute'
      productAttributeMapping: IProductAttributeMapping
      productAttributeId: string
    }
  | {
      type: 'updateAttributeDisplayType'
      productAttributeMapping: IProductAttributeMapping
      productAttributeId: string
    }
  | { type: 'reSortAttributes'; productAttributes: IProductAttribute[] }
  | {
      type: 'addAttributeValue'
      productAttributeValue: IProductAttributeValue
      productAttributeId: string
    }
  | {
      type: 'deleteAttributeValue'
      productAttributeValue: IProductAttributeValue
      productAttributeId: string
    }
  | {
      type: 'updateAttributeValue'
      productAttributeValue: IProductAttributeValue
      productAttributeId: string
      optionAttributeValue: IProductAttributeValue
    }
  | { type: 'updateProductVariant'; productVariant: IProductVariant }
  | {
      type: 'reSortAttributeValues'
      productAttributeValues: IProductAttributeValue[]
      productAttributeId: string
    }
  | {
      type: 'addProductType'
      productType: IProductType
    }
  | {
      type: 'deleteProductType'
      productType: IProductType
    }
  | {
      type: 'changeProductTypes'
      productTypes: IProductType[]
    }
  | { type: 'changeTrackInventory'; trackInventory: boolean }
  | { type: 'changeTrackInventoryByVariant'; trackInventoryByVariant: boolean }
  | { type: 'changeVenue'; venue: IVenue }
  | { type: 'changeInventoryStrategy'; inventoryStrategy: ProductInventoryStrategy }
  | {
      type: 'changeInventory'
      startQuantity: number
      quantity: number
      allocatedQuantity: number
    }
  | { type: 'setDirty'; isDirty: boolean }

export { ProductReducerInitialState, ProductReducer }
