import React, {Component, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Wrapper from './styled/Wrapper';
import Tag from './styled/Tag';
import Input from './styled/Input';
import TagDelete from './styled/TagDelete';

const TagInput = (props) => {
    const {onTagsChanged} = props;

    const [selectedTags, setSelectedTags] = useState([])
    let input = React.createRef();

    useEffect(() => {
        const propTags = props.tags.map((tag, index) => {
            return {
                index,
                ...tag,
            }
        });

        setSelectedTags([
            ...selectedTags,
            ...propTags,
        ])

        focusInput();
    }, [props.tags]);

    useEffect(() => {
        onTagsChanged(selectedTags);
    },[selectedTags]);

    const onInputKeyUp = (e) => {
        const { addTagOnEnterKeyPressed, onTagsChanged} = props;
        const inputValue = e.target.value;
        const inputNotEmpty = inputValue && inputValue.trim() !== '';
        const addTag = () => {
            setSelectedTags([
                ...selectedTags, {
                    index: selectedTags.length + 1,
                    displayValue: inputValue,
                },
            ])

            clearInput()
        }

        if (e.key === 'Enter' && inputNotEmpty && addTagOnEnterKeyPressed) {
            addTag();
        }
    }

    const onInputKeyDown = (e) => {
        const { onTagsChanged } = props;
        const deleteLastTag = () => {

            setSelectedTags(selectedTags.splice(0, selectedTags.length - 1))
        };

        if (e.key === 'Backspace' && e.target.selectionStart === 0) {
            deleteLastTag();
        }
    }

    const clearInput = () => {
        input.value = '';
    }

    const focusInput = () => {
        input?.focus();
    }

    const removeTag = (index) => {
        setSelectedTags(selectedTags.filter(tag => tag.index !== index))
    }

    const renderTags = () => {
        const TagComponent = getTagStyledComponent();
        const Delete = getTagDeleteComponent();
        const DeleteIcon = getDeleteIcon();

        return selectedTags.length > 0 ?
            selectedTags.map((tag, index) =>
                <TagComponent key={index}>
                    {tag.displayValue}
                    <Delete index={tag.index} onClick={() => removeTag(tag.index)}>{DeleteIcon}</Delete>
                </TagComponent>
            ) :
            null;
    }

    const renderPlaceholder = () => {
        const { placeholder, hideInputPlaceholderTextIfTagsPresent } = props;

        return hideInputPlaceholderTextIfTagsPresent && selectedTags.length > 0 ? null : placeholder;
    }

    const getDeleteIcon = () => {
        const { tagDeleteIcon } = props;
        return tagDeleteIcon || ' x';
    }

    const getTagDeleteComponent = () => {
        const { tagDeleteStyle } = props;

        return tagDeleteStyle ? styled(TagDelete)`
            ${tagDeleteStyle}
        ` : TagDelete;
    }

    const getTagStyledComponent = () => {
        const { tagStyle } = props;

        return tagStyle ? styled(Tag)`
            ${tagStyle}
        ` : Tag;
    }

    const getInputWrapperStyledComponent = () => {
        const { wrapperStyle } = props;

        return wrapperStyle ? styled(Wrapper)`
            ${wrapperStyle}
        ` : Wrapper;
    }

    const getInputStyledComponent = () => {
        const { inputStyle } = props;

        return inputStyle ? styled(Input)`
            ${inputStyle}
        ` : Input;
    }

    const { onInputChanged } = props;
    const InputWrapper = getInputWrapperStyledComponent();
    const InputComponent = getInputStyledComponent();

    return (
            <InputWrapper onClick={focusInput}>
                {renderTags()}
                <InputComponent
                    ref={el => input = el}
                    onChange={onInputChanged}
                    placeholder={renderPlaceholder()}
                    type="text"
                    onKeyUp={onInputKeyUp}
                    onKeyDown={onInputKeyDown}/>
            </InputWrapper>
        )
}

TagInput.propTypes = {
    tags: PropTypes.array.isRequired,
    onTagsChanged: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    wrapperStyle: PropTypes.string,
    inputStyle: PropTypes.string,
    tagStyle: PropTypes.string,
    tagDeleteStyle: PropTypes.string,
    tagDeleteIcon: PropTypes.element,
    addTagOnEnterKeyPressed: PropTypes.bool,
    hideInputPlaceholderTextIfTagsPresent: PropTypes.bool,
}

TagInput.defaultProps = {
    placeholder: 'Type and hit enter to add a product attribute value...',
    addTagOnEnterKeyPressed: true,
    hideInputPlaceholderTextIfTagsPresent: true,
}

export default TagInput;
