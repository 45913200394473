import React, { FC } from 'react'
import { IVenue } from '../../models/interface'

interface Props {
  venue: IVenue
}

const VenueCard: FC<Props> = ({ venue }) => {
  return (
    <div className="flex items-center px-2 py-2">
      <div className="ml-4 text-base text-gray-800 font-normal">{venue.name}</div>
    </div>
  )
}

export default VenueCard
