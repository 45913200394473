
const ImageToolImageService = (function() {

  let images =  [
      {
      "id": "19215e58-e75d-4d13-bea4-94bd34c44c35",
      "mediaType": 1,
      "filename": "d52edc85-c126-4031-adda-ef79c01a9237",
      "location": "https://marathonshopping.blob.core.windows.net/images/d52edc85-c126-4031-adda-ef79c01a9237",
      "title": "another category",
      "description": null,
      "duration": 0,
      "contentType": null,
      "added": "2020-12-16T23:17:48.064249",
      "size": 431166,
      "imageBase64Data": null,
      "metaData": null
      },
      {
          "id": "bb29f04f-c8e7-455f-8a15-df2d4bc215cf",
          "mediaType": 1,
          "filename": "0b3c96d4-1f49-4681-a866-7b4c3d2264fd",
          "location": "https://marathonshopping.blob.core.windows.net/images/0b3c96d4-1f49-4681-a866-7b4c3d2264fd",
          "title": "Test Name",
          "description": null,
          "duration": 0,
          "contentType": null,
          "added": "2020-12-16T22:21:23.909739",
          "size": 40972,
          "imageBase64Data": null,
          "metaData": null
      },
      {
          "id": "15e98bd4-3021-456b-b9f6-c4e550210b1a",
          "mediaType": 1,
          "filename": "3a142eb7-f296-497a-8ff0-44e429b13632",
          "location": "https://marathonshopping.blob.core.windows.net/images/3a142eb7-f296-497a-8ff0-44e429b13632",
          "title": "React Image Title 1",
          "description": null,
          "duration": 0,
          "contentType": null,
          "added": "2020-12-16T20:51:35.694988",
          "size": 26893,
          "imageBase64Data": null,
          "metaData": null
      }];

    const retrieveItems = async() => {
        return Promise.resolve(images);
    };

    const getItem = async(itemLink) => {
        for(var i = 0; i < images.length; i++) {
            if ( images[i].link === itemLink) {
                return Promise.resolve(images[i]);
            }
        }
        return null;
    }

    const createItem = async(item) => {
        images.push(item);
        return Promise.resolve(item);
    }

    const deleteItem = async (itemId) => {
        images = images.filter(item => item.id !== itemId)

        return Promise.resolve(itemId);
    }

    const updateItem = async(item) => {
    }
    return {
        retrieveItems: retrieveItems,
        getItem: getItem,
        createItem: createItem,
        deleteItem: deleteItem,
        updateItem: updateItem
    }
})();

export default ImageToolImageService;
