import React, { FC } from 'react'
import { useAppSelector } from '../../../redux/Hooks'
import { useDispatch } from 'react-redux'

export type Props = {
  placeHolder: string
}

export const ProductSearchPanel: FC<Props> = ({ placeHolder }) => {
  const { searchProductName } = useAppSelector(({ appReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  return (
    <div>
      <input
        type="text"
        value={searchProductName}
        onChange={(e) => dispatch({ type: 'searchProductNameChanged', name: e.target.value })}
        placeholder={placeHolder}
        className="border-gray-300 text-gray-500 p-1 w-full placeholder-gray-300 selected:ring-0 focus:ring-0 focus:border-gray-200 focus:outline-none"
      />
    </div>
  )
}
