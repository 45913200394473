import { Configuration, LogLevel } from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID!,
    authority: process.env.REACT_APP_ADB2C_AUTHORITY,
    knownAuthorities: process.env.REACT_APP_ADB2C_KNOWN_AUTHORITIES?.split(','),
    //knownAuthorities: ['pixieauth.b2clogin.com'],
    redirectUri: process.env.REACT_APP_ADB2C_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            break
          case LogLevel.Info:
            break
          case LogLevel.Verbose:
            break
          case LogLevel.Warning:
            break
          default:
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  authority: process.env.REACT_APP_ADB2C_AUTHORITY,
  scopes: process.env.REACT_APP_ADB2C_LOGIN_SCOPES?.split(',') ?? [],
}

export const silentRequest = {
  scopes: ['openid', 'profile'],
  loginHint: 'example@domain.net',
}

export const editProfile = {
  authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY,
}

export const policyNames = {
  signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY,
}
