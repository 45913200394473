import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from '@fortawesome/pro-duotone-svg-icons'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import {
  defaultCategory,
  defaultMediaItem,
  IMediaItem,
  IMediaItemUpload,
} from '../../models/interface'
import { InputElement } from '../../components/form-elements/input-element'
import NavigateBackLayout from '../../layouts/navigateBackLayout'
import FooterSaveCancelDeleteLayout from '../../layouts/FooterSaveCancelDeleteLayout'

interface MediaItemLocationProps {
  add: boolean
}

interface MediaItemParamsProps {
  id: string
}

const MediaDetailsPage = () => {
  const [mediaItem, setMediaItem] = useState<IMediaItem>(defaultMediaItem)
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams<MediaItemParamsProps>()
  const location = useLocation<MediaItemLocationProps>()
  const add = location.state.add
  const history = useHistory()
  const [image, setImage] = useState<string | null>(null)
  const [uploadedFile, setUploadedFile] = useState(null)

  const { register, handleSubmit, reset, formState, control } = useForm<IMediaItem>({
    defaultValues: defaultCategory,
  })

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setUploadedFile(acceptedFiles[0])
    setImage(URL.createObjectURL(acceptedFiles[0]))
  }, [])

  useEffect(() => {
    if (!add) {
      hourMarketApi
        .getMediaItem(id)
        .then((r) => {
          console.log(r.data)
          setMediaItem(r.data)
          setImage(r.data.location ?? '')
          reset(r.data)
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const onSubmit = (data: any) => {
    if (add) {
      let mediaItemUpload: IMediaItemUpload = {
        MediaType: 1,
        Title: mediaItem?.title ?? '',
        ContentType: 'image/png',
        Filename: 'test12345',
        MediaFile: uploadedFile!,
      }

      hourMarketApi.uploadImageMediaItem(mediaItemUpload).then((r) => {})
    } else {
    }
  }

  const disabled =
    !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md">
          <NavigateBackLayout title={'MEDIA ITEMS'} onPressed={history.goBack} />
          <div className="mx-auto max-w-7xl shadow-sm p-8 rounded-md border bg-white">
            <InputElement label={'Title'} id={'title'} type={'text'} register={register('title')} />
            <InputElement
              label={'Description'}
              id={'description'}
              type={'text'}
              register={register('description')}
            />
            <ImagePanel categoryImage={image ?? ''} onDrop={onDrop} />
          </div>
        </div>
      </div>
      {/* Footer */}
      {!loading && (
        <FooterSaveCancelDeleteLayout
          onCancel={history.goBack}
          onDelete={history.goBack}
          disabled={disabled}
          isSubmitting={formState.isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          showDeleteButton={false}
        />
      )}
    </div>
  )
}

export default MediaDetailsPage

type ImagePanelProps = {
  categoryImage: string
  onDrop: (acceptedFiles: any) => void
}

const ImagePanel: FC<ImagePanelProps> = ({ categoryImage, onDrop }) => {
  return (
    <div>
      <div className="text-gray-800 font-semibold mt-6">Image</div>
      <ImageUploader categoryImage={categoryImage} onDrop={onDrop} />
    </div>
  )
}

type ImageUploaderProps = {
  categoryImage: string
  onDrop: (acceptedFiles: any) => void
}

const ImageUploader: FC<ImageUploaderProps> = ({ categoryImage, onDrop }) => {
  return categoryImage ? (
    <ImageUploadMainImage categoryImage={categoryImage} />
  ) : (
    <ImageUploadControl onDrop={onDrop} />
  )
}

type ImageUploadMainImageProps = {
  categoryImage: string
}

const ImageUploadMainImage: FC<ImageUploadMainImageProps> = ({ categoryImage }) => {
  return (
    <div className="flex flex-col">
      <div className="inline-block relative mt-2">
        <img className="h-64 w-64 object-cover block hover:opacity-70" src={categoryImage} alt="" />
      </div>
    </div>
  )
}

type ImageUploadControlProps = {
  onDrop: (acceptedFiles: any) => void
}

const ImageUploadControl: FC<ImageUploadControlProps> = ({ onDrop }) => {
  return (
    <div className="">
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className="flex flex-col items-center space-y-5 justify-center w-full h-48  border-4 border-gray-100 bg-gray-50 hover:bg-gray-100 hover:border-4 hover:border-gray-200 cursor-pointer focus:outline-none">
              <input {...getInputProps()} />
              {/*<FontAwesomeIcon icon={faCloudUpload} size="3x" style={{"--fa-primary-color":"#3B82F6", "--fa-secondary-color":"#3B82F6"}}  />*/}
              <FontAwesomeIcon icon={faCloudUpload} size="3x" />
              <div className="text-xs text-gray-400">Drop image or click to upload image</div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}
