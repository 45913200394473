import React, { Dispatch, FC, RefObject, useEffect, useRef, useState } from 'react'
import { Prompt, useParams } from 'react-router'
import {
  CatalogType,
  defaultProduct,
  ICategoryTree,
  IEntityMediaItemDelete,
  IEntityMediaItemUpload,
  IMediaItem,
  IMediaItemUploadResponse,
  IProductAttribute,
  IProductDetails,
  IVenue,
  ProductStatus,
} from '../../models/interface'
import { useForm, useWatch } from 'react-hook-form'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { useHistory, useLocation } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import {
  ProductActions,
  ProductReducer,
  ProductReducerInitialState,
  ProductState,
} from './context/productReducer'
import { useImmerReducer } from 'use-immer'
import { ProductVariantsContainer, ProductVariantsManage } from './ProductVariants'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import {
  InputCheckBoxElement,
  InputElement,
  InputHtmlElement,
  InputLabelElement,
  InputNumberElement,
} from '../../components/form-elements/input-element'
import { Control } from 'react-hook-form/dist/types/form'
import { YesNoDialog } from '../../components/dialogs'
import ImageUpload from '../../components/imagetool/imageUpload'
import { ProductTypesContainer, ProductTypesManage } from './ProductTypes'
import { CategoriesPanel } from './Categories/index.'
import { SaveCancelDeleteFooter } from './Footer'
import { ProductOptionsContainer, ProductOptionsManage } from './ProductOptions'
import { SideMenuContainer } from './SideMenu'
import NavigateBackLayout from '../../layouts/navigateBackLayout'
import VenuePicker from '../../layouts/Venues/VenuePicker'
import ProductInventoryStrategy, {
  ProductInventoryStrategies,
} from '../../Types/Products/ProductInventoryTypes'
import GenericDialog from '../../layouts/general/GenericDialog'
import ProductInventoryLayout from '../../layouts/Products/ProductInventoryLayout'
import ProductInventoryContainer from './ProductInventory/ProductInventoryContainer'

interface ProductDetailsState {
  add: boolean
  categoryId: string
  displayOrderWithinCategory?: number
  catalogType: CatalogType
}

const ProductStateContext = React.createContext<ProductState>({} as ProductState)
const ProductDispatchContext = React.createContext<Dispatch<ProductActions>>(
  {} as Dispatch<ProductActions>
)

const ProductDetails: FC = () => {
  const [product, setProduct] = useState<IProductDetails>(defaultProduct)
  const [categories, setCategories] = useState<ICategoryTree[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const location = useLocation<ProductDetailsState>()
  const { add, categoryId, displayOrderWithinCategory, catalogType } = location.state
  const [showVariantOptions, setShowVariantOptions] = useState<boolean>(false)
  const [showVariants, setShowVariants] = useState<boolean>(false)
  const [showProductTypes, setShowProductTypes] = useState<boolean>(false)
  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [isAddMode, setIsAddMode] = useState(add)
  const [productId, setProductId] = useState(id)
  const imageGalleryUploadRef = useRef<HTMLInputElement>()
  const [openManageInventory, setOpenManageInventory] = useState(false)

  const showDeleteProductModal = () => setShowDeleteModal(true)

  const { register, handleSubmit, reset, formState, control, setValue } = useForm<IProductDetails>({
    defaultValues: defaultProduct,
  })

  const [state, dispatch] = useImmerReducer(ProductReducer, {
    ...ProductReducerInitialState,
    setValue: setValue,
  })

  const IsolateReRender: FC<{ control: Control<IProductDetails> }> = ({ control }) => {
    const name = useWatch({ control, name: 'name' })
    const description = useWatch({ control, name: 'description' })
    const active = useWatch({ control, name: 'active' })
    const price = useWatch({ control, name: 'price' })
    const displayOrder = useWatch({ control, name: 'displayOrder' })
    const activeDescription = useWatch({ control, name: 'activeDescription' })
    const locationCoordinates = useWatch({ control, name: 'locationCoordinates' })
    const locationDescription = useWatch({ control, name: 'locationDescription' })
    const productCode = useWatch({ control, name: 'productCode' })
    const productType = useWatch({ control, name: 'productType' })
    const activeFrom = useWatch({ control, name: 'activeFrom' })
    const activeTo = useWatch({ control, name: 'activeTo' })
    const doesNotRequireBooking = useWatch({ control, name: 'doesNotRequireBooking' })

    dispatch({ type: 'changeName', value: name !== undefined ? name : '' })
    dispatch({ type: 'changeDescription', value: description !== undefined ? description : '' })
    dispatch({ type: 'changePrice', value: price !== undefined ? price : 0.0 })
    dispatch({ type: 'changeActive', value: active !== undefined ? active : true })
    dispatch({ type: 'changeDoesNotRequireBooking', value: doesNotRequireBooking })
    dispatch({
      type: 'changeActiveDescription',
      value: activeDescription !== undefined ? activeDescription : '',
    })
    dispatch({
      type: 'changeLocationDescription',
      value: locationDescription !== undefined ? locationDescription : '',
    })
    dispatch({
      type: 'changeLocationCoordinates',
      value: locationCoordinates !== undefined ? locationCoordinates : '',
    })
    dispatch({ type: 'changeProductCode', value: productCode !== undefined ? productCode : '' })
    dispatch({ type: 'changeProductType', value: productType !== undefined ? productType : '' })
    dispatch({ type: 'changeActiveFrom', value: activeFrom !== undefined ? activeFrom : '' })
    dispatch({ type: 'changeActiveTo', value: activeTo !== undefined ? activeTo : '' })
    dispatch({
      type: 'changeDisplayOrder',
      displayOrder: displayOrder !== undefined ? displayOrder : 0,
      categoryId: categoryId,
    })
    dispatch({ type: 'changeProductCode', value: productCode !== undefined ? productCode : '' })
    return <></>
  }

  useEffect(() => {
    setLoading(true)

    // TODO: Move all this to custom data hooks.
    async function fetchProductData() {
      const [r1, r2]: [AxiosResponse<IProductAttribute[]>, AxiosResponse<ICategoryTree[]>] =
        await Promise.all([
          hourMarketApi.getProductAttributes(),
          hourMarketApi.getCategoryFullTree(),
        ])
      return { productsAttributes: r1.data, categories: r2.data }
    }

    async function fetchExperienceData() {
      const [r1, r2, r3]: [
        AxiosResponse<IProductAttribute[]>,
        AxiosResponse<ICategoryTree[]>,
        AxiosResponse<IVenue[]>
      ] = await Promise.all([
        hourMarketApi.getExperienceProductAttributes(),
        hourMarketApi.getExperiencesCategoryFullTree(),
        hourMarketApi.getVenues(),
      ])
      return { productsAttributes: r1.data, categories: r2.data, venues: r3.data }
    }

    async function fetchProductAndData() {
      const [r1, r2, r3]: [
        AxiosResponse<IProductDetails>,
        AxiosResponse<IProductAttribute[]>,
        AxiosResponse<ICategoryTree[]>
      ] = await Promise.all([
        hourMarketApi.getProduct(productId),
        hourMarketApi.getProductAttributes(),
        hourMarketApi.getCategoryFullTree(),
      ])
      return { productDetails: r1.data, productsAttributes: r2.data, categories: r3.data }
    }

    async function fetchExperienceAndData() {
      const [r1, r2, r3, r4]: [
        AxiosResponse<IProductDetails>,
        AxiosResponse<IProductAttribute[]>,
        AxiosResponse<ICategoryTree[]>,
        AxiosResponse<IVenue[]>
      ] = await Promise.all([
        hourMarketApi.getExperience(productId),
        hourMarketApi.getExperienceProductAttributes(),
        hourMarketApi.getExperiencesCategoryFullTree(),
        hourMarketApi.getVenues(),
      ])
      return {
        productDetails: r1.data,
        productsAttributes: r2.data,
        categories: r3.data,
        venues: r4.data,
      }
    }

    if (!isAddMode) {
      setLoading(true)

      if (catalogType === CatalogType.Ecommerce) {
        fetchProductAndData()
          .then((result) => {
            if (categoryId && displayOrderWithinCategory) {
              result.productDetails.displayOrder = displayOrderWithinCategory
            }
            setProduct(result.productDetails)
            reset(result.productDetails)
            setCategories(result.categories)
            dispatch({ type: 'getProduct', data: result.productDetails })
            dispatch({ type: 'getAttributes', data: result.productsAttributes })
          })
          .finally(() => setLoading(false))
      }

      if (catalogType === CatalogType.Experience) {
        fetchExperienceAndData()
          .then((result) => {
            if (categoryId && displayOrderWithinCategory) {
              result.productDetails.displayOrder = displayOrderWithinCategory
            }
            setProduct(result.productDetails)
            reset(result.productDetails)
            setCategories(result.categories)
            dispatch({ type: 'getProduct', data: result.productDetails })
            dispatch({ type: 'getAttributes', data: result.productsAttributes })
            dispatch({ type: 'getVenues', venues: result.venues })
          })
          .finally(() => setLoading(false))
      }
    } else {
      if (catalogType === CatalogType.Ecommerce) {
        fetchProductData()
          .then((result) => {
            reset(defaultProduct)
            dispatch({ type: 'getAttributes', data: result.productsAttributes })
            setCategories(result.categories)
          })
          .finally(() => setLoading(false))
      }

      if (catalogType === CatalogType.Experience) {
        fetchExperienceData()
          .then((result) => {
            reset(defaultProduct)
            dispatch({ type: 'getAttributes', data: result.productsAttributes })
            dispatch({ type: 'getVenues', venues: result.venues })
            setCategories(result.categories)
          })
          .finally(() => setLoading(false))
      }
    }
  }, [isAddMode])

  function onSubmit(data: any) {

    const productToSave = {
      ...state.product!
    }

    // Always set the ProductStatus field based on the value of the `Active` field,
    // if this is an experience.
    if (catalogType === CatalogType.Experience) {
      const productStatus = productToSave.active === true ? ProductStatus.Active : ProductStatus.Draft
      productToSave.productStatus = productStatus;
    }

    if (isAddMode && catalogType === CatalogType.Ecommerce) {
      return hourMarketApi.createProduct(productToSave).then((r) => {
        if (r.status === 201) {
          setProductId(r.data.id)
          setIsAddMode(false)
          reset(data)
        }
      })
    } else if (isAddMode && catalogType === CatalogType.Experience) {
      return hourMarketApi.createExperience(productToSave).then((r) => {
        if (r.status === 201) {
          setProductId(r.data.id)
          setIsAddMode(false)
          reset(data)
        }
      })
    } else {
      return hourMarketApi.updateProduct(productToSave).then((r) => {
        reset(data)
        history.goBack()
      })
    }
  }

  const onImageUpload = async (
    uploadedFile: File,
    onUploadProgress?: (e: ProgressEvent) => void
  ): Promise<AxiosResponse<IMediaItemUploadResponse>> => {
    let mediaItemUpload: IEntityMediaItemUpload = {
      MediaType: 1,
      Title: '',
      Description: '',
      ContentType: 'image/png',
      Filename: 'test12345',
      Id: productId ?? '',
      MediaFile: uploadedFile,
    }

    return hourMarketApi.uploadProductMediaItem(mediaItemUpload, onUploadProgress)
  }

  const handleImageDeleted = (mediaItemId: string) => {
    let mediaItemDelete: IEntityMediaItemDelete = {
      ProductId: productId,
      MediaItemId: mediaItemId,
    }

    hourMarketApi.removeProductMediaItem(mediaItemDelete).then((r) => {
      console.log(r)
    })
  }

  const onResortImages = (images: any) => {
    const sortedImages = images.map((image: IMediaItem, index: number) => {
      return {
        MediaItemId: image.id,
        DisplayOrder: index,
      }
    })

    const updateProductSortedImages = {
      ProductId: productId,
      SortedMediaItems: sortedImages,
    }

    hourMarketApi.updateProductSortedImages(updateProductSortedImages).then((r) => console.log(r))
  }

  const yesDeleteAction = async () => {
    setShowDeleteModal(false)
    await deleteProduct(product.id!)
  }

  const deleteProduct = async (id: string) => {
    await hourMarketApi.deleteProduct(id).finally(() => history.goBack())
  }

  const detailsRef = useRef<HTMLInputElement>(null)
  const collectionsRef = useRef<HTMLInputElement>(null)
  const imageGalleryRef = useRef<HTMLInputElement>(null)
  const productOptionsRef = useRef<HTMLInputElement>(null)
  const productVariantsRef = useRef<HTMLInputElement>(null)
  const productTypesRef = useRef<HTMLInputElement>(null)
  const relatedProductsRef = useRef<HTMLInputElement>(null)
  const inventoryRef = useRef<HTMLInputElement>(null)
  const salesRef = useRef<HTMLInputElement>(null)
  const handleSideMenuClick = (e: any, ref: RefObject<HTMLInputElement>) => {
    e.preventDefault()

    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  }

  const onVenueSelected = (venue: IVenue) => dispatch({ type: 'changeVenue', venue })

  const saveInventory = (
    inventoryStrategy: ProductInventoryStrategy,
    productId: string,
    startQuantity: number,
    quantity: number,
    allocatedQuantity: number
  ) => {
    hourMarketApi
      .saveProductInventory({
        inventoryStrategy: inventoryStrategy.id,
        productId,
        startQuantity,
        quantity,
        allocatedQuantity,
      })
      .then((r) => {
        if (r.status === 200) {
          dispatch({ type: 'changeInventoryStrategy', inventoryStrategy })
          dispatch({ type: 'changeInventory', startQuantity, quantity, allocatedQuantity })
        } else {
          alert(`Error saving Inventory !`)
        }
      })
      .catch((error) => alert(`Error saving Inventory !`))
      .finally(() => {
        setOpenManageInventory(false)
      })
  }

  return (
    <>
      <YesNoDialog
        modalIsOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        yesAction={yesDeleteAction}
        noAction={() => setShowDeleteModal(false)}
        title={catalogType === CatalogType.Ecommerce ? 'Delete Product?' : 'Delete Event?'}
        subtitle={
          catalogType === CatalogType.Ecommerce
            ? 'Are you sure you want to delete Product?'
            : 'Are you sure you want to delete Event?'
        }
        description={product?.name ?? ''}
      />
      <GenericDialog
        isOpen={openManageInventory}
        closeModal={() => setOpenManageInventory(false)}
        title="Manage Inventory"
        subTitle="">
        <>
          {state?.product && (
            <ProductInventoryLayout
              inventoryStrategy={
                ProductInventoryStrategies.find((x) => x.id == state?.product?.inventoryStrategy) ??
                ProductInventoryStrategies[0]
              }
              inventoryStartQuantity={state?.product?.inventoryStartQuantity ?? 0}
              inventoryQuantity={state?.product?.inventoryQuantity ?? 0}
              inventoryAllocatedQuantity={state?.product?.inventoryAllocatedQuantity ?? 0}
              onSave={(inventoryStrategy, startQty, onHandQty, allocatedQty) => {
                if (state?.product?.id) {
                  saveInventory(
                    inventoryStrategy,
                    state.product.id,
                    startQty,
                    onHandQty,
                    allocatedQty
                  )
                }
              }}
              onCancel={() => setOpenManageInventory(false)}
            />
          )}
        </>
      </GenericDialog>
      <ProductDispatchContext.Provider value={dispatch}>
        <ProductStateContext.Provider value={state}>
          {/* Product Details */}
          {!showVariantOptions && !showVariants && !showProductTypes && (
            <div className="flex flex-col flex-grow w-full overflow-hidden">
              <div className="flex-1 overflow-y-scroll">
                {loading ? (
                  <LoadingIndicatorPanel
                    message={
                      catalogType === CatalogType.Ecommerce
                        ? 'Loading Product ...'
                        : 'Loading Event ...'
                    }
                  />
                ) : (
                  <div className="mx-auto mt-8 max-w-7xl">
                    {/* Back navigation */}
                    <NavigateBackLayout
                      title={catalogType === CatalogType.Ecommerce ? 'PRODUCTS' : 'EVENTS'}
                      onPressed={history.goBack}
                    />
                    <div className="flex">
                      {/* Side Menu */}
                      {!isAddMode && (
                        <SideMenuContainer
                          categoriesRef={'#collectionsRef'}
                          categoriesText={'Collections'}
                          onCategories={(e) => handleSideMenuClick(e, collectionsRef)}
                          detailsRef={'#details'}
                          detailsText={'Details'}
                          onDetails={(e) => handleSideMenuClick(e, detailsRef)}
                          imagesRef={'#imageGalleryRef'}
                          imagesText={'Images'}
                          onImages={(e) => handleSideMenuClick(e, imageGalleryRef)}
                          productOptionsRef={'#productOptionsRef'}
                          productOptionsText={
                            catalogType === CatalogType.Ecommerce
                              ? 'Product Options'
                              : 'Event Options'
                          }
                          onProductOptions={(e) => handleSideMenuClick(e, productOptionsRef)}
                          productVariantsRef={'#productVariantsRef'}
                          productVariantsText={
                            catalogType === CatalogType.Ecommerce
                              ? 'Product Variants'
                              : 'Event Variants'
                          }
                          onProductVariants={(e) => handleSideMenuClick(e, productVariantsRef)}
                          productTypesRef={'#productTypesRef'}
                          productTypesText={
                            catalogType === CatalogType.Ecommerce ? 'Product Types' : 'Event Types'
                          }
                          onProductTypes={(e) => handleSideMenuClick(e, productTypesRef)}
                          relatedProductsRef={'#relatedProductsRef'}
                          relatedProductsText={
                            catalogType === CatalogType.Ecommerce
                              ? 'Related Products'
                              : 'Related Products/Events'
                          }
                          onRelatedProducts={(e) => handleSideMenuClick(e, relatedProductsRef)}
                          inventoryText={'Inventory'}
                          inventoryRef={'#inventoryRef'}
                          onInventory={(e) => handleSideMenuClick(e, inventoryRef)}
                          salesText={'Sales'}
                          salesRef={'#salesRef'}
                          onSales={(e) => handleSideMenuClick(e, salesRef)}
                        />
                      )}
                      <div className="flex flex-col flex-grow w-full overflow-hidden">
                        {/* Details */}
                        <div ref={detailsRef} className="p-8 rounded-md shadow-sm bg-white">
                          <div className="font-bold text-gray-800/90">DETAILS</div>
                          <Prompt
                            message={(location, action) => {
                              if (action === 'POP') {
                                console.log('Backing up...')
                              }
                              return !formState.isDirty
                                ? true
                                : `You have unsaved changes are sure you want to exit ?`
                            }}
                          />

                          <InputElement
                            label={'Name'}
                            id={'name'}
                            type={'text'}
                            register={register('name')}
                          />
                          <InputHtmlElement
                            label={'Description'}
                            id={'description'}
                            name="description"
                            control={control}
                            register={register('description')}
                          />
                          <InputNumberElement
                            label={'Default Price'}
                            id={'price'}
                            register={register('price')}
                          />
                          {categoryId && (
                            <InputNumberElement
                              label={'Display Order'}
                              id={'displayOrder'}
                              register={register('displayOrder')}
                            />
                          )}
                          {catalogType === CatalogType.Experience && (
                            <>
                              <InputElement
                                label={'Start Date/Time'}
                                id={'activeFrom'}
                                type={'text'}
                                register={register('activeFrom')}
                              />
                              <InputElement
                                label={'Finish Date/Time'}
                                id={'activeTo'}
                                type={'text'}
                                register={register('activeTo')}
                              />
                              <InputElement
                                label={'Location Coordinates'}
                                id={'locationCoordinates'}
                                type={'text'}
                                register={register('locationCoordinates')}
                              />
                              <InputElement
                                label={'Time Description'}
                                id={'activeDescription'}
                                type={'text'}
                                register={register('activeDescription')}
                              />
                              <div className="mt-6">
                                <InputLabelElement label={'Venue'} id={'Venue'} />
                                {state.venues && state.product && (
                                  <VenuePicker
                                    venues={state.venues}
                                    initiaVenue={state.product?.venue}
                                    onVenueSelected={onVenueSelected}
                                  />
                                )}
                              </div>
                              <InputElement
                                label={'Event Code'}
                                id={'productCode'}
                                type={'text'}
                                register={register('productCode')}
                              />
                              <InputCheckBoxElement
                                label={'Does Not Require Booking'}
                                id={'DoesNotRequireBooking'}
                                register={register('doesNotRequireBooking')}
                              />
                            </>
                          )}
                          <InputCheckBoxElement
                            label={'Active'}
                            id={'active'}
                            register={register('active')}
                          />
                          <IsolateReRender control={control} />
                        </div>
                        {/* Collections */}
                        {!categoryId && (
                          <div
                            ref={collectionsRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white">
                            <div className="font-bold text-gray-800/90 mb-4">COLLECTIONS</div>
                            <div className="mt-2 h-max-96 overflow-y-auto px-2">
                              <CategoriesPanel product={product} categories={categories} />
                            </div>
                          </div>
                        )}
                        {/* Image Gallery */}
                        {!isAddMode && (
                          <div
                            ref={imageGalleryRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white">
                            <div className="font-bold text-gray-800/90 mb-4">IMAGES</div>
                            <ImageUpload
                              title={'Drop image or click to upload image'}
                              multiple={true}
                              setref={imageGalleryUploadRef}
                              mediaItems={product?.mediaItems ?? []}
                              onImageUpload={onImageUpload}
                              onImageDeleted={handleImageDeleted}
                              onResortImages={onResortImages}
                            />
                          </div>
                        )}
                        {/* Product Options */}
                        {!isAddMode && (
                          <div
                            ref={productOptionsRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white">
                            <ProductOptionsContainer
                              catalogType={catalogType}
                              title={
                                catalogType === CatalogType.Ecommerce
                                  ? 'PRODUCT OPTIONS'
                                  : 'EVENT OPTIONS'
                              }
                              product={product}
                              showVariantOptions={() => setShowVariantOptions(true)}
                              showVariants={() => setShowVariants(true)}
                            />
                          </div>
                        )}
                        {/* Product variants */}
                        {!isAddMode && (
                          <div
                            ref={productVariantsRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white">
                            <ProductVariantsContainer
                              title={
                                catalogType === CatalogType.Ecommerce
                                  ? 'PRODUCT VARIANTS'
                                  : 'EVENT VARIANTS'
                              }
                              manageVariants={() => setShowVariants(true)}
                            />
                          </div>
                        )}
                        {/* Product Types */}
                        {!isAddMode && (
                          <div
                            ref={productTypesRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white">
                            <ProductTypesContainer
                              catalogType={catalogType}
                              product={product}
                              productTypes={state.product?.productTypes ?? []}
                              manageProductTypes={() => setShowProductTypes(true)}
                            />
                          </div>
                        )}

                        {/* Inventory */}
                        {!isAddMode && (
                          <div
                            ref={inventoryRef}
                            className="mt-8 p-8 rounded-md shadow-sm bg-white mb-10">
                            <ProductInventoryContainer
                              inventoryStrategy={
                                ProductInventoryStrategies.find(
                                  (x) => x.id == state?.product?.inventoryStrategy
                                ) ?? ProductInventoryStrategies[0]
                              }
                              inventoryStartQuantity={state?.product?.inventoryStartQuantity}
                              inventoryQuantity={state?.product?.inventoryQuantity}
                              inventoryAllocatedQuantity={
                                state?.product?.inventoryAllocatedQuantity
                              }
                              manageProductInventory={() => setOpenManageInventory(true)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* Footer */}
              {!loading && (
                <SaveCancelDeleteFooter
                  buttonText={catalogType === CatalogType.Ecommerce ? 'Save Product' : 'Save Event'}
                  isAddMode={isAddMode}
                  disabled={!(formState.isDirty || state.dirty) || formState.isSubmitting}
                  isSubmitting={formState.isSubmitting}
                  onDelete={showDeleteProductModal}
                  onCancel={history.goBack}
                  onSubmit={handleSubmit(onSubmit)}
                />
              )}
            </div>
          )}

          {/* Manage Product Options */}
          {showVariantOptions && (
            <ProductOptionsManage
              catalogType={catalogType}
              title={
                catalogType === CatalogType.Ecommerce
                  ? 'MANAGE PRODUCT OPTIONS'
                  : 'MANAGE EVENT OPTIONS'
              }
              product={product}
              hideVariantOptions={() => setShowVariantOptions(false)}
              formState={formState}
            />
          )}

          {/* Manage Product Variants */}
          {showVariants && state.product && (
            <ProductVariantsManage
              product={product}
              title={
                catalogType === CatalogType.Ecommerce
                  ? 'MANAGE PRODUCT VARIANTS'
                  : 'MANAGE EVENT VARIANTS'
              }
              productVariants={state.product.variants}
              hideVariants={() => setShowVariants(false)}
              formState={formState}
            />
          )}
          {/* Manage Product Types */}
          {showProductTypes && state.product && (
            <ProductTypesManage
              catalogType={catalogType}
              title={
                catalogType === CatalogType.Ecommerce
                  ? 'MANAGE PRODUCT TYPES'
                  : 'MANAGE EVENT TYPES'
              }
              loadingText={
                catalogType === CatalogType.Ecommerce
                  ? 'Loading product types'
                  : 'Loading event types'
              }
              onCancel={() => setShowProductTypes(false)}
              saveText="Save Type"
              preSelectedProductTypes={state.product?.productTypes ?? []}
            />
          )}
        </ProductStateContext.Provider>
      </ProductDispatchContext.Provider>
    </>
  )
}

export default ProductDetails

export { ProductStateContext, ProductDispatchContext }
