// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
import {useEffect, useState} from "react";

const LOCAL_STORAGE_KEYS = {
    SIDE_MENU_KEY: "SIDE_MENU_KEY"
}

function usePersistedState(key, defaultValue) {
    const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(key)) || defaultValue
    )
    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    return [state, setState];
}

function clearPersistedState(key) {
    localStorage.removeItem(key)
}



export { LOCAL_STORAGE_KEYS, usePersistedState, clearPersistedState }



