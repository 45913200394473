import styled from 'styled-components'

const Tag = styled.span`
    background: #D6372C;
    border-radius: 5px;
    font-size: medium;
    color: white;
    padding: 5px;
    margin: 5px 5px 5px 5px;   
    cursor: pointer;
`;

export default Tag;