import React, { FC } from 'react'
import { IProductType, IProductTypeTree } from '../../../models/interface'
import ProductTypeTreeItem from './ProductTypeTreeItem'

interface Props {
  productTypes: IProductTypeTree[]
  onSelected: (id: string | undefined, selected: boolean) => void
  selectedProductTypes?: IProductType[]
}

const ProductTypeNonNestedTree: FC<Props> = ({
  productTypes,
  onSelected,
  selectedProductTypes,
}) => {
  return (
    <div className="flex flex-wrap">
      {productTypes?.map((item) => (
        <ProductTypeTreeItem
          key={item.id}
          productType={item}
          onSelected={onSelected}
          selected={selectedProductTypes?.some((x) => x.id === item.id)}
        />
      ))}
    </div>
  )
}

export default ProductTypeNonNestedTree
