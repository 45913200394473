import React, {useEffect, useState} from 'react';
import PageHeaderComponent from "../../components/pageHeaderComponent";
import {faShoppingBag} from "@fortawesome/pro-solid-svg-icons";
import {hourMarketApi} from "../../Utils/api/hourmarket-api";
import {IShopifyCustomCatalog, IShopifyShopInfo, IShopifySmartCatalog} from "../../models/interface";
import { ShopifyCategoryCard } from "../categories/shopify-category-card.component";
import {Link, useHistory} from "react-router-dom";

export interface ShopifyParamTypes {
    id?: string
}

const ShopifyCollectionsPage = () => {
    const [smartCatalog, setSmartCatalog] = useState<Array<IShopifySmartCatalog>>([]);
    const [customCatalog, setCustomCatalog] = useState<Array<IShopifyCustomCatalog>>([]);
    const [shopInfo, setShopInfo] = useState<IShopifyShopInfo>();
    const [authorised, setAuthorised] = useState(false);

    const { push } = useHistory();

    useEffect(() => {
        getCachedData();
    }, [push]);

    function refreshFromShopify() {
        getShopInfoData();
        getCustomCatalogData();
        getSmartCatalogData();
    }

    async function getCachedData() {
        hourMarketApi.getShopifyCachedCustomCatalog().then((r) => {
            setCustomCatalog(r);
        });

        hourMarketApi.getShopifyCachedSmartCatalog().then((r) => {
            setSmartCatalog(r);
        });
    }

    function getShopInfoData() {
        hourMarketApi.getShopifyShopInfo().then((response) => {
            setShopInfo(response.data);
        });
    }

    function getCustomCatalogData() {
        hourMarketApi.getShopifyCustomCatalog().then((response) => {
            setCustomCatalog(response.data);
            if (response.data)
                response.data.map((item) => {
                });
        });
    }

    function getSmartCatalogData() {
        hourMarketApi.getShopifySmartCatalog().then((response) => {
            setSmartCatalog(response.data);
            if (response.data)
                response.data.map((item) => {
                });
        });
    }

    return (
        <>
            <div className="m-10 ml-16 max-w-7xl p-4 rounded-md">
                <PageHeaderComponent title="Index" icon={faShoppingBag} />
                <button onClick={() => refreshFromShopify()} className="mt-3 mb-3 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                    Refresh From Index
                </button>
                <ShopifyCollection customCatalog={customCatalog} smartCatalog={smartCatalog} />
            </div>
        </>
    )
};

export default ShopifyCollectionsPage;

interface ShopifyCollectionCatalogProps {
    smartCatalog: Array<IShopifySmartCatalog>
    customCatalog: Array<IShopifyCustomCatalog>
}

interface SmartCollectionCatalogProps {
    items: Array<IShopifySmartCatalog>
}

interface CustomCollectionCatalogProps {
    items: Array<IShopifyCustomCatalog>
}

export const ShopifyCollection: React.FC<ShopifyCollectionCatalogProps> = ({ smartCatalog, customCatalog }) => {
    return (
        <>
            {smartCatalog?.length > 0 &&
            (<div className="grid grid-cols-4 gap-y-16 mt-5 py-4 space-y-2 sm:px-6 lg:px-0 lg:col-span-9 shadow-lg rounded-lg  sm:rounded-md bg-white">
                {smartCatalog.map((catalogItem) => (
                    <Link to={{pathname: `/shopifyproducts/`, state: { products: catalogItem.products}}}>
                        <ShopifyCategoryCard key={catalogItem.smartCollection.id} image={catalogItem.smartCollection.image?.src} title={catalogItem.smartCollection.title} displayOrder={0} />
                    </Link>
                ))}
            </div>)}

            {customCatalog?.length > 0 &&
            (<div className="grid grid-cols-4 gap-y-16 mt-5 py-4 space-y-2 sm:px-6 lg:px-0 lg:col-span-9 shadow-lg rounded-lg  sm:rounded-md bg-white">
                {customCatalog.map((catalogItem) => (
                    <Link to={{pathname: `/shopifyproducts/`, state: { products: catalogItem.products }}}>
                    <ShopifyCategoryCard key={catalogItem.customCollection.id} image={catalogItem.customCollection.image?.src} title={catalogItem.customCollection.title} displayOrder={0} />
                    </Link>
                ))}
            </div>)}
        </>
    )
}
