import styled from 'styled-components'

const Input = styled.input`
  background: #eeeeee;
  border: none;
  margin-top: 20px;
  border-radius: 3px;
  outline: none;
  font-size: medium;
  display: inline-block;
  width: 100%;
  color: #69626D;
  font-weight: 400;
  focus {
    outline: none;
  }  
  &::-webkit-input-placeholder {
    font-weight: 100;
    font-style: italic;
    color: #69626D;
  }
`;

export default Input;