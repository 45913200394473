//https://uk.typology.com/collections/exfoliation-peeling

import React, { FC, useEffect, useState } from 'react'
import { ICategory, ICategoryProducts, IProduct } from '../../../models/interface'
import { LoadingIndicatorPanel } from '../../../components/LoadingIndicatorPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { hourMarketApi } from '../../../Utils/api/hourmarket-api'
import Select from 'react-select'

type TemplateOption<T> = { label: string; value: T }

const CategoryPreview = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const [categoryProduct, setCategoryProduct] = useState<ICategoryProducts | undefined>(undefined)
  const history = useHistory()
  const [productTemplateType, setProductTemplateType] = useState<ProductTemplateType>('ImageTextBelow')
  const [categoryTemplateType, setCategoryTemplateType] = useState<CategoryTemplateType>('JustText')

  const categoryTemplateTypes: Array<TemplateOption<CategoryTemplateType>> = [
    { label: 'Text Only', value: 'JustText' },
    { label: 'Text with Background Image', value: 'BackgroundImage' },
  ]

  const productTemplateTypes: Array<TemplateOption<ProductTemplateType>> = [
    { label: 'Image - Text Below', value: 'ImageTextBelow' },
    { label: 'Image - Text InLine', value: 'ImageTextInline' },
    { label: 'Text Only', value: 'TextOnly' },
  ]

  useEffect(() => {
    console.log('id', id)
    setLoading(true)
    hourMarketApi
      .getCategoryProducts(id)
      .then((r) => {
        console.log(r.data)
        setCategoryProduct(r.data)
      })
      .finally(() => setLoading(false))
  }, [id])

  const onChangeCategoryTemplate = (selected?: TemplateOption<CategoryTemplateType> | TemplateOption<CategoryTemplateType>[] | null) => {
    if (selected !== null && !Array.isArray(selected) && selected?.value !== undefined) {
      setCategoryTemplateType(selected?.value)
    }
  }

  const onChangeProductTemplate = (selected?: TemplateOption<ProductTemplateType> | TemplateOption<ProductTemplateType>[] | null) => {
    if (selected !== null && !Array.isArray(selected) && selected?.value !== undefined) {
      setProductTemplateType(selected?.value)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingIndicatorPanel message={'Loading category ...'} />
      ) : (
        <div className="w-full">
          <div className="mx-auto h-full mt-2 max-w-7xl  p-4 rounded-md w-full overflow-hidden overflow-y-auto">
            <button onClick={history.goBack} className="mb-4 flex items-center focus:outline-none">
              <FontAwesomeIcon icon={faArrowLeft} size="1x" className="text-gray-500 cursor-pointer" />
              <span className="ml-2 text-gray-500">Back</span>
            </button>
            <div className="font-medium text-gray-400 text-sm my-2">Category Preview</div>
            <div className="flex space-x-8">
              <div className="flex flex-col">
                <div className="text-gray-800 text-sm">Category Header Template</div>
                <Select
                  value={categoryTemplateTypes.find((x) => x.value === categoryTemplateType)}
                  className="w-72 mb-4 z-40"
                  options={categoryTemplateTypes}
                  onChange={(template) => onChangeCategoryTemplate(template)}
                />
              </div>
              <div className="flex flex-col">
                <div className="text-gray-800 text-sm">Product Template</div>
                <Select
                  value={productTemplateTypes.find((x) => x.value === productTemplateType)}
                  className="w-56 mb-4 z-40"
                  options={productTemplateTypes}
                  onChange={(template) => onChangeProductTemplate(template)}
                />
              </div>
            </div>
            <div className="bg-white mb-8 pb-10">
              <CategoryHeaderTemplateFactory templateType={categoryTemplateType} category={categoryProduct?.category!} />
              <div className="p-4 mx-6 mt-10">
                <CategoryProductsTemplateFactory templateType={productTemplateType} categoryProduct={categoryProduct!} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CategoryPreview

type ProductTemplateType = 'ImageTextBelow' | 'ImageTextInline' | 'TextOnly'

type CategoryProductsPreviewPanelProps = {
  templateType: ProductTemplateType
  categoryProduct: ICategoryProducts
}

const ProductPreviewBannerBelowPanel: FC<CategoryProductsPreviewPanelProps> = ({ templateType, categoryProduct }) => {
  return (
    <div className="grid grid-cols-5 gap-y-10 gap-x-10">
      {categoryProduct?.products
        ?.sort(function (a, b) {
          return a.displayOrder !== undefined && b.displayOrder !== undefined && a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((product) => {
          return templateType === 'ImageTextBelow' ? <ProductPreviewBannerBelowCard product={product} /> : <ProductPreviewBannerInlineCard product={product} />
        })}
    </div>
  )
}

type ProductPreviewCardProps = {
  product: IProduct
}

const ProductPreviewBannerBelowCard: FC<ProductPreviewCardProps> = ({ product }) => {
  return (
    <div className="border border-gray-100 shadow-sm rounded-md overflow-hidden">
      <img src={product.image} className="h-48 w-full border-b border-gray-200 object-cover object-center" alt="product" />
      <div className="text-gray-800 px-2 py-2 text-sm font-medium">{product.name}</div>
    </div>
  )
}

const ProductPreviewBannerInlineCard: FC<ProductPreviewCardProps> = ({ product }) => {
  return (
    <div className="relative border border-gray-100 shadow-sm rounded-sm overflow-hidden h-48 w-48">
      <div className="absolute hover:bg-gray-700/30 w-full h-full z-20 " />
      <img src={product.image} className="absolute object-cover w-full h-48 z-10" alt="product" />
      <div className="absolute left-1/2 z-30 bottom-0 -translate-x-1/2 w-full py-1 h-12 px-4 bg-gray-900/50 filter backdrop-blur-sm">
        <div className="text-gray-100  text-sm font-medium w-full text-center">{product.name}</div>
      </div>
    </div>
  )
}

const ProductPreviewNoImagePanel: FC<CategoryProductsPreviewPanelProps> = ({ templateType, categoryProduct }) => {
  return (
    <div className="flex flex-col space-y-2">
      {categoryProduct?.products
        ?.sort(function (a, b) {
          return a.displayOrder !== undefined && b.displayOrder !== undefined && a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((product) => {
          return <ProductPreviewNoImageList product={product} />
        })}
    </div>
  )
}

const ProductPreviewNoImageList: FC<ProductPreviewCardProps> = ({ product }) => {
  return (
    <div className="border">
      <div className="text-gray-800 px-2 py-2 text-sm font-normal">{product.name}</div>
    </div>
  )
}

const CategoryProductsTemplateFactory: FC<CategoryProductsPreviewPanelProps> = ({ templateType, categoryProduct }) => {
  switch (templateType) {
    case 'ImageTextBelow':
      return <ProductPreviewBannerBelowPanel categoryProduct={categoryProduct} templateType={templateType} />
    case 'ImageTextInline':
      return <ProductPreviewBannerBelowPanel categoryProduct={categoryProduct} templateType={templateType} />
    default:
      return <ProductPreviewNoImagePanel categoryProduct={categoryProduct} templateType={templateType} />
  }
}

type CategoryTemplateType = 'JustText' | 'BackgroundImage'

type CategoryHeaderTemplateProps = {
  templateType: CategoryTemplateType
  category: ICategory
}

const CategoryHeaderTemplateFactory: FC<CategoryHeaderTemplateProps> = ({ templateType, category }) => {
  switch (templateType) {
    case 'BackgroundImage':
      return <CategoryTemplateWithBackgroundImage category={category} />
    default:
      return <div className="uppercase text-gray-800 font-bold text-3xl px-10 pt-14 tracking-wider">{category?.title}</div>
  }
}

type CategoryTemplateWithBackgroundImageProps = {
  category: ICategory
}

const CategoryTemplateWithBackgroundImage = ({ category }: CategoryTemplateWithBackgroundImageProps) => {
  return (
    <div className="relative flex flex-col justify-center w-full h-96 shadow-md">
      <img src={category?.image} className="absolute inset-0 object-cover w-full h-full z-10" />
      <div className="container z-20">
        <h1 className="my-8 mx-10 md:my-5 font-medium leading-4 uppercase text-5xl">{category?.title}</h1>
      </div>
    </div>
  )
}
