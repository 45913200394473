import React, {FC} from 'react';
import LoadingIndicator from "./LoadingIndicator";

type LoadingIndicatorPanelProps = {
    message: string
}

const LoadingIndicatorPanel: FC<LoadingIndicatorPanelProps> = ({message}) => {
    return (
        <div className="mx-auto mt-8 w-96 h-36 flex flex-col justify-center items-center bg-white rounded-md p-8">
            <LoadingIndicator size={"small"} fill={true} />
            <div className="text-pixie text-sm font-medium">
                {message}
            </div>
        </div>
        )
};

export {
    LoadingIndicatorPanel
}
