import { IDropDownOptionItem } from '../../../models/interface'

const CollectionLeadTimeData: IDropDownOptionItem[] = [
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 60, label: '1 hr' },
  { value: 120, label: '2 hrs' },
  { value: 180, label: '3 hrs' },
  { value: 240, label: '4 hrs' },
  { value: 300, label: '5 hrs' },
  { value: 360, label: '6 hrs' },
  { value: 420, label: '7 hrs' },
  { value: 480, label: '8 hrs' },
  { value: 540, label: '9 hrs' },
  { value: 600, label: '10 hrs' },
  { value: 660, label: '11 hrs' },
  { value: 720, label: '12 hrs' },
  { value: 1440, label: '24 hrs' },
  { value: 2880, label: '48 hrs' },
]

export { CollectionLeadTimeData }
