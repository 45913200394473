import React, {FC} from 'react';
import {IExperience} from "../../models/interface";

interface ExperienceAttendeesProps {
    experience: IExperience
}

const ExperienceAttendees: FC<ExperienceAttendeesProps> = ({experience}) => {
    return (
        <div>
            <div className="font-bold text-xl my-2 text-gray-900">Event Attendees</div>

        </div>
    )
}

export default ExperienceAttendees;
