import { CatalogType, IProductTypeTree } from '../../../models/interface'
import React, { FC } from 'react'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabelSmall } from '@pixieme/pixie-react-components'
import { useAppSelector } from '../../../redux/Hooks'
import { useDispatch } from 'react-redux'
import { useProductTypesTree } from '../../../hooks'
import ProductTypesFilterItem from './ProductTypesFilterItem'

type CategoriesSelectionProps = {
  title: string
  catalogType: CatalogType
}

export const ProductTypesFilterContainer: FC<CategoriesSelectionProps> = ({
  title,
  catalogType = CatalogType.Ecommerce,
}) => {
  const { data: productTypes, loading } = useProductTypesTree(catalogType)
  const { filteredProductTypes } = useAppSelector(({ appReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  const onSelected = (isSelected: boolean, categoryId: string) => {
    let updatedCategories: string[]

    if (isSelected) {
      updatedCategories = filteredProductTypes.concat(categoryId)
    } else {
      updatedCategories = filteredProductTypes.filter((item) => item !== categoryId)
    }

    dispatch({ type: 'filterProductByCategoriesChanged', filteredCategories: updatedCategories })
  }

  if (loading) {
    return (
      <div className="flex mt-2 items-center">
        <FontAwesomeIcon icon={faSpinner} color="dark" spin={true} className="mr-2" />
        <LabelSmall variant="dark" label="loading" />
      </div>
    )
  }

  return (
    <>
      {productTypes && productTypes.length > 0 && (
        <div className="text-base font-medium mt-6">{title}</div>
      )}
      {productTypes
        ?.sort(function (a, b) {
          return a.displayOrder !== undefined &&
            b.displayOrder !== undefined &&
            a.displayOrder > b.displayOrder
            ? 1
            : -1
        })
        .map((productType: IProductTypeTree) => {
          return (
            <ProductTypesFilterItem
              key={productType.id}
              productType={productType}
              productTypeSelected={onSelected}
              initialSelectedProductTypes={filteredProductTypes}
            />
          )
        })}
    </>
  )
}
