import React, {FC, MouseEventHandler} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-duotone-svg-icons";

type SaveButtonProps = {
    disabled: boolean,
    isSubmitting: boolean,
    onSubmit: MouseEventHandler,
    buttonText?: string
}

const SaveButton: FC<SaveButtonProps> = ({disabled, isSubmitting, onSubmit, buttonText = "Save"}) => {
    const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ")

    return (
        <button
            disabled={disabled}
            onClick={onSubmit}
            className={classNames(
                disabled
                    ? 'bg-pixie bg-opacity-30'
                    : 'bg-pixie hover:bg-pixie hover:bg-opacity-50',
                "h-8 w-36 mt-5 border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white/90 focus:outline-none"
            )} >
            {
                isSubmitting
                    ? <><span className="mr-2">Saving</span><FontAwesomeIcon icon={faSpinner} color="white" size="1x" spin={true} className="my-auto"  /></>
                    : buttonText
            }
        </button>
    )
};

export default SaveButton;
