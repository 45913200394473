import React from 'react'
import ReactDOM from 'react-dom'
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, AccountInfo } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import './assets/style.css'
import './assets/tailwind.css'
import App from './App'
import { msalConfig } from './auth/authConfig'
import store from './redux/store'

const msalInstance = new PublicClientApplication(msalConfig)
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}

export let authToken: string = ''

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult
    const { account, accessToken } = payload
    authToken = accessToken
    msalInstance.setActiveAccount(account)
  }

  if (event.eventType === EventType.ACCOUNT_ADDED && event.payload) {
    const account = event.payload as AccountInfo
    msalInstance.setActiveAccount(account)
  }
})

const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API

  if (!activeAccount && accounts.length === 0) {
    return
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    scopes: process.env.REACT_APP_ADB2C_LOGIN_SCOPES?.split(',') ?? [],
    account: activeAccount || accounts[0],
  }

  const authResult = await msalInstance.acquireTokenSilent(request)

  return authResult.accessToken
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

export { acquireAccessToken }
