import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faBicycle, faPersonCarry, faQuestion, faTruck} from "@fortawesome/pro-light-svg-icons";
import {IOrder} from "../../models/interface";

const deliveryMethodIcon = (order: IOrder) : IconProp => {
    switch (order.shippingMethod) {
        case "Collection":
            return faPersonCarry
        case "LocalDelivery":
            return faBicycle
        case "NationalDelivery":
            return faTruck
    }
    return faQuestion
}

const deliveryMethodText = (order: IOrder) : string => {
    switch (order.shippingMethod) {
        case "Collection":
            return "Collection"
        case "LocalDelivery":
            return "Local Delivery"
        case "NationalDelivery":
            return "National Delivery"
    }
    return "Not specified"
}

const deliveryStatusText = (order: IOrder) : string => {
    switch (order.shippingMethod) {
        case "Collection":
            return "Not Collected"
        case "LocalDelivery":
            return "Not Shipped"
        case "NationalDelivery":
            return "Not Shipped"
    }
    return "Not specified"
}

const deliveryMethodCompleteText = (order: IOrder) : string => {
    switch (order.shippingMethod) {
        case "Collection":
            return "Mark as Collected"
        case "LocalDelivery":
            return "Mark as Shipped"
        case "NationalDelivery":
            return "Mark as Shipped"
    }
    return "Not specified"
}

export {
    deliveryMethodIcon,
    deliveryMethodText,
    deliveryStatusText,
    deliveryMethodCompleteText
}
