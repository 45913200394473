//https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
const LocalStorageService = (function() {
    var _service;

    function _getService() {
        if(!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(tokenObj) {
        localStorage.setItem("access_token", tokenObj.access_token);
        localStorage.setItem("refresh_token", tokenObj.refresh_token);
    }

    function _getAccessToken() {
        return localStorage.getItem("access_token");
    }

    function _getRefreshToken() {
        return localStorage.getItem("refresh_token");
    }
    function _clearToken() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    }

    function _setMenu(menuObj) {
        localStorage.setItem("pixie_menu", menuObj);
    }

    function _getMenu() {
        return localStorage.getItem("pixie_menu");
    }

    return {
        getService : _getService,
        setToken : _setToken,
        getAccessToken : _getAccessToken,
        getRefreshToken : _getRefreshToken,
        clearToken : _clearToken,
        setMenu : _setMenu,
        getMenu : _getMenu
    }
})();

export default LocalStorageService;
