import React, { useEffect, useState } from 'react'
import { SlotsDisplay } from '../slotsDisplay'
import { hourMarketApi } from '../../../Utils/api/hourmarket-api'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { defaultDeliverySettings, IDeliverySettings } from '../../../models/interface'
import { useForm } from 'react-hook-form'
import SaveButton from '../../../components/SaveButton'
import { InputCheckBoxElement, InputSelectElement, InputTextElement } from '../../../components/form-elements/input-element'
import { CollectionLeadTimeData } from './CollectionLeadTimeData'
import CancelButton from '../../../components/CancelButton'
import LogisticsSlotsComponent from '../../../components/logistics/UI/LogisticsSlotsComponent'

const ClickAndCollect = () => {
  const [loading, setLoading] = useState(true)
  const { register, handleSubmit, reset, formState } = useForm<IDeliverySettings>({ defaultValues: defaultDeliverySettings })

  useEffect(() => {
    getDeliverySettings()
  }, [])

  const getDeliverySettings = () => {
    setLoading(true)
    hourMarketApi
      .getDeliverySettings()
      .then((r) => {
        if (r.status === 200) {
          reset(r.data)
        }
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = (data: any) => {
    return hourMarketApi.saveDeliverySettings(data).then(() => reset(data))
  }

  const disabled = !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting
  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md">
          <h1 className="text-xl font-bold text-gray-900 mb-6">Click and Collect</h1>
          <div className="p-4 bg-white rounded-md">
            {loading ? (
              <LoadingIndicator size={'small'} fill={true} />
            ) : (
              <>
                <div>
                  <h1 className="text-xl font-bold text-gray-900 mb-6">Settings</h1>
                  <InputCheckBoxElement label={'We offer collections'} id={'offerCollection'} register={register('offerCollection')} />
                  <InputTextElement label="Collection Location" id={'collectionLocation'} register={register('collectionLocation')} rows={3} />
                  <InputCheckBoxElement label={'We offer collection times'} id={'offerCollectionSlots'} register={register('offerCollectionSlots')} />
                  <InputSelectElement
                    label={'Collection lead time'}
                    id={'collectionLeadTimeInMins'}
                    name={'collectionLeadTimeInMins'}
                    register={register('collectionLeadTimeInMins')}
                    optionData={CollectionLeadTimeData}
                  />
                  <InputTextElement label="Collection Notes" id={'collectionNotes'} register={register('collectionNotes')} rows={3} />
                </div>
              </>
            )}
          </div>
          <div className="p-4 bg-white rounded-md mt-10">
            <h1 className="text-xl font-bold text-gray-900 mb-2">Collection Slots</h1>
            <LogisticsSlotsComponent ScheduleType="Collection" />
          </div>
          <div className="p-4 bg-white rounded-md mt-10">
            <h1 className="text-xl font-bold text-gray-900 mb-2">Collection Slots Template</h1>
            <LogisticsSlotsComponent ScheduleType="CollectionTemplate" />
          </div>
        </div>
      </div>
      {/* Footer */}
      {!loading && (
        <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
          <div className="flex space-x-4 justify-end">
            <CancelButton disabled={false} isCancelling={false} onCancel={() => console.log('cancel')} />
            <SaveButton disabled={disabled} isSubmitting={formState.isSubmitting} onSubmit={handleSubmit(onSubmit)} />
          </div>
        </div>
      )}
    </div>
  )
}

export default ClickAndCollect

const ClickAndCollectSettings = () => {
  const [loading, setLoading] = useState(true)
  const { register, handleSubmit, reset, formState } = useForm<IDeliverySettings>({ defaultValues: defaultDeliverySettings })

  useEffect(() => {
    getDeliverySettings()
  }, [])

  const getDeliverySettings = () => {
    setLoading(true)
    hourMarketApi
      .getDeliverySettings()
      .then((r) => {
        if (r.status === 200) {
          reset(r.data)
        }
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = (data: any) => {
    return hourMarketApi.saveDeliverySettings(data).then(() => reset(data))
  }

  const disabled = !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  return loading ? (
    <LoadingIndicator size={'small'} fill={true} />
  ) : (
    <>
      <div>
        <InputCheckBoxElement label={'We offer collections'} id={'offerCollection'} register={register('offerCollection')} />
        <InputTextElement label="Collection Location" id={'collectionLocation'} register={register('collectionLocation')} rows={3} />
        <InputCheckBoxElement label={'We offer collection times'} id={'offerCollectionSlots'} register={register('offerCollectionSlots')} />
        <InputSelectElement
          label={'Collection lead time'}
          id={'collectionLeadTimeInMins'}
          name={'collectionLeadTimeInMins'}
          register={register('collectionLeadTimeInMins')}
          optionData={CollectionLeadTimeData}
        />
        <InputTextElement label="Collection Notes" id={'collectionNotes'} register={register('collectionNotes')} rows={3} />
        <SaveButton disabled={disabled} isSubmitting={formState.isSubmitting} onSubmit={handleSubmit(onSubmit)} />
      </div>
    </>
  )
}
