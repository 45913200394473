import React, { FC, useEffect, useState } from 'react'
import { YesNoDialog } from '../../components/dialogs'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import {
  Input,
  InputElement,
  InputLabelElement,
} from '../../components/form-elements/input-element'
import FooterSaveCancelDeleteLayout from '../../layouts/FooterSaveCancelDeleteLayout'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { useHistory, useLocation } from 'react-router-dom'
import { defaultVenue, IVenue } from '../../models/interface'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'

interface VenueTypeState {
  add: boolean
}

const VenueScreen: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [venue, setVenue] = useState<IVenue>(defaultVenue)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation<VenueTypeState>()
  const { add } = location.state

  const { register, handleSubmit, reset, formState } = useForm<IVenue>({
    defaultValues: defaultVenue,
  })

  const disabled =
    !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  useEffect(() => {
    if (!add) {
      setLoading(true)
      hourMarketApi
        .getVenue(id)
        .then((r) => {
          setVenue(r.data)
          reset(r.data)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [add, id, reset])

  const yesDeleteAction = async () => {
    setShowDeleteModal(false)
    await deleteVenue(venue.id!)
  }

  const deleteVenue = async (id: string) =>
    await hourMarketApi.deleteVenue(id).finally(() => history.goBack())

  const showDeleteVenueModal = () => setShowDeleteModal(true)

  const onSubmit = (data: any) => {
    if (add) {
      return hourMarketApi.createVenue(data).then((r) => {
        history.goBack()
      })
    } else {
      return hourMarketApi.updateVenue(data).then((r) => {
        history.goBack()
      })
    }
  }

  return (
    <>
      <YesNoDialog
        modalIsOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        yesAction={yesDeleteAction}
        noAction={() => setShowDeleteModal(false)}
        title="Delete Venue "
        subtitle="Are you sure you want to delete venue"
        description={venue?.name ?? ''}
      />
      <div className="flex flex-col flex-grow w-full overflow-hidden">
        <div className="flex-1 overflow-y-scroll">
          {loading ? (
            <LoadingIndicatorPanel message="Loading product attribute ..." />
          ) : (
            <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow overflow-hidden mb-12">
              <div className="">
                <button
                  onClick={history.goBack}
                  className="mb-4 flex items-center focus:outline-none">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size="1x"
                    className="text-gray-500 cursor-pointer"
                  />
                  <span className="ml-2 text-gray-500">Venues</span>
                </button>
                <div className="max-w-7xl px-4 px-8 pt-4 pb-12 rounded-md border bg-white">
                  <InputElement
                    label={'Name'}
                    id={'title'}
                    type={'text'}
                    register={register('name')}
                    inputWidthCss="w-96"
                  />
                  <InputElement
                    label={'Description'}
                    id={'description'}
                    type={'text'}
                    register={register('description')}
                    inputWidthCss="w-96"
                  />
                  <InputElement
                    label={'Address1'}
                    id={'address1'}
                    type={'text'}
                    register={register('address1')}
                    inputWidthCss="w-96"
                  />
                  <InputElement
                    label={'Address2'}
                    id={'address2'}
                    type={'text'}
                    register={register('address2')}
                    inputWidthCss="w-96"
                  />
                  <InputElement
                    label={'City'}
                    id={'city'}
                    type={'text'}
                    register={register('city')}
                    inputWidthCss="w-52"
                  />
                  <InputElement
                    label={'County'}
                    id={'county'}
                    type={'text'}
                    register={register('county')}
                    inputWidthCss="w-52"
                  />
                  <InputElement
                    label={'Postcode'}
                    id={'postcode'}
                    type={'text'}
                    register={register('postcode')}
                    inputWidthCss="w-30"
                  />

                  <div className="mt-6">
                    <InputLabelElement label={'Location (Latitude/Longitude)'} id={id} />
                    <div className="flex">
                      <Input
                        type={'number'}
                        id={'latitude'}
                        placeHolder={'Latitude'}
                        register={register('latitude')}
                        additionalCss="mr-4 w-32"
                      />
                      <Input
                        type={'number'}
                        id={'longitude'}
                        placeHolder={'Longitude'}
                        register={register('longitude')}
                        additionalCss="w-32"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Footer */}
        {!loading && (
          <FooterSaveCancelDeleteLayout
            onCancel={history.goBack}
            onDelete={showDeleteVenueModal}
            disabled={disabled}
            isSubmitting={formState.isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            showDeleteButton={!add}
          />
        )}
      </div>
    </>
  )
}

export default VenueScreen
