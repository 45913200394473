import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { CatalogType, defaultProductAttribute, IProductAttribute } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { YesNoDialog } from '../../components/dialogs'
import { InputElement } from '../../components/form-elements/input-element'
import NavigateBackLayout from '../../layouts/navigateBackLayout'
import FooterSaveCancelDeleteLayout from '../../layouts/FooterSaveCancelDeleteLayout'

interface ProductAttributesState {
  catalogType: CatalogType
}

const ProductAttributesDetailsPage = () => {
  const [productAttribute, setProductAttribute] =
    useState<IProductAttribute>(defaultProductAttribute)
  const { id } = useParams<{ id: string }>()
  const add = !id
  const [loading, setLoading] = useState<boolean>(true)
  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const location = useLocation<ProductAttributesState>()
  const { catalogType } = location.state

  useEffect(() => {
    if (!add) {
      setLoading(true)
      hourMarketApi
        .getProductAttribute(id)
        .then((r) => {
          setProductAttribute(r.data)
          reset(r.data)
        })
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [id])

  const onSubmit = (data: any) => {
    if (add) {
      if (catalogType === CatalogType.Ecommerce) {
        return hourMarketApi.createProductAttribute(data).then((r) => {
          console.log(r)
          history.goBack()
        })
      }
      if (catalogType === CatalogType.Experience) {
        return hourMarketApi.createExperienceProductAttribute(data).then((r) => {
          console.log(r)
          history.goBack()
        })
      }
    } else {
      return hourMarketApi.updateProductAttribute(data).then((r) => {
        console.log(r)
        history.goBack()
      })
    }
  }

  const showDeleteProductAttributeModal = () => setShowDeleteModal(true)

  const yesDeleteAction = async () => {
    setShowDeleteModal(false)
    await deleteProduct(productAttribute.id!)
  }

  const deleteProduct = async (id: string) => {
    await hourMarketApi.deleteProductAttribute(id).finally(() => history.goBack())
  }

  const { register, handleSubmit, reset, formState } = useForm<IProductAttribute>({
    defaultValues: defaultProductAttribute,
  })
  const disabled =
    !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  return (
    <>
      <YesNoDialog
        modalIsOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        yesAction={yesDeleteAction}
        noAction={() => setShowDeleteModal(false)}
        title="Delete Product Attribute"
        subtitle="Are you sure you want to delete product attribute"
        description={productAttribute?.name ?? ''}
      />
      <div className="flex flex-col flex-grow w-full overflow-hidden">
        <div className="flex-1 overflow-y-scroll">
          {loading ? (
            <LoadingIndicatorPanel message="Loading product attribute ..." />
          ) : (
            <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow overflow-hidden">
              <div className="">
                <NavigateBackLayout title={'PRODUCT OPTIONS'} onPressed={history.goBack} />
                <div className="max-w-7xl px-4 px-8 pt-4 pb-12 rounded-md border bg-white">
                  <InputElement
                    label={'Name'}
                    id={'name'}
                    type={'text'}
                    register={register('name')}
                    inputWidthCss="w-96"
                  />
                  <InputElement
                    label={'Description'}
                    id={'description'}
                    type={'text'}
                    register={register('description')}
                    inputWidthCss="w-96"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Footer */}
        {!loading && (
          <FooterSaveCancelDeleteLayout
            onCancel={history.goBack}
            onDelete={showDeleteProductAttributeModal}
            disabled={disabled}
            isSubmitting={formState.isSubmitting}
            onSubmit={handleSubmit(onSubmit)}
            showDeleteButton={!add}
          />
        )}
      </div>
    </>
  )
}

export default ProductAttributesDetailsPage
