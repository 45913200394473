import React from "react";

export function useFormFields<T>(initialValues: T) {
    const [formFields, setFormFields] = React.useState<T>(initialValues);
    const createChangeHandler = (key: keyof T) => (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        //const value = e.target.value;
        const value = e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
        setFormFields((prev: T) => ({ ...prev, [key]: value }));
    };
    return { formFields, setFormFields, createChangeHandler };
}

export function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}
