import React, { FC, useContext, useRef, useState } from 'react'
import { IProductAttribute, IProductAttributeMapping } from '../../../models/interface'
import { ProductOptionLinesPanel } from './productOptionLinesPanel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/pro-light-svg-icons'
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'

interface ProductOptionPanelProps {
  id: any
  index: number
  productAttributeMapping: IProductAttributeMapping
  moveProductOption: (dragIndex: number, hoverIndex: number) => void
  attributes: IProductAttribute[]
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

export const ProductOptionPanel: FC<ProductOptionPanelProps> = ({
  id,
  index,
  productAttributeMapping,
  moveProductOption,
  attributes,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [attribute, setAttribute] = useState('')
  const productOptionsDispatch = useContext(ProductDispatchContext)
  const productOptionsState = useContext(ProductStateContext)

  const handleAttributeSelectedChange = (e: React.BaseSyntheticEvent) => {
    productOptionsDispatch({
      type: 'updateAttribute',
      productAttributeMapping: productAttributeMapping,
      productAttributeId: e.target.value,
    })
    setAttribute(e.target.value)
  }

  const handleAttributeDisplayTypeChange = (e: React.BaseSyntheticEvent) => {
    productOptionsDispatch({
      type: 'updateAttributeDisplayType',
      productAttributeMapping: { ...productAttributeMapping, displayType: e.target.value },
      productAttributeId: e.target.value,
    })
    console.log('productAttributeMapping', productAttributeMapping, e.target.value)

    //setAttribute(e.target.value);
  }

  const onSubmit = (e: React.SyntheticEvent) => {}

  const onDeleteAttribute = (e: React.SyntheticEvent) => {
    e.preventDefault()
    productOptionsDispatch({
      type: 'deleteAttribute',
      productAttribute: productAttributeMapping.attribute!,
    })
  }

  interface DragItem {
    index: number
    id: string
    type: string
  }

  const [{ handlerId }, drop] = useDrop({
    accept: VariantOptionPanelTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveProductOption(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: VariantOptionPanelTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <form onSubmit={onSubmit}>
        <div className="flex space-x-16 my-8">
          {/*{productAttributeMapping.displayOrder}*/}
          <button className="cursor-move focus:outline-none mr-4">
            <FontAwesomeIcon icon={faGripVertical} size="1x" />
          </button>

          <div className="flex flex-col">
            <label className="text-sm mb-2">Name</label>
            <select
              className="block text-sm border-gray-300"
              value={productAttributeMapping.attribute?.id}
              onChange={handleAttributeSelectedChange}>
              {attributes &&
                attributes?.length > 0 &&
                attributes.map((attribute) => {
                  return (
                    <option key={attribute.id} value={attribute.id} className="text-sm">
                      {attribute?.name}
                    </option>
                  )
                })}
            </select>
            <button
              onClick={onDeleteAttribute}
              className="mt-5 text-left underline text-red-600 font-medium text-xs focus:outline-none">
              Delete Option
            </button>
          </div>

          <div className="flex flex-col">
            <label className="text-sm mb-2">Type</label>
            <select
              value={productAttributeMapping.displayType ?? 0}
              className="block text-sm border-gray-300"
              onChange={handleAttributeDisplayTypeChange}>
              <option value="Dropdown">Dropdown</option>
              <option value="Swatch">Swatch</option>
              <option value="RadioButtons">Radio Buttons</option>
              <option value="RectangleList">Rectangle List</option>
              <option value="ImageList">Image List</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-sm mb-2">Values</label>
            <ProductOptionLinesPanel productAttributeMapping={productAttributeMapping} />
          </div>
        </div>
      </form>
    </div>
  )
}

export const VariantOptionPanelTypes = {
  CARD: 'variantOptionPanelType',
}
