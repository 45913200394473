import { IProductDetails, IProductVariant } from '../../../models/interface'
import React, { FC } from 'react'
import { ProductVariantLinePanel } from './productVariantLinePanel'
import CancelButton from '../../../components/CancelButton'
import SaveButton from '../../../components/SaveButton'
import { FormState } from 'react-hook-form'

type Props = {
  product: IProductDetails
  title: string
  productVariants: IProductVariant[]
  hideVariants: () => void
  formState: FormState<IProductDetails>
}

const ProductVariantsManage: FC<Props> = ({
  product,
  title,
  productVariants,
  hideVariants,
  formState,
}) => {
  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mx-8 p-4 max-h-screen flex flex-col flex-grow">
          <div className="max-w-full px-4 py-8 mt-4 mb-24 h-screen bg-white rounded-md  shadow-lg  overflow-x-auto overflow-y-auto">
            <div className="font-bold text-gray-800/90 mb-4">{title}</div>
            <ProductVariantPanelHeader />
            {productVariants
              .slice()
              .sort((a, b) =>
                a?.displayOrder !== undefined &&
                b?.displayOrder !== undefined &&
                a.displayOrder > b.displayOrder
                  ? 1
                  : -1
              )
              .map((productVariant) => {
                return <ProductVariantLinePanel productVariant={productVariant} product={product} />
              })}
          </div>
        </div>
      </div>
      <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
        <div className="flex space-x-4 justify-end">
          <CancelButton disabled={false} isCancelling={false} onCancel={hideVariants} />
          <SaveButton
            disabled={false}
            isSubmitting={false}
            onSubmit={() => {
              hideVariants()
            }}
            buttonText="Save Variants"
          />
        </div>
      </div>
    </div>
  )
}

export default ProductVariantsManage

function ProductVariantPanelHeader() {
  return (
    <div className="flex w-full space-x-4 mb-4 bg-gray-100 py-2 px-2">
      <div className="flex-shrink-0 text-sm  text-gray-500 font-bold w-32">DISPLAY ORDER</div>
      {/*<div className="flex-shrink-0 text-sm  text-gray-500 font-bold w-32">TAG</div>*/}
      {/*<div className="flex-shrink-0 text-sm text-gray-500 font-bold w-24">IN STOCK</div>*/}
      <div className="flex-shrink-0 text-sm text-gray-500 font-bold w-96">NAME</div>
      <div className="flex-shrink-0 text-sm text-gray-500 font-bold w-64">PRICE</div>
      <div className="flex-shrink-0 text-sm text-gray-500 font-bold w-64">SALE PRICE</div>
      <div className="flex-shrink-0 text-sm text-gray-500 font-bold w-48">SKU</div>
      <div className="flex-shrink-0 text-sm text-gray-500 font-bold w-48">IMAGE</div>
    </div>
  )
}
