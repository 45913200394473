import React, { FC, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CatalogType, ICategory } from '../../models/interface'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { CategoryCardComponent } from './category-card.component'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { useAppSelector } from '../../redux/Hooks'
import { CategorySearchPanel } from './search/categorySearchPanel'
import NavigateBackLayout from '../../layouts/navigateBackLayout'

interface CategoriesPageParamsProps {
  parentCategoryId?: string
  parentCategory?: string
}

interface Props {
  catalogType: CatalogType
  title: string
  addTitle: string
  loadingTitle: string
}

const CategoriesPage: FC<Props> = ({
  catalogType = CatalogType.Ecommerce,
  loadingTitle = 'Loading collections ...',
  addTitle = 'Add collection',
  title = 'Collections',
}) => {
  const history = useHistory()
  const [categories, setCategories] = useState<ICategory[]>([])
  const [loading, setLoading] = useState(false)
  const [, setRefresh] = useState(false)
  const location = useLocation<CategoriesPageParamsProps>()
  const parentCategoryId = location.state?.parentCategoryId ?? undefined
  const parentCategory = location.state?.parentCategory ?? undefined
  const [filteredCategories, setFilteredCategories] = useState<ICategory[]>([])
  const { searchCategoryName } = useAppSelector(({ appReducer: reducer }) => reducer)

  const addCategory = () =>
    history.push('/categorydetails/add', {
      add: true,
      parentCategoryId: parentCategoryId,
      parentCategory: parentCategory,
      catalogType: catalogType,
    })

  useEffect(() => {
    setLoading(true)
    if (!parentCategoryId) {
      if (catalogType === CatalogType.Ecommerce) {
        hourMarketApi
          .getCategoryTreeFirstLevel()
          .then((r) => {
            setFilteredCategories(r.data)
            setCategories(r.data)
          })
          .finally(() => setLoading(false))
      }
      if (catalogType === CatalogType.Experience) {
        hourMarketApi
          .getExperienceCategoryTreeFirstLevel()
          .then((r) => {
            setFilteredCategories(r.data)
            setCategories(r.data)
          })
          .finally(() => setLoading(false))
      }
    } else {
      if (catalogType === CatalogType.Ecommerce) {
        hourMarketApi
          .getCategoryTreeChildLevel(parentCategoryId!)
          .then((r) => {
            setFilteredCategories(r.data)
            setCategories(r.data)
          })
          .finally(() => setLoading(false))
      }
      if (catalogType === CatalogType.Experience) {
        hourMarketApi
          .getExperienceCategoryTreeChildLevel(parentCategoryId!)
          .then((r) => {
            setFilteredCategories(r.data)
            setCategories(r.data)
          })
          .finally(() => setLoading(false))
      }
    }
  }, [parentCategoryId])

  const deleteCategory = async (id: string) =>
    await hourMarketApi.deleteCategory(id).finally(() => setRefresh(true))

  const showCategoryPreview = async (id: string) => history.push(`/categories/preview/${id}`)

  useEffect(() => {
    if (searchCategoryName) {
      const filteredCategories = categories.filter((category) =>
        category.title?.toLowerCase().includes(searchCategoryName.toLowerCase())
      )
      setFilteredCategories(filteredCategories)
    } else {
      setFilteredCategories(categories)
    }
  }, [searchCategoryName, categories])

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow pb-8">
          {loading ? (
            <LoadingIndicatorPanel message={loadingTitle} />
          ) : (
            <>
              {parentCategory && (
                <NavigateBackLayout title={parentCategory} onPressed={history.goBack} />
              )}
              <CrudListHeader
                headerLabel={!parentCategory ? `${title}` : `SUB CATEGORIES FOR ${parentCategory}`}
                subheaderLabel={addTitle}
                addAction={addCategory}
              />
              <div className="z-30 flex space-x-4 mb-2">
                <CategorySearchPanel />
              </div>
              <div className="bg-white min-w-full divide-y flex-1 rounded-md shadow-sm overflow-hidden">
                {filteredCategories
                  .sort(function (a, b) {
                    return a.displayOrder > b.displayOrder ? 1 : -1
                  })
                  .map((category) => (
                    <div
                      key={category.id}
                      className="bg-white cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                      <Link
                        to={{
                          pathname: `/categorydetails/${category.id}`,
                          state: { add: false },
                        }}>
                        <CategoryCardComponent
                          key={category.id}
                          deleteCategory={deleteCategory}
                          category={category}
                          showCategoryPreview={showCategoryPreview}
                          catalogType={catalogType}
                        />
                      </Link>
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CategoriesPage
