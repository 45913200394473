import { CatalogType } from '../../../models/interface'
import ProductTypesPage from '../producttypes.page'
import { useLocation } from 'react-router-dom'

interface Props {
  parentProductTypeId?: string
  parentProductTypeTitle?: string
}

const ExperienceProductTypesPage = () => {
  const location = useLocation<Props>()
  const parentProductTypeId = location?.state?.parentProductTypeId
  const parentProductTypeTitle = location?.state?.parentProductTypeTitle

  return (
    <ProductTypesPage
      catalogType={CatalogType.Experience}
      title={'Event Types'}
      addTitle={'Add Event Type'}
      loadingTitle={'Loading event types ...'}
      parentProductTypeId={parentProductTypeId}
      parentProductTypeTitle={parentProductTypeTitle}
    />
  )
}

export default ExperienceProductTypesPage
