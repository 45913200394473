import { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'

export interface ReturnedFetchData<T> {
  data?: T
  error?: any
  loading: boolean
}

const useFetch = <FetchedData>(
  apiCall: () => Promise<AxiosResponse<FetchedData>>
): ReturnedFetchData<FetchedData> => {
  const [fetchedData, setFetchedData] = useState<ReturnedFetchData<FetchedData>>({
    loading: true,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await apiCall()
        setFetchedData({ data, loading: false, error: undefined })
      } catch (error: any) {
        setFetchedData({ data: undefined, loading: false, error: error })
      }
    }
    fetchData().finally()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return fetchedData
}

export default useFetch
