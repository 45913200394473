import { CatalogType, ICategoryTree } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import useFetch, { ReturnedFetchData } from './useFetch'

const useCollectionsTree = (catalogType: CatalogType): ReturnedFetchData<ICategoryTree[]> => {
  return useFetch<ICategoryTree[]>(() =>
    catalogType === CatalogType.Ecommerce
      ? hourMarketApi.getCategoryFullTree()
      : hourMarketApi.getExperiencesCategoryFullTree()
  )
}

export default useCollectionsTree
