import React, { FC } from 'react'

interface Props {
  title: string
}

const HeaderTitleLayout: FC<Props> = ({ title }) => {
  return <div className="text-lg font-bold text-gray-600 uppercase">{title}</div>
}

export default HeaderTitleLayout
