import { startOfWeek, getDay, addDays, getHours, getMinutes, addMinutes, formatISO, format } from 'date-fns'
import { EmptySchedule, ScheduleType, SlotSchedule } from './models'
import { ConvertSlotCountPerHourToMinutes } from './SlotUtils'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { ICreateLogisticSlots, IGetLogisticSlotsResponse, ILogisticSlot } from '../../models/interface'
import LogisticsMapper from './LogisticsMapper'
import moment from 'moment'
import { AxiosResponse } from 'axios'

export default class LogisticsApi {
  static SaveSlots = async (schedule: SlotSchedule): Promise<string> => {
    const times = schedule.DayScheduleTypes.flatMap((day) => day.Times.filter((time) => time.Open))

    const slots: ILogisticSlot[] = times.map((item) => {
      const timeTo = addMinutes(item.DateFrom!, ConvertSlotCountPerHourToMinutes(schedule.SlotsPerHour))
      return {
        day: getDay(item.DateFrom!),
        date: formatISO(item.DateFrom!),
        timeFrom: `${getHours(item.DateFrom!)}:${getMinutes(item.DateFrom!)}`,
        timeTo: `${getHours(timeTo)}:${getMinutes(timeTo)}`,
      }
    })

    const dataToSave: ICreateLogisticSlots = {
      Id: schedule.Id,
      ScheduleType: 1,
      DateFrom: formatISO(startOfWeek(schedule.DateFrom)),
      DateTo: formatISO(addDays(startOfWeek(schedule.DateFrom), 7)),
      SlotsPerHour: schedule.SlotsPerHour,
      Slots: slots,
    }

    console.log('createLogisticSlots', dataToSave)
    const response = await hourMarketApi.createLogisticSlots(dataToSave)

    return response.data.Id
  }

  static getSlots = async (scheduleType: ScheduleType, dateFrom: Date): Promise<SlotSchedule> => {
    const weekStart: Date = startOfWeek(dateFrom)
    let schedule: SlotSchedule = EmptySchedule(scheduleType, weekStart)
    console.log('LogisticsApi getSlots scheduleType', scheduleType, dateFrom, weekStart)

    const r: AxiosResponse<IGetLogisticSlotsResponse> = await hourMarketApi.getLogisticSlotsByWeekAndType({
      ScheduleType: scheduleType === 'Collection' ? 1 : 2,
      DateFrom: format(startOfWeek(dateFrom), 'yyyy-MM-dd'),
    })

    if (r.data) {
      console.log('getSlots completed', r.data, r.data.id)
      schedule = {
        Id: r.data.id,
        DateFrom: new Date(r.data.dateFrom),
        DateTo: new Date(r.data.dateTo),
        SlotsPerHour: r.data.slotsPerHour,
        ScheduleType: r.data.scheduleType === 1 ? 'Collection' : 'Delivery',
        DayScheduleTypes: LogisticsMapper.MapToSlotsToSlotScheduleDays(r.data.slotsPerHour, r.data.slots),
      } as SlotSchedule
      console.log('getSlots schedule', schedule)
    }

    return schedule
  }
}
