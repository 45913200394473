import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

const ZOOM_MIN = 1
const ZOOM_MAX = 3
const ZOOM_STEP = 0.01

export default function ZoomSlider({ onChange, zoom }) {
  return (
    <div className="">
      <h1 className="text-sm text-gray-800 font-medium">Image zoom</h1>
      <div className="w-auto px-2 py-2 mx-auto">
        <Slider
          value={zoom}
          handleStyle={{
            backgroundColor: 'text-primary',
            borderColor: 'text-primary',
          }}
          min={ZOOM_MIN}
          max={ZOOM_MAX}
          onChange={onChange}
          trackStyle={{
            backgroundColor: 'text-primary',
          }}
          step={ZOOM_STEP}
        />
      </div>
    </div>
  )
}
