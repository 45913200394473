import { ProductAttributesPage } from '../../productattributes/product-attributes.page'
import { CatalogType } from '../../../models/interface'

const ExperienceAttributesPage = () => {
  return (
    <ProductAttributesPage
      catalogType={CatalogType.Experience}
      title={'Event Options'}
      addTitle={'Add Event Option'}
      loadingTitle={'Loading Event Options ...'}
    />
  )
}

export default ExperienceAttributesPage
