import React, { useContext } from 'react'

import '../assets/menu.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Disclosure } from '@headlessui/react'

import { AuthContext } from '../Utils/Auth/authContext'
import {
  clearPersistedState,
  LOCAL_STORAGE_KEYS,
  usePersistedState,
} from '../Utils/usePersistedState'
import { YesNoDialog } from './dialogs'
import { appConfigLogo, appConfigShowLogo, appIsIFramedEnabled } from '../config/appconfig'
import { faLock } from '@fortawesome/pro-solid-svg-icons'
import { useMsal } from '@azure/msal-react'
import { useAppSelector } from '../redux/Hooks'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SidebarMenu = ({ navigation }) => {
  const authContext = useContext(AuthContext)
  const [showLogoutModal, setShowLogoutModal] = React.useState(false)
  const [navigationState, setNavigationState] = usePersistedState(
    LOCAL_STORAGE_KEYS.SIDE_MENU_KEY,
    navigation
  )

  const { businessName } = useAppSelector(({ appReducer: reducer }) => reducer)
  const { instance } = useMsal()

  const onClicked = (item) => {
    const newNavigationState = [...navigationState]
    const index = navigationState.findIndex((e) => e.name === item.name)
    const itemFound = navigationState.find((e) => e.name === item.name)
    newNavigationState[index] = { ...itemFound, open: !item.open }
    setNavigationState(newNavigationState)
  }

  const yesAction = () => {
    setShowLogoutModal(false)
    instance.logoutRedirect()
    clearPersistedState(LOCAL_STORAGE_KEYS.SIDE_MENU_KEY)
  }

  return (
    <div className="w-72 flex flex-col bg-white overflow-hidden border-2">
      <div className="flex items-center text-white my-4 mx-4">
        {!appIsIFramedEnabled && appConfigShowLogo && (
          <div className="flex flex-col">
            <img src={appConfigLogo} className="h-auto px-6 py-2 w-full object-cover" alt="Logo" />
            <div className="text-gray-600 text-center text-sm font-semibold mt-2">
              {businessName}
            </div>
            {process.env.REACT_APP_MODE === 'TEST' && (
              <div className="font-extrabold text-red-600 text-center">** TEST **</div>
            )}
          </div>
        )}
        {appIsIFramedEnabled && <div className="font-bold text-gray-600">E-Commerce</div>}
      </div>

      <nav className="flex-1 overflow-y-auto px-2 space-y-1 bg-white" aria-label="Sidebar">
        {navigationState
          .filter((x) => x.enabled)
          .map((item) =>
            !item.children ? (
              item.enabled ? (
                <div key={item.name}>
                  <a
                    href={item.href}
                    className={
                      'group text-gray-600 text-sm flex items-center w-full py-3 font-medium  border-l-8 border-transparent hover:text-pixie hover:bg-pixie hover:bg-opacity-10 menu-item-container'
                    }>
                    {item.icon && (
                      <FontAwesomeIcon
                        icon={item.icon}
                        size="lg"
                        className="text-gray-300 fa-fw group-hover:text-pixie mr-4"
                      />
                    )}
                    {item.name}
                  </a>
                </div>
              ) : (
                <></>
              )
            ) : item.enabled ? (
              <Disclosure as="div" key={item.name} className="group space-y-1">
                {({ open }) => (
                  <>
                    <div onClick={() => onClicked(item)}>
                      <Disclosure.Button className="focus:outline-none text-gray-800 text-sm flex items-center w-full py-3 font-semibold border-l-8 border-transparent hover:text-pixie hover:bg-pixie hover:bg-opacity-10 menu-item-container">
                        <FontAwesomeIcon
                          icon={item.icon}
                          size={'lg'}
                          className="text-gray-300 fa-fw text-left group-hover:text-pixie mr-4"
                        />
                        {item.name}
                        <svg
                          className={classNames(
                            open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                            'ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true">
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel static className="space-y-1">
                      {item.open &&
                        item.children.map((subItem) =>
                          subItem.enabled ? (
                            <a
                              key={subItem.name}
                              href={subItem.href}
                              className="group text-gray-600 text-sm flex items-center w-full pl-11 pr-2 py-3 font-medium border-l-8 border-transparent hover:text-pixie hover:bg-pixie hover:bg-opacity-10 menu-item-container">
                              {subItem.name}
                            </a>
                          ) : (
                            <></>
                          )
                        )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <></>
            )
          )}
      </nav>
      {/* Footer */}
      {!appIsIFramedEnabled && (
        <div className="py-2 px-2 flex-none border-t">
          <button
            className="group focus:outline-none text-gray-600 text-sm flex items-center w-full py-3 font-medium border-l-8 border-transparent hover:text-pixie hover:bg-pixie hover:bg-opacity-10 menu-item-container"
            onClick={() => setShowLogoutModal(true)}>
            <FontAwesomeIcon
              icon={faLock}
              size="lg"
              className="text-gray-300 group-hover:text-pixie mr-4"
            />
            Sign out
          </button>
        </div>
      )}
      <YesNoDialog
        alertType={'warning'}
        modalIsOpen={showLogoutModal}
        closeModal={() => setShowLogoutModal(false)}
        yesAction={yesAction}
        noAction={() => setShowLogoutModal(false)}
        title="Logout"
        subtitle="Are you sure you want to logout ?"
        description=""
      />
    </div>
  )
}

export default SidebarMenu
