import React, { FC } from 'react'

const ImageWithFallback: FC<ImageWithFallbackProps> = ({ mainImage, fallbackImage, altText, additionalCss }) => {
  const imageOnLoadHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log(`The image with url of ${event.currentTarget.src} has been loaded`)
  }

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = fallbackImage
    event.currentTarget.className = additionalCss
  }

  return <img src={mainImage} onLoad={imageOnLoadHandler} onError={imageOnErrorHandler} alt={altText} className={additionalCss} />
}

export default ImageWithFallback

type ImageWithFallbackProps = {
  mainImage: string
  fallbackImage: string
  altText: string
  additionalCss: string
}
