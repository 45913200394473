import React, { FC } from 'react'
import { IProductType, IProductTypeTree } from '../../../models/interface'
import ProductTypeTreeItem from './ProductTypeTreeItem'

interface Props {
  productTypes: IProductTypeTree[]
  onSelected: (id: string | undefined, selected: boolean) => void
  // These are product types that need to be marked as selected
  selectedProductTypes?: IProductType[]
}

const ProductTypeNestedTree: FC<Props> = ({ productTypes, selectedProductTypes, onSelected }) => {
  return (
    <>
      {productTypes?.map((item) => {
        return (
          <div className="mb-10">
            <ProductTypeTreeItem productType={item} onSelected={onSelected} />
            <div className="flex flex-wrap">
              {item.childProductTypes?.map((childItem) => (
                <ProductTypeTreeItem
                  key={childItem.id}
                  productType={childItem}
                  onSelected={onSelected}
                  selected={selectedProductTypes?.some((x) => x.id === childItem.id)}
                />
              ))}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ProductTypeNestedTree
