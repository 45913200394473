import React, { FC, useCallback, useEffect, useState } from 'react'
import { IProductType, IProductTypeTree } from '../../../models/interface'
import { flattenProductTypes } from './helpers'
import ProductTypeNestedTree from './ProductTypeNestedTree'
import ProductTypeNonNestedTree from './ProductTypeNonNestedTree'

interface Props {
  productTypes: IProductTypeTree[]
  onProductTypesChanged: (productTypes: IProductTypeTree[]) => void
  // These are product types that need to be marked as selected
  selectedProductTypes?: IProductType[]
}

const ProductTypesTree: FC<Props> = ({
  productTypes,
  selectedProductTypes,
  onProductTypesChanged,
}) => {
  const [flatMapProductTypes] = useState(flattenProductTypes(productTypes))
  const [selectedItems, setSelectedItems] = useState<IProductType[]>(selectedProductTypes ?? [])
  const isNestedLayout = flatMapProductTypes.some((x) => x.isChild === true)

  const onSelected = useCallback(
    (id: string | undefined, selected: boolean) => {
      if (selected) {
        const selectedProductType = flatMapProductTypes?.find((item) => item.id === id)
        if (selectedProductType) {
          setSelectedItems([...selectedItems.concat(selectedProductType)])
        }
      } else {
        setSelectedItems([...selectedItems.filter((item) => item.id !== id)])
      }
    },
    [flatMapProductTypes, selectedItems]
  )

  useEffect(() => {
    onProductTypesChanged(selectedItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems])

  return (
    <>
      <div className="mb-10">
        {isNestedLayout ? (
          <ProductTypeNestedTree
            productTypes={productTypes}
            onSelected={onSelected}
            selectedProductTypes={selectedItems}
          />
        ) : (
          <ProductTypeNonNestedTree
            productTypes={productTypes}
            onSelected={onSelected}
            selectedProductTypes={selectedItems}
          />
        )}
      </div>
    </>
  )
}

export default ProductTypesTree
