import React, {useState} from 'react';
import LoadingIndicator from "../../../components/LoadingIndicator";
import {CrudListHeader} from "../../../components/crudlistheader.component";

const OrderSettings = () => {
    const [loading, setLoading] = useState(false);

    return loading ? (
        <LoadingIndicator size={"small"} fill={true} />
    ) : (
        <>
            <div className="m-10 ml-16 p-4">
                <CrudListHeader
                    headerLabel={"Order Settings"}
                    subheaderLabel={""}
                    addAction={""}
                />
                <div className="flex flex-col bg-white rounded-md">
                    <div className="min-w-full p-4">
                        <div className="text-base">Various Order Settings</div>
                        <div className="text-sm text-gray-500">Various order settings will go here.</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderSettings;
