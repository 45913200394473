import { ICategory, ICategoryTree } from '../../../models/interface'
import React, { FC, useState } from 'react'

type Props = {
  category: ICategoryTree
  initialSelectedCategories: string[]
  categorySelected: (isSelected: boolean, categoryId: string) => void
}
export const CollectionsFilterItem: FC<Props> = ({
  category,
  initialSelectedCategories,
  categorySelected,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    initialSelectedCategories.some((x) => x === category.id)
  )

  const onCategorySelected = (checked: boolean) => {
    setIsChecked(checked)
    categorySelected(checked, category.id!)
  }

  return (
    <div className="flex flex-col">
      <div className="flex space-x-2 my-2 items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onCategorySelected(e.target.checked)}
          className="h-4 w-4 text-pixie selected:outline-none focus:outline-none focus:ring-0"
        />
        <div className="text-sm">{category.title}</div>
      </div>
      {category?.childCategories && (
        <div className="pl-8">
          {category?.childCategories
            ?.sort(function (a, b) {
              return a.displayOrder !== undefined &&
                b.displayOrder !== undefined &&
                a.displayOrder > b.displayOrder
                ? 1
                : -1
            })
            .map((category: ICategory) => {
              return (
                <CollectionsFilterItem
                  key={category.id}
                  category={category}
                  categorySelected={categorySelected}
                  initialSelectedCategories={initialSelectedCategories}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}
