import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

type Props = {
  disabled: boolean
  isCancelling: boolean
  onDelete: () => void
}

const DeleteButton: FC<Props> = ({ disabled, isCancelling, onDelete }) => {
  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  return (
    <button
      type={'button'}
      disabled={disabled}
      onClick={onDelete}
      className={classNames(
        disabled ? 'text-red-400 bg-opacity-30' : 'text-red-400',
        'h-8 w-36 mt-5 border border-red-400 bg-white rounded-md shadow-sm py-1 px-4 inline-flex items-center justify-center text-sm font-medium hover:bg-red-50 focus:outline-none'
      )}>
      {isCancelling ? (
        <>
          <span className="mr-2">Saving</span>
          <FontAwesomeIcon
            icon={faSpinner}
            size="1x"
            spin={true}
            className="my-auto text-white/90"
          />
        </>
      ) : (
        <div className="flex space-x-2 items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} color="bg-red-400" />
          <span>Delete</span>
        </div>
      )}
    </button>
  )
}

export default DeleteButton
