interface ProductInventoryStrategy {
    id: number
    name: string
}

export const  ProductInventoryStrategies: ProductInventoryStrategy[] = [
    { id: 0, name: 'Not Inventory Controlled' },
    { id: 1, name: 'Inventory Controlled By Product' },
]

export default ProductInventoryStrategy
