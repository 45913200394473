//https://codesandbox.io/s/l9ko3oqwym
//https://www.nicesnippets.com/blog/react-color-picker-example-with-change-event

import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical } from '@fortawesome/pro-light-svg-icons'
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd'
import {
  IProductAttribute,
  IProductAttributeMapping,
  IProductAttributeValue,
} from '../../../models/interface'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'
import { ColorResult, SketchPicker } from 'react-color'

interface ProductOptionLinePanelProps {
  id: any
  index: number
  name?: string
  attribute: IProductAttribute
  attributeMapping: IProductAttributeMapping
  attributeValue: IProductAttributeValue
  moveLine: (dragIndex: number, hoverIndex: number) => void
}

const style = {
  border: '1px dashed gray',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

export const ProductOptionLinePanel: FC<ProductOptionLinePanelProps> = ({
  id,
  index,
  name,
  attribute,
  attributeMapping,
  attributeValue,
  moveLine,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [optionAttributeValue, setOptionAttributeValue] = useState<
    IProductAttributeValue | undefined
  >(attributeValue)
  const [color, setColor] = useState<string>()
  const [showColorPicker, setShowColorPicker] = useState<Boolean>(false)
  const productOptionsDispatch = useContext(ProductDispatchContext)
  const productOptionsState = useContext(ProductStateContext)

  const onDeleteAttributeLineValue = (e: React.SyntheticEvent) => {
    e.preventDefault()
    productOptionsDispatch({
      type: 'deleteAttributeValue',
      productAttributeValue: attributeValue,
      productAttributeId: attribute.id!,
    })
  }

  interface DragItem {
    index: number
    id: string
    type: string
  }

  const onValueForNameChanged = (e: any) => {
    const { value } = e.target
    if (optionAttributeValue !== undefined) {
      setOptionAttributeValue({ ...optionAttributeValue, name: value })
    }
  }

  const onInputBlurred = (e: any) => {
    if (optionAttributeValue !== undefined) {
      productOptionsDispatch({
        type: 'updateAttributeValue',
        productAttributeValue: attributeValue,
        productAttributeId: attribute.id!,
        optionAttributeValue: optionAttributeValue,
      })
    }
  }

  const onValueForColorChanged = (e: any) => {
    const { value } = e.target
    if (optionAttributeValue !== undefined) {
      setOptionAttributeValue({ ...optionAttributeValue, colorSquaresRgb: value })
    }
  }

  const onValueForImageChanged = (e: any) => {
    const { value } = e.target
    if (optionAttributeValue !== undefined) {
      setOptionAttributeValue({ ...optionAttributeValue, imageSquaresPictureId: value })
    }
  }

  useEffect(() => {
    // console.log("onValueChanged", optionAttributeValue)
  }, [optionAttributeValue])

  const [{ handlerId }, drop] = useDrop({
    accept: AttributeValueLineTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveLine(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: AttributeValueLineTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const displayColorPicker = () => {
    setShowColorPicker(true)
  }

  const handleColorChange = (color: ColorResult) => {
    if (optionAttributeValue !== undefined) {
      setOptionAttributeValue({ ...optionAttributeValue, colorSquaresRgb: color.hex })
      productOptionsDispatch({
        type: 'updateAttributeValue',
        productAttributeValue: attributeValue,
        productAttributeId: attribute.id!,
        optionAttributeValue: optionAttributeValue,
      })
    }
    setColor(color.hex)
    // setShowColorPicker(false)
  }

  return (
    <div
      className="flex space-x-2"
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}>
      <button className=" focus:outline-none mr-4">
        <FontAwesomeIcon icon={faGripVertical} size="1x" />
      </button>
      <div className="flex flex-col space-y-2">
        <input
          type="text"
          value={optionAttributeValue?.name}
          onChange={onValueForNameChanged}
          onBlur={onInputBlurred}
          className="w-96 text-sm border-gray-300"
        />
        {attributeMapping.displayType === 'Swatch' && (
          <div>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={optionAttributeValue?.colorSquaresRgb}
                onChange={onValueForColorChanged}
                onBlur={onInputBlurred}
                placeholder={'colour'}
                className="w-24 text-sm border-gray-300"
              />
              <div
                onClick={() => displayColorPicker()}
                className="w-8 h-8 border border-gray-300"
                style={{ backgroundColor: optionAttributeValue?.colorSquaresRgb }}></div>
            </div>
            {showColorPicker && (
              <div style={{ position: 'absolute', zIndex: 2 }}>
                <div
                  style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                  }}
                  onClick={() => setShowColorPicker(false)}
                />
                <SketchPicker color={color} onChangeComplete={handleColorChange} />
              </div>
            )}
          </div>
        )}
        {attributeMapping.displayType === 'ImageList' && (
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={optionAttributeValue?.imageSquaresPictureId}
              onChange={onValueForImageChanged}
              onBlur={onInputBlurred}
              placeholder={'image id'}
              className="w-48 text-sm border-gray-300"
            />
            <img
              src={'https://via.placeholder.com/100'}
              className="object-cover h-16 w-16 border border-gray-400"
              alt=""
            />
          </div>
        )}
      </div>
      <button
        onClick={onDeleteAttributeLineValue}
        className="text-red-600 font-extrabold focus:outline-none">
        X
      </button>
    </div>
  )
}

export const AttributeValueLineTypes = {
  CARD: 'attributeValueLineType',
}
