import React, { FC } from 'react'
import { InputLabelElement } from '../../../components/form-elements/input-element'
import ProductInventoryStrategy from '../../../Types/Products/ProductInventoryTypes'

interface Props {
  inventoryStrategy: ProductInventoryStrategy
  manageProductInventory: () => void
  inventoryStartQuantity: number | undefined
  inventoryQuantity: number | undefined
  inventoryAllocatedQuantity: number | undefined
}

const ProductInventoryContainer: FC<Props> = ({
  inventoryStrategy,
  inventoryStartQuantity,
  inventoryQuantity,
  inventoryAllocatedQuantity,
  manageProductInventory,
}) => {
  return (
    <div className="">
      <div className="flex justify-between items-center">
        <div className="font-bold text-gray-800/90 mb-5">INVENTORY</div>
        <button
          onClick={manageProductInventory}
          className="bg-pixie text-sm font-medium text-white/90 px-4 py-1 rounded-md hover:bg-opacity-40">
          Manage
        </button>
      </div>
      <div className="mb-6">
        <InputLabelElement label={inventoryStrategy.name} id={'inventoryStrategy'} />
      </div>
      <div className="flex flex-wrap">
        <div className="grid grid-cols-3 gap-x-4">
          <div className="">
            <InputLabelElement label={'Start Quantity'} id={'inventoryStartQuantityLabel'} />
            <InputLabelElement
              label={`${inventoryStartQuantity ?? 'not defined'}`}
              id={'inventoryStartQuantity'}
              additionalCss="font-extrabold"
            />
          </div>
          <div className="">
            <InputLabelElement label={'On Hand Quantity'} id={'inventoryQuantityLabel'} />
            <InputLabelElement
              label={`${inventoryQuantity ?? 'not defined'}`}
              id={'inventoryQuantity'}
              additionalCss="font-extrabold"
            />
          </div>
          <div className="">
            <InputLabelElement
              label={'Allocated Quantity'}
              id={'inventoryAllocatedQuantityLabel'}
            />
            <InputLabelElement
              label={`${inventoryAllocatedQuantity ?? 'not defined'}`}
              id={'inventoryAllocatedQuantity'}
              additionalCss="font-extrabold"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductInventoryContainer
