import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  title: string
  onPressed: () => void
}

const NavigateBackLayout: FC<Props> = ({ title, onPressed }) => {
  return (
    <button onClick={onPressed} className="mb-4 flex items-center focus:outline-none">
      <FontAwesomeIcon icon={faArrowLeft} size="1x" className="text-gray-500 cursor-pointer" />
      <span className="ml-2 font-bold text-gray-500">{title}</span>
    </button>
  )
}

export default NavigateBackLayout
