import React from "react";
import PageHeaderComponent from "../../components/pageHeaderComponent";
import {faShoppingBag} from "@fortawesome/pro-solid-svg-icons";
import { IShopifyProduct } from "../../models/interface";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/pro-duotone-svg-icons";
import {Link, useLocation} from "react-router-dom";

interface ProductsProps {
    products: Array<IShopifyProduct>
}

const ShopifyProducts = () => {
    const location = useLocation<ProductsProps>();
    const products: Array<IShopifyProduct> = location.state.products;

    return (
        <>
            <div className="m-10 ml-16 max-w-7xl p-4 rounded-md">
                <PageHeaderComponent title="Products" icon={faShoppingBag} />
                <ProductCollection products={products} />
            </div>
        </>
    )
};

export default ShopifyProducts;

export const ProductCollection: React.FC<ProductsProps> = ({products}) => {
    return (
        <>
            {products?.length > 0 &&
            (<div className="grid grid-cols-4 gap-y-16 mt-5 py-4 space-y-2 sm:px-6 lg:px-0 lg:col-span-9 shadow-lg rounded-lg  sm:rounded-md bg-white">
                {products.map((product) => (
                    <Link to={{pathname: `/`, state: {}}}>
                        <ShopifyProductCard key={product.id} id={product.id} title={product.title} image={product.image} images={product.images} />
                    </Link>
                ))}
            </div>)}
        </>
    )
}

export const ShopifyProductCard: React.FC<IShopifyProduct> = ({id, title, image, images}) => {
    const imageSrc = images.length > 0 ? images[0].src : "";

    return (
        <>
        <div className="flex flex-col items-center">
            {imageSrc ?
                <img
                    src={imageSrc}
                    className="h-48 w-48 object-cover rounded-md hover:opacity-60"
                    alt=""
                />
                : <FontAwesomeIcon icon={faImage} size="8x" className="text-gray-200 h-48 w-48 hover:opacity-60"  />
            }
            <div className="text-gray-400 text-base pt-2 font-semibold">{title}</div>
        </div>
        </>
    )
}