import React, { FC, useEffect, useState } from 'react'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { Link, useHistory } from 'react-router-dom'
import { CatalogType, IProductAttribute } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { ProductAttributeCard } from './product-aatributes-card.component'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'

interface Props {
  catalogType: CatalogType
  title: string
  addTitle: string
  loadingTitle: string
}

export const ProductAttributesPage: FC<Props> = ({
  catalogType = CatalogType.Ecommerce,
  title = 'Product Options',
  addTitle = 'Add Product Option',
  loadingTitle = 'Loading Product Options ...',
}) => {
  const history = useHistory()
  const [items, setItems] = useState<IProductAttribute[]>([])
  const [loading, setLoading] = useState(false)
  const addProductAttribute = () =>
    history.push('/productattributes/add', { add: true, catalogType: catalogType })

  useEffect(() => {
    setLoading(true)
    if (catalogType === CatalogType.Ecommerce) {
      hourMarketApi
        .getProductAttributes()
        .then((r) => setItems(r.data))
        .finally(() => setLoading(false))
    }
    if (catalogType === CatalogType.Experience) {
      hourMarketApi
        .getExperienceProductAttributes()
        .then((r) => setItems(r.data))
        .finally(() => setLoading(false))
    }
  }, [])

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow">
          {loading ? (
            <LoadingIndicatorPanel message={loadingTitle} />
          ) : (
            <>
              <CrudListHeader
                headerLabel={title}
                subheaderLabel={addTitle}
                addAction={addProductAttribute}
              />
              <div className="bg-white min-w-full divide-y flex-1">
                {items.map((productAttribute) => (
                  <div
                    key={productAttribute.id}
                    className="bg-white cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                    <Link
                      to={{
                        pathname: `/productattributes/edit/${productAttribute.id}`,
                        state: { add: false },
                      }}>
                      <ProductAttributeCard
                        key={productAttribute.id}
                        productAttribute={productAttribute}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
