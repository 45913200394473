import { IProductAttributeMapping } from '../../../models/interface'
import React, { FC } from 'react'
import _ from 'lodash'

type Props = {
  productAttributeMapping: IProductAttributeMapping
}

const ProductAttributeValuesPanel: FC<Props> = ({ productAttributeMapping }) => {
  return (
    <>
      {productAttributeMapping.attributeValues.length > 0 ? (
        <span className="flex flex-wrap space-x-4">
          {_.concat(productAttributeMapping.attributeValues)
            ?.sort(function (a, b) {
              return a.displayOrder > b.displayOrder ? 1 : -1
            })
            .map((attributeValue) => {
              return (
                <span key={attributeValue.id} className="border py-1 px-4 text-sm rounded-md text-gray-600">
                  {attributeValue.name}
                </span>
              )
            })}
        </span>
      ) : (
        <></>
      )}
    </>
  )
}

export default ProductAttributeValuesPanel
