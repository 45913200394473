import React from "react";
import { numberFormat } from "../../Utils/app.utils";
import { IProduct } from "../../models/interface";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/pro-duotone-svg-icons";

type CategoryProductCardProps = {
    product: IProduct,
    showProductPreview: (id: string) => void
}

const CategoryProductCard: React.FC<CategoryProductCardProps> = ({product, showProductPreview}) => {

    const showPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        showProductPreview(product?.id!)
    }

  return (
      <div className="flex justify-between px-2 py-2">
          <div className="flex items-center">
              {product.image
                  ? <img
                      src={product.image}
                      className="object-cover h-16 w-16"
                      alt=""
                  />
                  : <div className="flex h-16 w-16 items-center justify-center">
                      <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-200" />
                  </div>
              }
              <div className="flex flex-col ml-4">
                  <div className="text-gray-800">{product.name}</div>
                  {/*<div className="text-gray-800">*/}
                  {/*    {numberFormat(product.price)}*/}
                  {/*</div>*/}
              </div>
          </div>
          <div className="flex flex-col justify-center ml-4">
              <button onClick={showPreview} className="z-10 text-pixie bg-white border border-pixie px-4 py-1 rounded-full font-normal text-sm hover:font-bold hover:bg-white">
                  Preview
              </button>
          </div>
    </div>
  );
};

export default CategoryProductCard;
