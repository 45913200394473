import { ILogisticSlot } from '../../models/interface'
import { SlotScheduleDay, SlotTimeType } from './models'
import { addMinutes, parseISO } from 'date-fns'
import { ConvertSlotCountPerHourToMinutes } from './SlotUtils'

export default class LogisticsMapper {
  static MapToSlotsToSlotScheduleDays(slotsPerHour: number, slots: ILogisticSlot[]): SlotScheduleDay[] {
    let days: SlotScheduleDay[] = []

    slots.forEach((slot) => {
      const dayExists = days.find((day) => day.DayType.Number === slot.day)
      const time = LogisticsMapper.MapToLogisticSlotToSlotTimeType(slotsPerHour, slot)
      if (!dayExists) {
        const newDay: SlotScheduleDay = {
          DayType: {
            Number: slot.day,
            Display: '',
          },
          Times: [],
        }
        newDay.Times.push(time)
        days.push(newDay)
      } else {
        dayExists.Times.push(time)
      }
    })

    return days
  }

  static MapToLogisticSlotToSlotTimeType(slotsPerHour: number, slot: ILogisticSlot): SlotTimeType {
    const slotDurationMins = ConvertSlotCountPerHourToMinutes(slotsPerHour)

    return {
      Open: true,
      DateFrom: parseISO(slot.date),
      DateTo: addMinutes(parseISO(slot.date), slotDurationMins),
    } as SlotTimeType
  }
}
