import React, { FC } from 'react'
import { useAppSelector } from '../../../redux/Hooks'
import { useDispatch } from 'react-redux'

export const CategorySearchPanel: FC = () => {
  const { searchCategoryName } = useAppSelector(({ appReducer: reducer }) => reducer)
  const dispatch = useDispatch()

  return (
    <div>
      <input
        type="text"
        value={searchCategoryName}
        onChange={(e) => dispatch({ type: 'searchCategoryNameChanged', name: e.target.value })}
        placeholder="search for collections"
        className="border-gray-200 text-gray-500 p-1 w-96 selected:ring-0 focus:ring-0 focus:border-gray-200 focus:outline-none"
      />
    </div>
  )
}
