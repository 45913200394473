import React, {FC, MouseEventHandler} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-duotone-svg-icons";

type CancelButtonProps = {
    disabled: boolean,
    isCancelling: boolean,
    onCancel: MouseEventHandler
}

const CancelButton: FC<CancelButtonProps> = ({disabled, isCancelling, onCancel}) => {
    const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ")

    return (
        <button
            disabled={disabled}
            onClick={onCancel}
            className={classNames(
                disabled
                    ? 'text-gray-500 text-opacity-30'
                    : 'text-gray-500',
                "h-8 w-36 mt-5 border border-gray-500 rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium hover:bg-gray-100  focus:outline-none"
            )} >
            {
                isCancelling
                    ? <><span className="mr-2">Saving</span><FontAwesomeIcon icon={faSpinner} color="white" size="1x" spin={true} className="my-auto"  /></>
                    : "Cancel"
            }
        </button>
    )
};

export default CancelButton
