import React, { FC } from 'react'
import { IOrder } from '../../models/interface'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deliveryMethodIcon, deliveryMethodText } from './DeliveryMethodUtils'
import { orderStatusText, paymentStatusText, shippingStatusText } from './OrderUtils'

type OrderCardProps = {
  order: IOrder
}

const OrderCard: FC<OrderCardProps> = ({ order }) => {
  return (
    <div className="flex flex-col space-y-2 p-4">
      <div className="flex">
        <div className="font-bold text-gray-700 text-sm w-36">Order Number:</div>
        <div className="font-medium text-gray-700 text-sm">{order.orderNumber}</div>
      </div>
      <div className="flex">
        <div className="font-bold text-gray-700 text-sm w-36">Order Status:</div>
        <div className="font-medium text-gray-700 text-sm">{orderStatusText(order)}</div>
      </div>
      <div className="flex">
        <div className="font-bold text-gray-700 text-sm w-36">Payment Status:</div>
        <div className="font-medium text-gray-700 text-sm">{paymentStatusText(order)}</div>
      </div>
      <div className="flex">
        <div className="font-bold text-gray-700 text-sm w-36">Shipping Status:</div>
        <div className="font-medium text-gray-700 text-sm">{shippingStatusText(order)}</div>
      </div>
      <div className="flex">
        <div className="font-bold text-gray-700 text-sm w-36">Order Date:</div>
        <div className="font-medium text-gray-700 text-sm">
          {moment(order.created).format('dddd DD/MM/YYYY HH:MM')}
        </div>
      </div>
      {order.shippingStatus > 10 && (
        <div className="flex items-center">
          <div className="font-bold text-gray-700 text-sm w-36">ShippingMethod:</div>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon={deliveryMethodIcon(order)}
              size="1x"
              className="mr-2 text-pixie"
            />
            <div className="font-medium text-gray-700 text-sm">{deliveryMethodText(order)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderCard
