import React from 'react';
import {IProductAttribute} from "../../models/interface";

interface IProductAttributeCard {
    productAttribute: IProductAttribute;
}

export const ProductAttributeCard: React.FC<IProductAttributeCard> = ({productAttribute}) => (
    <div className="flex justify-between px-2 py-2">
        <div className="flex" >
            <div className="text-base text-gray-800 font-normal">{productAttribute.name}</div>
        </div>
    </div>
);

