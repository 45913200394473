import produce from 'immer'

const AppReducer = (state: AppState = AppInitialState, action: AppActions) => {
  switch (action.type) {
    case 'searchCategoryNameChanged':
      return produce(state, (draft) => {
        draft.searchCategoryName = action.name
      })
    case 'searchProductNameChanged':
      return produce(state, (draft) => {
        draft.searchProductName = action.name
      })
    case 'filterProductByCategoriesChanged':
      return produce(state, (draft) => {
        draft.filteredProductCategories = action.filteredCategories
      })
    case 'filterProductByProductTypeChanged':
      return produce(state, (draft) => {
        draft.filteredProductTypes = action.filteredProductTypes
      })
    case 'setBusiness':
      return produce(state, (draft) => {
        draft.businessName = action.businessName
      })
    default:
      return state
  }
}

export type AppState = {
  businessName: string
  searchCategoryName: string
  searchProductName: string
  filteredProductCategories: string[]
  filteredProductTypes: string[]
}

const AppInitialState: AppState = {
  businessName: '',
  searchCategoryName: '',
  searchProductName: '',
  filteredProductCategories: [],
  filteredProductTypes: [],
}

export type AppActions =
    | { type: 'searchCategoryNameChanged'; name: string }
    | { type: 'searchProductNameChanged'; name: string }
    | { type: 'filterProductByCategoriesChanged'; filteredCategories: string[] }
    | { type: 'filterProductByProductTypeChanged'; filteredProductTypes: string[] }
    | { type: 'setBusiness'; businessName: string }

export { AppReducer, AppInitialState }
