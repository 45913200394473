import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import {ICategory} from "../../models/interface";
import {faImage} from "@fortawesome/pro-duotone-svg-icons";

export const ShopifyCategoryCard: React.FC<ICategory> = ({id, title, image}) => (
    <div className="flex flex-col items-center">
        {image ?
        <img
          src={image}
          className="h-48 w-48 object-cover rounded-md hover:opacity-60"
          alt=""
        />
        : <FontAwesomeIcon icon={faImage} size="8x" className="text-gray-200 h-48 w-48 hover:opacity-60"  />
        }
        <div className="text-gray-400 text-base pt-2 font-semibold">{title}</div>
    </div>
);

