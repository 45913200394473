import { AxiosRequestConfig, AxiosResponse } from 'axios'
import HttpClient from './http-client-base'
import {
  IBusinessOpeningHours,
  ICategory,
  ICategoryProducts,
  ICategorySortImages,
  ICategoryTree,
  IClassification,
  ICreateExperienceAttributeMapping,
  ICreateExperienceAttributeValues,
  ICreateLogisticSlots,
  ICreateLogisticSlotsResponse,
  ICreateProductAttributeMapping,
  ICreateProductAttributeValues,
  ICredentials,
  IDeleteCategoryMediaItem,
  IDeleteTenantMediaItem,
  IDeliveryPolygon,
  IDeliverySettings,
  IEntityMediaItemDelete,
  IEntityMediaItemUpload,
  IExperience,
  IExperienceMediaItemDelete,
  IExperienceSortImages,
  IExperienceTag,
  IGetCollectionDeliverySlotsByWeek,
  IGetCollectionDeliveryTemplate,
  IGetLogisticSlotsResponse,
  IIsInDeliveryArea,
  ILoginResponse,
  ILoyaltySetting,
  IMediaItem,
  IMediaItemUpload,
  IMediaItemUploadResponse,
  IOrder,
  IPaymentSettings,
  IProduct,
  IProductAttribute,
  IProductDetails,
  IProductInventory,
  IProductSortImages,
  IProductType,
  IProductTypeTree,
  IProductVariant,
  IRegister,
  IShopifyApiKeys,
  IShopifyConnection,
  IShopifyCustomCatalog,
  IShopifyShopInfo,
  IShopifySmartCatalog,
  IStripePayment,
  ITenant,
  ITenantDetails,
  ITenantSortImages,
  ITenantUpdate,
  IVenue,
} from '../../models/interface'
import { BaseUrl } from './api.config'

export const hourMarketApiConfig: AxiosRequestConfig = {
  timeout: 20000,
  responseType: 'json',
  baseURL: BaseUrl,
}

export class HourMarketApi extends HttpClient {
  public constructor(config: AxiosRequestConfig) {
    super(config.baseURL!)
  }

  public signin = (body: ICredentials) =>
    this.instance.post<ILoginResponse>('hourmarket/AdminSignIn', body)

  public getTenant = () => this.instance.get<ITenantDetails>(`tenants`)
  public updateTenant = (body: ITenantUpdate) => this.instance.put<ITenant>(`tenants`, body)
  public getTenantClassifications = () =>
    this.instance.get<Array<IClassification>>(
      `classifications/type/1e7c3d49-3778-43fe-a45e-661eec306e07`
    )
  public uploadTenantMediaItem = (
    body: IEntityMediaItemUpload,
    onUploadProgress?: (e: ProgressEvent) => void
  ) => {
    const formData = new FormData()
    formData.append('image', body.MediaFile)
    formData.append('MediaType', body.MediaType.toString())

    return this.uploadEntityMediaItem(
      'tenants/attachimagetoexistingtenant',
      formData,
      body,
      onUploadProgress
    )
  }
  public deleteTenantMediaItem = (body: IDeleteTenantMediaItem) =>
    this.instance.post<string>('tenants/removeimagefromexistingtenant', body)
  public updateTenantSortedImages = (body: ITenantSortImages) =>
    this.instance.put('tenants/sortimages', body)

  public getShopifyConnector = () => this.instance.get<IShopifyConnection>(`shopifyauth`)
  public getShopifySmartCatalog = () =>
    this.instance.get<Array<IShopifySmartCatalog>>(`shopifyauth/smartcatalog`)
  public getShopifyApiKey = async (): Promise<string> => {
    const r = this.instance.get<Array<IShopifyApiKeys>>(`ShopifyProxyToken`)

    const accessToken = await r.then((xxx) => {
      if (xxx?.data.length > 0) {
        return xxx?.data[0].accessToken ?? ''
      }
      return ''
    })
    return accessToken
  }
  public getShopifyApiKeys = () => this.instance.get<Array<IShopifyApiKeys>>(`ShopifyProxyToken`)
  public createShopifyApiKeys = (body: IShopifyApiKeys) =>
    this.instance.post<IShopifyApiKeys>('ShopifyProxyToken', body)
  public deleteShopifyApiKeys = (id: string) => this.instance.delete(`ShopifyProxyToken/${id}`)
  public getShopifyCustomCatalog = () =>
    this.instance.get<Array<IShopifyCustomCatalog>>(`shopifyauth/customcatalog`)
  public getShopifyShopInfo = () => this.instance.get<IShopifyShopInfo>(`shopifyauth/shopinfo`)

  public getShopifyCachedCustomCatalog = async () => {
    const apiKey = await this.getShopifyApiKey()
    console.log('ApiKey: ' + apiKey)

    const r = this.instance
      .get<Array<IShopifyCustomCatalog>>(`shopifycache/customcatalog`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      })
      .then((x) => {
        return x.data
      })

    return r
  }

  public getShopifyCachedSmartCatalog = async () => {
    const apiKey = await this.getShopifyApiKey()
    console.log('ApiKey: ' + apiKey)

    const r = this.instance
      .get<Array<IShopifySmartCatalog>>(`shopifycache/smartcatalog`, {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      })
      .then((x) => {
        return x.data
      })
    return r
  }

  public getCategoryFullTree = () =>
    this.instance.get<Array<ICategoryTree>>(`categories/firstlevel`)
  public getCategoryTreeFirstLevel = () =>
    this.instance.get<Array<ICategory>>(`categories/firstlevel`)
  public getCategoryTreeChildLevel = (id: string) =>
    this.instance.get<Array<ICategory>>(`categories/childlevel/${id}`)
  public getCategories = () => this.instance.get<Array<ICategory>>(`categories`)
  public getCategory = (id: string) => this.instance.get<ICategory>(`categories/${id}`)
  public createCategory = (body: ICategory) =>
    this.instance.post<ILoginResponse>('categories', body)
  public updateCategory = (body: ICategory) => this.instance.put<ILoginResponse>('categories', body)
  public deleteCategory = (id: string) => this.instance.delete(`categories/${id}`)
  public updateCategorySortedImages = (body: ICategorySortImages) =>
    this.instance.put('categories/sortimages', body)

  public createProductAttributeMapping = (body: ICreateProductAttributeMapping) =>
    this.instance.post('ProductsAttributeMappings', body)
  public createProductAttributeValues = (body: ICreateProductAttributeValues) =>
    this.instance.post('ProductsAttributeMappings/UpdateValues', body)
  public getProductAttributes = () =>
    this.instance.get<Array<IProductAttribute>>('productattributes')
  public getProductAttribute = (id: string) => this.instance.get(`productattributes/${id}`)
  public createProductAttribute = (body: IProductAttribute) =>
    this.instance.post('productattributes', body)
  public updateProductAttribute = (body: IProductAttribute) =>
    this.instance.put('productattributes', body)
  public deleteProductAttribute = (id: string) => this.instance.delete(`productattributes/${id}`)
  public getProductVariants = (id: string) =>
    this.instance.get<Array<IProductVariant>>(`products/productvariants/${id}`)

  public getCategoryProducts = (categoryId: string) =>
    this.instance.get<ICategoryProducts>(`categoryproducts/${categoryId}`)

  public register = (body: IRegister) => this.instance.post('hourmarket/register', body)

  public getProducts = () => this.instance.get<Array<IProduct>>(`products`)
  public createProduct = (body: IProductDetails) => this.instance.post('products', body)
  public getProduct = (id: string) => this.instance.get<IProductDetails>(`products/${id}`)
  public getProductPreview = (id: string) =>
    this.instance.get<IProductDetails>(`products/preview/${id}`)
  public updateProduct = (body: IProductDetails) => this.instance.put('products', body)
  public deleteProduct = (id: string) => this.instance.delete(`products/${id}`)
  public updateProductSortedImages = (body: IProductSortImages) =>
    this.instance.put('products/sortimages', body)

  public getProductTypeFullTree = () =>
    this.instance.get<Array<IProductTypeTree>>(`producttypes/firstlevel`)
  public getProductTypeTreeFirstLevel = () =>
    this.instance.get<Array<IProductType>>(`producttypes/firstlevel`)
  public getProductTypeTreeChildLevel = (id: string) =>
    this.instance.get<Array<IProductType>>(`producttypes/childlevel/${id}`)
  public getProductTypes = () => this.instance.get<Array<IProductType>>(`producttypes`)
  public getProductType = (id: string) => this.instance.get<IProductType>(`producttypes/${id}`)
  public createProductType = (body: IProductType) =>
    this.instance.post<ILoginResponse>('producttypes', body)
  public updateProductType = (body: IProductType) =>
    this.instance.put<ILoginResponse>('producttypes', body)
  public deleteProductType = (id: string) => this.instance.delete(`producttypes/${id}`)

  public getMediaItems = () => this.instance.get<Array<IMediaItem>>(`media`)
  public getMediaItem = (id: string) => this.instance.get<IMediaItem>(`media/${id}`)
  public uploadImageMediaItem = (body: IMediaItemUpload) => {
    console.log('uploadImageMediaItem')
    console.log(body)
    const media = body.MediaFile
    const formData = new FormData()
    formData.append('MediaFile', media)
    formData.append('MediaType', body.MediaType.toString())
    formData.append('Title', body.Title)

    return this.instance.post<IMediaItemUploadResponse>('media/images', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  }
  public uploadProductMediaItem = (
    body: IEntityMediaItemUpload,
    onUploadProgress?: (e: ProgressEvent) => void
  ) => {
    const formData = new FormData()
    formData.append('ProductImage', body.MediaFile)
    formData.append('MediaType', body.MediaType.toString())
    formData.append('ProductId', body.Id)
    return this.uploadEntityMediaItem(
      'products/attachimagetoexistingproduct',
      formData,
      body,
      onUploadProgress
    )
  }
  public removeProductMediaItem = (body: IEntityMediaItemDelete) =>
    this.instance.post<string>('products/removeimagefromexistingproduct', body)

  public uploadCategoryMediaItem = (body: IEntityMediaItemUpload) => {
    const formData = new FormData()
    formData.append('CategoryImage', body.MediaFile)
    formData.append('MediaType', body.MediaType.toString())
    formData.append('CategoryId', body.Id)

    return this.uploadEntityMediaItem('categories/attachimagetoexistingcategory', formData, body)
  }

  public deleteCategoryMediaItem = (body: IDeleteCategoryMediaItem) =>
    this.instance.post(`categories/removeimagefromexistingcategory`, body)

  public getLogisticSlots = () =>
    this.instance.get<[IGetLogisticSlotsResponse]>('LogisticsSlotsSchedule')

  public getLogisticCollectionSlotsTemplate = (
    query: IGetCollectionDeliveryTemplate
  ): Promise<AxiosResponse<IGetLogisticSlotsResponse>> => {
    return this.instance.get<IGetLogisticSlotsResponse>(
      `LogisticsSlotsSchedule/GetLogisticsTemplate?scheduleType=${query.ScheduleType}`
    )
  }

  public getLogisticSlotsByWeekAndType = (
    query: IGetCollectionDeliverySlotsByWeek
  ): Promise<AxiosResponse<IGetLogisticSlotsResponse>> => {
    return this.instance.get<IGetLogisticSlotsResponse>(
      `LogisticsSlotsSchedule/GetByWeekAndType?weekStart=${query.DateFrom}&scheduleType=${query.ScheduleType}`
    )
  }

  public createLogisticSlots = (body: ICreateLogisticSlots) =>
    this.instance.post<ICreateLogisticSlotsResponse>('LogisticsSlotsSchedule', body)

  public getDeliverySettings = () =>
    this.instance.get<IDeliverySettings>(`tenants/settings/delivery`)

  public saveDeliverySettings = (body: IDeliverySettings) =>
    this.instance.post(`tenants/settings/delivery`, body)

  public getLoyaltySettings = () => this.instance.get<ILoyaltySetting>(`tenants/settings/loyalty`)

  public saveLoyaltySettings = (body: ILoyaltySetting) =>
    this.instance.post(`tenants/settings/loyalty`, body)

  public isPostCodeInDeliveryArea = (postcode: string) =>
    this.instance.get<IIsInDeliveryArea>(
      `hourmarket/delivery/postcodeiscovered/${encodeURI(postcode)}`
    )

  public saveDeliveryPolygonSettings = (body: IDeliveryPolygon) =>
    this.instance.post(`tenants/settings/deliverypolygon`, body)

  public getDeliveryPolygonSettings = () =>
    this.instance.get<IDeliverySettings>(`tenants/settings/deliverypolygon`)

  public getOrders = () => this.instance.get<Array<IOrder>>(`orders`)

  public getOrder = (id: string) => this.instance.get<IOrder>(`orders/${id}`)

  public getExperiences = () => this.instance.get<Array<IProduct>>(`experiences`)

  public getExperience = (id: string) => this.instance.get<IProductDetails>(`experiences/${id}`)

  public createExperience = (body: IProductDetails) => this.instance.post('experiences', body)

  public getExperiencesCategoryFullTree = () =>
    this.instance.get<Array<ICategoryTree>>(`experiences/categories/firstlevel`)

  public getExperienceCategory = (id: string) =>
    this.instance.get<ICategory>(`experiences/categories/${id}`)

  public createExperienceCategory = (body: ICategory) =>
    this.instance.post<ICategory>('experiences/categories', body)

  public getExperienceCategoryTreeFirstLevel = () =>
    this.instance.get<Array<ICategory>>(`experiences/categories/firstlevel`)

  public getExperienceCategoryTreeChildLevel = (id: string) =>
    this.instance.get<Array<ICategory>>(`experiences/categories/childlevel/${id}`)

  public getExperienceProductAttributes = () =>
    this.instance.get<Array<IProductAttribute>>('experiences/productattributes')

  public getExperienceProductAttribute = (id: string) =>
    this.instance.get(`experiences/productattributes/${id}`)

  public createExperienceProductAttribute = (body: IProductAttribute) =>
    this.instance.post('experiences/productattributes', body)

  public updateExperience = (body: IExperience) => this.instance.put('experiences', body)

  public updateExperienceSortedImages = (body: IExperienceSortImages) =>
    this.instance.put('experiences/sortimages', body)

  public getExperienceTags = () => this.instance.get<Array<IExperienceTag>>(`experienceTags`)

  public uploadExperienceMediaItem = (body: IEntityMediaItemUpload) => {
    const formData = new FormData()
    formData.append('ExperienceImage', body.MediaFile)
    formData.append('MediaType', body.MediaType.toString())
    formData.append('ExperienceId', body.Id)

    return this.uploadEntityMediaItem('experiences/attachimagetoexistingexperience', formData, body)
  }

  public removeExperienceMediaItem = (body: IExperienceMediaItemDelete) =>
    this.instance.post<string>('experiences/removeimagefromexistingexperience', body)

  public getExperienceAttributes = () => this.instance.get('experienceattributes')

  public createExperienceAttributeMapping = (body: ICreateExperienceAttributeMapping) =>
    this.instance.post('ExperienceAttributeMappings', body)

  public createExperienceAttributeValues = (body: ICreateExperienceAttributeValues) =>
    this.instance.post('ExperienceAttributeMappings/UpdateValues', body)

  public getExperienceProductTypeFullTree = () =>
    this.instance.get<Array<IProductTypeTree>>(`experiences/producttypes/firstlevel`)

  public getExperienceProductTypeTreeFirstLevel = () =>
    this.instance.get<Array<IProductType>>(`experiences/producttypes/firstlevel`)

  public getExperienceProductTypeTreeChildLevel = (id: string) =>
    this.instance.get<Array<IProductType>>(`experiences/producttypes/childlevel/${id}`)

  public getExperienceProductTypes = () =>
    this.instance.get<Array<IProductType>>(`experiences/producttypes`)

  public getExperienceProductType = (id: string) =>
    this.instance.get<IProductType>(`experiences/producttypes/${id}`)

  public createExperienceProductType = (body: IProductType) =>
    this.instance.post<ILoginResponse>('experiences/producttypes', body)

  public updateExperienceProductType = (body: IProductType) =>
    this.instance.put<ILoginResponse>('experiences/producttypes', body)

  public deleteExperienceProductType = (id: string) =>
    this.instance.delete(`experiences/producttypes/${id}`)

  public getPaymentSettings = () => this.instance.get<IPaymentSettings>(`tenants/settings/payments`)

  public savePaymentSettings = (body: IPaymentSettings) =>
    this.instance.post(`tenants/settings/payments`, body)

  public testPayment = (body: IStripePayment) =>
    this.instance.post(`payments/stripe-checkout-session`, body)

  public getBusinessOpeningHoursSettings = () =>
    this.instance.get<IBusinessOpeningHours>(`tenants/settings/openinghours`)

  public saveBusinessOpeningHoursSettings = (body: IBusinessOpeningHours) =>
    this.instance.post(`tenants/settings/openinghours`, body)

  // System Types
  public getSystemProductTypes = (): Promise<AxiosResponse<Array<IProductTypeTree>>> =>
    this.instance.get<Array<IProductTypeTree>>(`systemtypes/producttypes`)

  public getSystemExperienceTypes = (): Promise<AxiosResponse<Array<IProductTypeTree>>> =>
    this.instance.get<Array<IProductTypeTree>>(`systemtypes/eventtypes`)

  // Venues
  public getVenues = () => this.instance.get<Array<IVenue>>(`venues`)

  public getVenue = (id: string) => this.instance.get<IVenue>(`venues/${id}`)

  public createVenue = (body: IVenue) => this.instance.post<IVenue>('venues', body)

  public updateVenue = (body: IVenue) => this.instance.put<IVenue>('venues', body)

  public deleteVenue = (id: string) => this.instance.delete(`venues/${id}`)

  // Product Inventory
  public saveProductInventory = (body: IProductInventory) =>
    this.instance.post<IProductInventory>('products/upsertInventory', body)

  private uploadEntityMediaItem = (
    endpoint: string,
    formData: FormData,
    body: IEntityMediaItemUpload,
    onUploadProgress?: (e: ProgressEvent) => void
  ) => {
    return this.instance.post<IMediaItemUploadResponse>(endpoint, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  }
}

export const hourMarketApi = new HourMarketApi(hourMarketApiConfig)
