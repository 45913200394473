import { ICategory, ICategoryTree, IProductDetails } from '../../../models/interface'
import React, { FC } from 'react'
import CategoryItem from './CategoryItem'

type CategoriesPanelProps = {
  product: IProductDetails
  categories: ICategoryTree[]
}

const CategoriesPanel: FC<CategoriesPanelProps> = ({ product, categories }) => {
  return (
    <>
      {categories
        ?.sort(function (a, b) {
          return a.displayOrder !== undefined && b.displayOrder !== undefined && a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((category: ICategory) => {
          return <CategoryItem key={category.id} product={product} category={category} />
        })}
    </>
  )
}

export default CategoriesPanel
