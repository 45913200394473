import { FC } from 'react'
import { IAddress } from '../models/interface'

interface Props {
  title?: string
  address: IAddress
}

const AddressLayout: FC<Props> = ({ title, address }) => {
  return (
    <div className="flex flex-col">
      {title && <div className="font-bold">{title}</div>}
      {address.address1 && <div className="font-medium">{address.address1}</div>}
      {address.address2 && <div className="font-medium">{address.address2}</div>}
      {address.city && <div className="font-medium">{address.city}</div>}
      {address.county && <div className="font-medium">{address.county}</div>}
      {address.zipPostalCode && <div className="font-medium">{address.zipPostalCode}</div>}
      {address.country && <div className="font-medium">{address.country}</div>}
    </div>
  )
}

export default AddressLayout
