import React from 'react'
import { IProduct } from '../../models/interface'
import { faImage } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { YesNoDialog } from '../../components/dialogs'
import ImageWithFallback from '../../components/ImageWithFallback'

interface IProductCard {
  product: IProduct
  deleteProduct: (id: string) => void
  showProductPreview: (id: string) => void
}

export const ProductCard: React.FC<IProductCard> = ({ product, deleteProduct, showProductPreview }) => {
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  const showPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    showProductPreview(product?.id!)
  }

  const yesAction = async () => {
    setShowDeleteModal(false)
    deleteProduct(product.id!)
  }

  return (
    <div className="flex items-center justify-between px-2 py-2">
      <YesNoDialog
        modalIsOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        yesAction={yesAction}
        noAction={() => setShowDeleteModal(false)}
        title="Delete Product"
        subtitle="Do you want to delete product"
        description={product?.name ?? ''}
      />
      <div className="flex items-center">
        {product.image ? (
          <ImageWithFallback fallbackImage={product.image} mainImage={product.thumbImage ?? ''} altText="" additionalCss="h-16 w-16 object-cover" />
        ) : (
          <div className="flex h-16 w-16 items-center justify-center">
            <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-200" />
          </div>
        )}
        <div className="ml-4 text-base text-gray-800 font-normal">{product.name}</div>
      </div>
      <div className="flex flex-col justify-center ml-4">
        <button
          onClick={showPreview}
          className="mt-2 z-10 text-pixie bg-white border border-pixie px-4 py-1 rounded-full font-normal text-sm hover:font-bold hover:bg-white">
          Preview
        </button>
      </div>
    </div>
  )
}
