import { CatalogType } from '../../../models/interface'
import CategoriesPage from '../../categories/categories.page'

const ExperienceCategoriesPage = () => (
  <CategoriesPage
    catalogType={CatalogType.Experience}
    title={'Event Collections'}
    addTitle={'Add Event Collection'}
    loadingTitle={'Loading event collections ...'}
  />
)

export default ExperienceCategoriesPage
