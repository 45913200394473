import React, { FC, MouseEventHandler } from 'react'
import CancelButton from '../../../components/CancelButton'
import SaveButton from '../../../components/SaveButton'

interface Props {
  saveText: string
  cancelIsDisabled: boolean
  isCancelling: boolean
  onCancel: () => void
  onSubmit: MouseEventHandler
}

const SaveCancelFooter: FC<Props> = ({
  saveText,
  onCancel,
  cancelIsDisabled,
  isCancelling,
  onSubmit,
}) => {
  return (
    <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
      <div className="flex space-x-4 justify-end">
        <CancelButton disabled={cancelIsDisabled} isCancelling={isCancelling} onCancel={onCancel} />
        <SaveButton
          disabled={false}
          isSubmitting={false}
          onSubmit={onSubmit}
          buttonText={saveText}
        />
      </div>
    </div>
  )
}

export default SaveCancelFooter
