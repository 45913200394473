import React, { FC } from 'react'
import SideMenuItem from './sideMenuItem'

interface Props {
  detailsText: string
  categoriesText: string
  imagesText: string
  productOptionsText: string
  productVariantsText: string
  productTypesText: string
  relatedProductsText: string
  inventoryText: string
  salesText: string
  detailsRef: string
  categoriesRef: string
  imagesRef: string
  productOptionsRef: string
  productVariantsRef: string
  productTypesRef: string
  relatedProductsRef: string
  inventoryRef: string
  salesRef: string
  onDetails: (e: any) => void
  onCategories: (e: any) => void
  onImages: (e: any) => void
  onProductOptions: (e: any) => void
  onProductVariants: (e: any) => void
  onProductTypes: (e: any) => void
  onRelatedProducts: (e: any) => void
  onInventory: (e: any) => void
  onSales: (e: any) => void
}

const SideMenuContainer: FC<Props> = ({
  detailsText,
  categoriesText,
  imagesText,
  productOptionsText,
  productVariantsText,
  productTypesText,
  relatedProductsText,
  inventoryText,
  salesText,
  detailsRef,
  categoriesRef,
  imagesRef,
  productOptionsRef,
  productVariantsRef,
  productTypesRef,
  relatedProductsRef,
  inventoryRef,
  salesRef,
  onDetails,
  onCategories,
  onImages,
  onProductOptions,
  onProductVariants,
  onProductTypes,
  onRelatedProducts,
  onInventory,
  onSales,
}) => {
  return (
    <div className="w-72">
      <div className="stickyMenu bg-white space-y-4  px-6 py-8 mr-4 mb-8 rounded-md shadow-sm">
        <SideMenuItem anchorRef={detailsRef} menuLabel={detailsText} onClicked={onDetails} />
        <SideMenuItem
          anchorRef={categoriesRef}
          menuLabel={categoriesText}
          onClicked={onCategories}
        />
        <SideMenuItem anchorRef={imagesRef} menuLabel={imagesText} onClicked={onImages} />
        <SideMenuItem
          anchorRef={productOptionsRef}
          menuLabel={productOptionsText}
          onClicked={onProductOptions}
        />
        <SideMenuItem
          anchorRef={productVariantsRef}
          menuLabel={productVariantsText}
          onClicked={onProductVariants}
        />
        <SideMenuItem
          anchorRef={productTypesRef}
          menuLabel={productTypesText}
          onClicked={onProductTypes}
        />
        <SideMenuItem anchorRef={inventoryRef} menuLabel={inventoryText} onClicked={onInventory} />
      </div>
    </div>
  )
}

export default SideMenuContainer
