import React from 'react'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

export const Home = () => {
  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="pb-6 px-4 flex-none max-w-7xl mx-auto mt-10">
          <h1 className="text-xl font-bold text-gray-900">HOME</h1>
          <div className="bg-white mt-5 p-8 rounded-md my-8 overflow-hidden">
            <h1 className="text-base font-bold text-gray-900">GENERAL</h1>
            <div className="grid grid-cols-4 gap-4 mt-2">
              <StatsCard title="Various" value="56" />
              {/*<HomeCard title="Business Settings" subtitle="General business settings" />*/}
            </div>
            <h1 className="text-base font-bold text-gray-900 mt-10">CATALOG</h1>
            <div className="grid grid-cols-4 gap-4 mt-2">
              {/*<HomeCard title="Click & Collect" subtitle="" />*/}
              {/*<HomeCard title="Local Delivery" subtitle="" action="LocalDelivery" />*/}
              {/*<HomeCard title="National Delivery" subtitle="" />*/}
              <StatsCard title="Products" value="56" />
              <StatsCard title="Collections" value="4" />
              <StatsCard title="Product Options" value="6" />
            </div>
            <h1 className="text-base font-bold text-gray-900 mt-10">FULFILLMENT</h1>
            <div className="grid grid-cols-4 gap-4 mt-2">
              <StatsCard title="Orders" value="123" />
            </div>
            <h1 className="text-base font-bold text-gray-900 mt-10">EVENTS</h1>
            <div className="grid grid-cols-4 gap-4 mt-2">
              <StatsCard title="Events" value="12" />
              {/*<HomeCard title="Business Settings" subtitle="General business settings" />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// eslint-disable-next-line no-unused-vars
const HomeCard = ({ title, subtitle, action }) => {
  const history = useHistory()

  const navigateTo = () => {
    history.push(`/${action}`)
  }

  return (
    <button onClick={navigateTo}>
      <div className="flex bg-white border-gray-200 border items-center rounded-md p-4">
        <FontAwesomeIcon icon={faHome} size="2x" className="mr-4 text-gray-300" />
        <div className="flex flex-col">
          <div className="text-pixie font-medium text-left">{title}</div>
          <div className="text-pixie font-light text-xs text-left">{subtitle}</div>
        </div>
      </div>
    </button>
  )
}

const StatsCard = ({ title, value, action }) => {
  const history = useHistory()

  const navigateTo = () => {
    history.push(`/${action}`)
  }

  return (
    <button onClick={navigateTo}>
      <div className="flex bg-white border-gray-200 border items-center rounded-md p-4 shadow">
        <div className="flex flex-col items-center w-full space-y-2">
          <div className="text-gray-500 font-medium text-center">{title}</div>
          <div className="text-gray-900 font-extrabold text-3xl text-center">{value}</div>
        </div>
      </div>
    </button>
  )
}
