import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import React from 'react'
import { InteractionType } from '@azure/msal-browser'
import ErrorComponent from './ErrorComponent'
import { loginRequest } from './authConfig'

const LoginB2CDev = () => {
  const msalInstance = useMsal()

  const getInfo = async () => {
    const accounts = msalInstance.instance.getAllAccounts()
    const activeAccount = msalInstance.instance.getActiveAccount()

    const request = {
      scopes: process.env.REACT_APP_ADB2C_LOGIN_SCOPES?.split(',') ?? [],
      account: activeAccount || accounts[0],
    }

    let tokenResponse = await msalInstance.instance.handleRedirectPromise()
    const myAccount = msalInstance.instance.getAccountByUsername('mjolk@pixieshop.com')

    msalInstance.instance
      .acquireTokenSilent(request)
      .then(function (response) {
      })
      .catch(function (error) {
      })
  }

  const loading = () => {
    return (
      <>
        <div>Loading ....</div>
      </>
    )
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={loading}>
      <div className="flex flex-col space-y-6 ">
        <div className="font-bold">Dev</div>
        <div className="font-bold">Logged In</div>
      </div>
    </MsalAuthenticationTemplate>
  )
}

export default LoginB2CDev
