import React, { FC } from 'react'
import { InputElement } from '../../components/form-elements/input-element'
import { useForm } from 'react-hook-form'
import ItemsPicker from '../general/ItemsPicker'
import ProductInventoryStrategy, {
  ProductInventoryStrategies,
} from '../../Types/Products/ProductInventoryTypes'

interface Props {
  inventoryStrategy: ProductInventoryStrategy
  inventoryStartQuantity: number
  inventoryQuantity: number
  inventoryAllocatedQuantity: number
  onSave: (
    inventoryStrategy: ProductInventoryStrategy,
    startQty: number,
    onHandQty: number,
    allocatedQty: number
  ) => void
  onCancel: () => void
}

const ProductInventoryLayout: FC<Props> = ({
  inventoryStrategy,
  inventoryStartQuantity,
  inventoryQuantity,
  inventoryAllocatedQuantity,
  onSave,
  onCancel,
}) => {
  const { register, handleSubmit, setValue } = useForm<Props>({
    defaultValues: {
      inventoryStrategy,
      inventoryStartQuantity,
      inventoryQuantity,
      inventoryAllocatedQuantity,
    },
  })

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onSave(
          data.inventoryStrategy,
          data.inventoryStartQuantity,
          data.inventoryQuantity,
          data.inventoryAllocatedQuantity
        )
      )}>
      <div className="">
        <ItemsPicker
          pickerItems={ProductInventoryStrategies}
          initialPickerItem={inventoryStrategy}
          OnPickerItemSelected={(inventoryStrategy) =>
            setValue('inventoryStrategy', inventoryStrategy)
          }
        />
        <div className="mt-4">
          <InputElement
            label={'Start Quantity'}
            id={'inventoryStartQuantity'}
            type={'number'}
            register={register('inventoryStartQuantity')}
            inputWidthCss="w-32"
          />
        </div>
        <div className="mt-2">
          <InputElement
            label={'On Hand Quantity'}
            id={'inventoryQuantity'}
            type={'number'}
            register={register('inventoryQuantity')}
            inputWidthCss="w-32"
          />
        </div>
        <div className="mt-2">
          <InputElement
            label={'Allocated Quantity'}
            id={'inventoryAllocatedQuantity'}
            type={'number'}
            register={register('inventoryAllocatedQuantity')}
            inputWidthCss="w-32"
          />
        </div>
        <div className="flex space-x-2 mt-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-1 bg-gray-500 text-gray-50 font-bold my-4 rounded-md text-sm">
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-1 bg-gray-500 text-gray-50 font-bold my-4 rounded-md text-sm">
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default ProductInventoryLayout
