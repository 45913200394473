import React, {FC, useCallback, useEffect, useState} from "react";
import {
  defaultExperience,
  IEntityMediaItemDelete,
  IEntityMediaItemUpload,
  IExperience,
  IExperienceMediaItemDelete, IMediaItem,
} from "../../models/interface";
import {useHistory, useLocation} from "react-router-dom";
import moment from "moment";
import { hourMarketApi } from "../../Utils/api/hourmarket-api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import ImageTool from "../../components/imagetool/image-tool.component";
import ExperienceExperienceAttributes from "./experience-attributes";
import {Controller, useForm} from "react-hook-form";
import SaveButton from "../../components/SaveButton";
import ExperienceAttendees from "./ExperienceAttendees";
import {InputLabelElement, InputElement} from "../../components/form-elements/input-element";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";
import CancelButton from "../../components/CancelButton";
import {LoadingIndicatorPanel} from "../../components/LoadingIndicatorPanel";

type ExperienceDetailsProps = {
  id: string;
  add: boolean;
};

const ExperienceDetails: FC = () => {
  const [experience, setExperience] = useState<IExperience>(defaultExperience);
  const [loading, setLoading] = useState(true);
  const location = useLocation<ExperienceDetailsProps>();
  const { id } = location.state;
  const { add } = location.state;
  const history = useHistory()

  const { register, handleSubmit, reset, formState, control, setValue, getValues } = useForm<IExperience>({defaultValues:defaultExperience})

  useEffect(() => {

    if (!add) {
      setLoading(true)
      hourMarketApi.getExperience(id)
          .then(r => {
            setExperience(r.data)
            reset(r.data)
          }).finally(() => setLoading(false))
    } else {
      setLoading(false)
    }

  }, [])


  function onSubmit(data: any) {
    console.log(data)
    if (add) {
      return hourMarketApi.createExperience(data).then(r => {
        history.goBack();
      })
    } else {
      return hourMarketApi.updateExperience(data).then(r => {
        history.goBack();
      })
    }
  }

  function onChangeStatus(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
  }

  const handleImageUpload = (uploadedFile: Blob) => {

    let mediaItemUpload: IEntityMediaItemUpload = {
      MediaType: 1,
      Title: "",
      Description: "",
      ContentType: "image/png",
      Filename: "",
      Id: experience.id ?? "",
      MediaFile: uploadedFile
    }

    hourMarketApi.uploadExperienceMediaItem(mediaItemUpload).then(r => {
      console.log(r);
    });
  };

  const handleImageDeleted = (mediaItemId: string) => {
    let mediaItemDelete: IExperienceMediaItemDelete = {
      ExperienceId: experience.id!,
      MediaItemId: mediaItemId
    }

    hourMarketApi.removeExperienceMediaItem(mediaItemDelete).then(r => {
      console.log(r);
    });
  }

  const disabled =  (!(formState.isDirty && Object.keys(formState.dirtyFields).length > 0 )) || formState.isSubmitting

  const onResortImages = (images: any) => {
    const sortedImages = images.map((image: IMediaItem, index: number) => {
      return {
        MediaItemId: image.id,
        DisplayOrder: index
      }
    })

    const updateExperienceSortedImages = {
      ExperienceId: id,
      SortedMediaItems: sortedImages
    }

    console.log("onResortImages")
    console.log(updateExperienceSortedImages)

    hourMarketApi.updateExperienceSortedImages(updateExperienceSortedImages)
        .then(r => console.log(r))
  }

  const mediaItems = () => {
    return experience.mediaItems
        ?.sort(function(a,b){ return (a.displayOrder > b.displayOrder) ? 1 : -1})
        .map(item => item)
  }

  return  (
      <div className="flex flex-col flex-grow w-full overflow-hidden">
        <div className="flex-1 overflow-y-scroll">
          { loading
              ? <LoadingIndicatorPanel message="Loading event ..." />
              : (
              <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md flex flex-col flex-grow overflow-hidden">
                <button onClick={history.goBack} className="mb-4 flex items-center focus:outline-none">
                  <FontAwesomeIcon
                      icon={faArrowLeft}
                      size="1x"
                      className="text-gray-500 cursor-pointer" />
                  <span className="ml-2 text-gray-500">Events</span>
                </button>
                <div className="p-4 rounded-md border bg-white">
                <form action="#" method="POST">
                  <div className="">
                    <div className="bg-white">
                      <div className="mt-10">
                        <div className="mt-6">
                          <InputLabelElement label={"Date"} id={"startDate"}/>
                          <Controller
                              control={control}
                              name="startDateTime"
                              render={({field: {onChange, value}}) => (
                                  <DatePicker
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      locale="en"
                                      selected={moment(value).toDate()}
                                      dateFormat="dd/MM/yyyy"
                                      onChange={(date: Date) => {
                                        onChange(() => {
                                          const endDate = getValues('endDateTime')
                                          const newDate = moment(date)
                                              .set({hour: moment(endDate).hour(), minute: moment(endDate).minute()})
                                              ?.format()
                                          setValue('endDateTime', newDate)
                                          setValue('startDateTime', moment(date).format())
                                        })
                                      }}/>
                              )}
                          />
                        </div>

                        <div className="mt-6">
                          <InputLabelElement label={"Start Time"} id={"startTime"}/>
                          <Controller
                              control={control}
                              name="startDateTime"
                              render={({field: {onChange, value}}) => (
                                  <DatePicker
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      locale="en"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      selected={moment(value).toDate()}
                                      timeIntervals={15}
                                      timeCaption="Start Time"
                                      dateFormat="h:mm aa"
                                      onChange={(date: Date) => {
                                        onChange(() => {
                                          const startDate = getValues("startDateTime")
                                          const newStartDate = moment(startDate)
                                              .set({hour: moment(date).hour(), minute: moment(date).minute()})
                                              ?.format()
                                          setValue('startDateTime', newStartDate)
                                        })
                                      }}/>
                              )}
                          />
                        </div>

                        <div className="mt-6">
                          <InputLabelElement label={"End Time"} id={"endTime"}/>
                          <Controller
                              control={control}
                              name="endDateTime"
                              render={({field: {onChange, value}}) => (
                                  <DatePicker
                                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                      locale="en"
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={15}
                                      selected={moment(value).toDate()}
                                      timeCaption="End Time"
                                      dateFormat="h:mm aa"
                                      onChange={(date: Date) => {
                                        onChange(() => {
                                          const startDate = getValues("startDateTime")
                                          const newStartDate = moment(startDate)
                                              .set({hour: moment(date).hour(), minute: moment(date).minute()})
                                              ?.format()
                                          setValue('endDateTime', newStartDate)
                                        })
                                      }}/>
                              )}
                          />
                        </div>

                        <InputElement label={"Title"} id={"title"} type={"text"} register={register("title")}/>
                        <InputElement label={"Sub Title"} id={"subTitle"} type={"text"} register={register("subTitle")}/>
                        <div className="w-full mt-6">
                          <InputLabelElement label={"Description"} id={"description"}/>
                          <Controller
                              control={control}
                              name="description"
                              render={({field: {onChange, value}}) => (
                                  <Editor
                                      onEditorStateChange={editorState => {
                                        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                      }}
                                      defaultContentState={convertToRaw(ContentState.createFromBlockArray(htmlToDraft(value ?? "").contentBlocks))}
                                      editorStyle={{
                                        border: "1px solid #cccccc",
                                        height: "15em",
                                        paddingTop: "0.5em",
                                        paddingBottom: "0.5em",
                                        paddingLeft: "1em",
                                        paddingRight: "1em",
                                      }}
                                  />
                              )}
                          />
                        </div>
                        <InputElement label={"Price"} id={"subTitle"} type={"number"} register={register("price")}
                                      inputWidthCss={"w-36"}/>
                        <InputElement label={"Number of Tickets"} id={"maximumAttendeeCapacity"} type={"number"}
                                      register={register("maximumAttendeeCapacity")} inputWidthCss={"w-36"}/>
                        <div className="w-full mt-6">
                          <InputLabelElement label={"Event Type"} id={"eventType"}/>
                          <select
                              {...register("eventAttendanceMode")}
                              id="eventAttendanceMode"
                              className="mt-1 block w-48 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          >
                            <option value={"MixedEventAttendanceMode"}>Online & Offline Event</option>
                            <option value={"OfflineEventAttendanceMode"}>Offline Event</option>
                            <option value={"OnlineEventAttendanceMode"}>Online Event</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 pt-5">
                      <button
                          onClick={onChangeStatus}
                          className="bg-pixie h-8 w-36 mt-5 border border-transparent rounded-md shadow-sm py-1 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-pixie hover:bg-opacity-50 focus:outline-none">
                        Change Status
                      </button>
                    </div>
                  </div>
                </form>
              </div>
                <div className="mt-4 mb-10 max-w-7xl p-4 rounded-md border bg-white">
                <ImageTool onImageSelected={handleImageUpload} entityImages={mediaItems} onImageDeleted={handleImageDeleted} onResortImages={onResortImages} />
                </div>
                <div className="mt-4 max-w-7xl p-4 rounded-md border bg-white mb-10">
                <ExperienceExperienceAttributes experience={experience} />
                </div>
                <div className="mt-4 max-w-7xl p-4 rounded-md border bg-white mb-10">
                <ExperienceAttendees experience={experience} />
                </div>
              </div>
              )
          }
        </div>
        {/* Footer */}
        {!loading && <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
          <div className="flex space-x-4 justify-end">
            <CancelButton
                disabled={false}
                isCancelling={false}
                onCancel={history.goBack}
            />
            <SaveButton
                disabled={disabled}
                isSubmitting={formState.isSubmitting}
                onSubmit={handleSubmit(onSubmit)}
            />
          </div>
        </div>}
      </div>
  )
}

export default ExperienceDetails;
