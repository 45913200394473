import React, { FC } from 'react'
import classNames from 'classnames'
import { Controller } from 'react-hook-form'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { ContentState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { Control } from 'react-hook-form/dist/types/form'
import { CollectionLeadTimeData } from '../../pages/DeliveryAndCollection/clickandcollect/CollectionLeadTimeData'
import { IDropDownOptionItem } from '../../models/interface'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import clsx from 'clsx'

interface InputLabelElementProps {
  id: string
  label: string
  additionalCss?: string
}

interface InputElementProps {
  label: string
  type: string
  id: string
  register: any
  inputWidthCss?: string
}

interface InputProps {
  type: string
  id: string
  register: any
  additionalCss?: string
  placeHolder?: string
}

interface InputCheckBoxElementProps {
  label: string
  id: string
  register: any
  additionalCss?: string
  orientation?: 'portrait' | 'landscape'
}

interface InputNumberElementProps {
  label: string
  id: string
  register: any
  additionalInputCss?: string
}

interface InputHtmlElementProps<T> {
  label: string
  id: string
  name: string
  control: Control<T>
  register: any
  additionalInputCss?: string
}

interface InputTextElementProps {
  label: string
  id: string
  register: any
  rows: number
  inputCss?: string
}

interface InputSelectElementProps {
  label: string
  id: string
  name: string
  register: any
  optionData: IDropDownOptionItem[]
  inputCss?: string
}

const InputLabelElement: FC<InputLabelElementProps> = ({ label, id, additionalCss }) => {
  return (
    <label htmlFor={id} className={clsx('block text-gray-600 text-sm font-medium', additionalCss)}>
      {label}
    </label>
  )
}

const Input: FC<InputProps> = ({ type, id, register, additionalCss, placeHolder }) => {
  return (
    <input
      type={type}
      id={id}
      placeholder={placeHolder}
      {...register}
      className={classNames(
        additionalCss ? additionalCss : 'w-full',
        'mt-1 block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm'
      )}
    />
  )
}

const InputElement: FC<InputElementProps> = ({ label, type, id, register, inputWidthCss }) => {
  return (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <input
        type={type}
        id={id}
        {...register}
        className={classNames(
          inputWidthCss ? inputWidthCss : 'w-full',
          'mt-1 block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-400 focus:border-gray-400 sm:text-sm'
        )}
      />
    </div>
  )
}

const InputCheckBoxElement: FC<InputCheckBoxElementProps> = ({
  label,
  id,
  register,
  orientation = 'portrait',
  additionalCss,
}) => {
  return orientation === 'landscape' ? (
    <div className="flex mt-6 items-center space-x-2">
      <input
        type="checkbox"
        id={id}
        {...register}
        className={classNames(
          'h-4 w-4 text-pixie focus:outline-none',
          additionalCss ? additionalCss : ''
        )}
      />
      <InputLabelElement label={label} id={id} />
    </div>
  ) : (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <input
        type="checkbox"
        id={id}
        {...register}
        className={classNames(
          'h-4 w-4 text-pixie focus:outline-none',
          additionalCss ? additionalCss : ''
        )}
      />
    </div>
  )
}

const InputNumberElement: FC<InputNumberElementProps> = ({
  label,
  id,
  register,
  additionalInputCss,
}) => {
  return (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <input
        type="number"
        pattern="[0-9]{0,5}"
        id={id}
        {...register}
        className={classNames(
          'block w-20 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md',
          additionalInputCss ? additionalInputCss : ''
        )}
      />
    </div>
  )
}

const InputHtmlElement: FC<InputHtmlElementProps<any>> = ({
  label,
  id,
  name,
  control,
  register,
  additionalInputCss,
}) => {
  return (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <div className="text-gray-900  z-[110]">
            <div className={'relative top-0 left-0'}>
              <Editor
                onEditorStateChange={(editorState) => {
                  onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                }}
                defaultContentState={convertToRaw(
                  ContentState.createFromBlockArray(htmlToDraft(value ?? '').contentBlocks)
                )}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                editorStyle={{
                  border: '1px solid #cccccc',
                  height: '15em',
                  paddingTop: '0.5em',
                  paddingBottom: '0.5em',
                  paddingLeft: '1em',
                  paddingRight: '1em',
                }}
                toolbar={{
                  options: ['inline', 'list', 'textAlign', 'history'],
                }}
              />
            </div>
          </div>
        )}
      />
    </div>
  )
}

const InputTextElement: FC<InputTextElementProps> = ({ label, id, register, rows, inputCss }) => {
  return (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <textarea
        id={id}
        rows={rows}
        {...register}
        className={classNames(
          'shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-96 h-36 sm:text-sm border-gray-300 rounded-md',
          inputCss ? inputCss : 'w-full'
        )}
        defaultValue={''}
      />
    </div>
  )
}

const InputSelectElement: FC<InputSelectElementProps> = ({
  label,
  id,
  name,
  register,
  optionData,
  inputCss,
}) => {
  return (
    <div className="mt-6">
      <InputLabelElement label={label} id={id} />
      <select
        id={id}
        {...register}
        name={name}
        className="mt-1 block w-36 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
        {CollectionLeadTimeData.map((item) => {
          return <option value={item.value}>{item.label}</option>
        })}
      </select>
    </div>
  )
}

export {
  Input,
  InputLabelElement,
  InputElement,
  InputCheckBoxElement,
  InputNumberElement,
  InputHtmlElement,
  InputTextElement,
  InputSelectElement,
}
