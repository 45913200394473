import React, { useEffect, useState } from 'react'
import { hourMarketApi } from '../../../Utils/api/hourmarket-api'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { SlotsDisplay } from '../slotsDisplay'
import DeliveryArea from './DeliveryArea'
import { defaultDeliverySettings, IDeliverySettings } from '../../../models/interface'
import { useForm } from 'react-hook-form'
import SaveButton from '../../../components/SaveButton'
import LogisticsSlotsComponent from '../../../components/logistics/UI/LogisticsSlotsComponent'

const LocalDelivery = () => {
  const [tabType, setTabType] = useState('settings')

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md">
          {/*<div className="max-w-7xl m-10 ml-16 max-w-7xl">*/}
          <h1 className="text-xl font-bold text-gray-900 mb-6">Local Delivery</h1>
          <div className="p-4 bg-white rounded-md">
            <LocalDeliverySettings />

            <>
              <div className="p-4 bg-white rounded-md mt-10">
                <h1 className="text-xl font-bold text-gray-900 mb-2">Delivery Slots</h1>
                <LogisticsSlotsComponent ScheduleType="Delivery" />
              </div>
              <div className="p-4 bg-white rounded-md mt-10">
                <h1 className="text-xl font-bold text-gray-900 mb-2">Delivery Slots Template</h1>
                <LogisticsSlotsComponent ScheduleType="DeliveryTemplate" />
              </div>
            </>
            <div className={classNames('focus:outline-none text-gray-900 hover:text-gray-500 whitespace-nowrap py-2 px-1 border-b-4 font-medium text-sm')}>
              Delivery Area
            </div>
            <DeliveryArea />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocalDelivery

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const LocalDeliverySettings = () => {
  const [loading, setLoading] = useState(true)
  const { register, handleSubmit, reset, formState } = useForm<IDeliverySettings>({ defaultValues: defaultDeliverySettings })

  useEffect(() => {
    getDeliverySettings()
  }, [])

  const getDeliverySettings = () => {
    setLoading(true)
    hourMarketApi
      .getDeliverySettings()
      .then((r) => {
        if (r.status === 200) {
          reset(r.data)
        }
      })
      .finally(() => setLoading(false))
  }

  const onSubmit = (data: any) => {
    return hourMarketApi.saveDeliverySettings(data).then(() => reset(data))
  }

  const disabled = !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  return loading ? (
    <LoadingIndicator size={'small'} fill={true} />
  ) : (
    <div>
      <div className="flex space-x-2 items-start items-end mt-3">
        <div className={'w-1/4 text-gray-500 font-normal'}>We offer a local delivery service</div>
        <input type="checkbox" className="h-4 w-4 text-pixie focus:outline-none" id="offerLocalDelivery" {...register('offerLocalDelivery')} />
      </div>

      <div className="flex space-x-2 items-start items-end mt-3">
        <div className={'w-1/4 text-gray-500 font-normal'}>We offer local delivery times</div>
        <input type="checkbox" className="h-4 w-4 text-pixie focus:outline-none" id="offerLocalDeliverySlots" {...register('offerLocalDeliverySlots')} />
      </div>

      <div className="flex space-x-2 items-center mt-3">
        <div className={'text-gray-500 w-1/4'}>Delivery lead time</div>
        <div className="col-span-4 sm:col-span-2">
          <select
            {...register('localDeliveryLeadTimeInMins')}
            id="localDeliveryLeadTimeInMins"
            name="localDeliveryLeadTimeInMins"
            className="mt-1 block w-36 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm">
            <option value={15}>15 mins</option>
            <option value={30}>30 mins</option>
            <option value={60}>1 hr</option>
            <option value={120}>2 hrs</option>
            <option value={180}>3 hrs</option>
            <option value={240}>4 hrs</option>
            <option value={300}>5 hrs</option>
            <option value={360}>6 hrs</option>
            <option value={420}>7 hrs</option>
            <option value={480}>8 hrs</option>
            <option value={540}>9 hrs</option>
            <option value={1440}>24 hrs</option>
            <option value={2880}>48 hrs</option>
          </select>
        </div>
      </div>

      <div className="flex space-x-2 items-start mt-3">
        <div className={'w-1/4 text-gray-500'}>Delivery notes</div>
        <textarea
          id="localDeliveryNotes"
          rows={3}
          {...register('localDeliveryNotes')}
          className="shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-96 h-36 sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <div className="flex space-x-2 items-start mt-3">
        <div className={'w-1/4 text-gray-500'}>Minimum Order Value (£)</div>
        <input
          type="number"
          pattern="[0-9]{0,5}"
          id="localDeliveryMinAmount"
          {...register('localDeliveryMinAmount')}
          className="block w-36 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <div className="flex space-x-2 items-start mt-3">
        <div className={'w-1/4 text-gray-500'}>Delivery Charge (£)</div>
        <input
          type="number"
          pattern="[0-9]{0,5}"
          id="localDeliveryCharge"
          {...register('localDeliveryCharge')}
          className="block w-36 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <div className="flex space-x-2 items-start items-end mt-3">
        <div className={'w-1/4 text-gray-500 font-normal'}>Enable Delivery Free Above Value</div>
        <input
          type="checkbox"
          className="h-4 w-4 text-pixie focus:outline-none"
          id="localDeliveryOfferFreeAbove"
          {...register('localDeliveryOfferFreeAbove')}
        />
      </div>

      <div className="flex space-x-2 items-start mt-3">
        <div className={'w-1/4 text-gray-500'}>Delivery Free Above Value (£)</div>
        <input
          type="number"
          pattern="[0-9]{0,5}"
          id="localDeliveryFreeAbove"
          {...register('localDeliveryFreeAbove')}
          className="block w-36 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
        />
      </div>

      <SaveButton disabled={disabled} isSubmitting={formState.isSubmitting} onSubmit={handleSubmit(onSubmit)} />
    </div>
  )
}
