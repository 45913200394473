export const numberFormat = (value: number | undefined): string =>
{
    if (value !== undefined) {
        return new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP'
        }).format(value);
    };

    return "";
}
