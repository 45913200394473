import React from 'react'
import { IMediaItem } from '../../models/interface'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-duotone-svg-icons'
import moment from 'moment'

type MediaitemCardProps = {
  item: IMediaItem
}

const MediaitemCard: React.FC<MediaitemCardProps> = ({ item }) => {
  return (
    <div className="border-2 bg-white rounded-md m-2 hover:opacity-40 hover:border-pixie hover:border-2 overflow-clip">
      <div className="">
        {item.location ? (
          <img src={item.location} className="object-cover h-32 w-full" alt="" />
        ) : (
          <div className="flex h-16 w-16 items-center justify-center">
            <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-200" />
          </div>
        )}
        <div className="flex flex-col p-2">
          <div className={'font-semibold text-xs'}>Size</div>
          <div className="text-gray-800 text-xs">{item.size}</div>
          <div className={'font-semibold text-xs mt-2'}>Uploaded</div>
          <div className="text-gray-800 text-xs">
            {moment(item.created).format('DD/MM/YYYY HH:MM')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaitemCard
