import { CatalogType } from '../../../models/interface'
import ProductTypesPage from '../producttypes.page'
import { useLocation } from 'react-router-dom'

interface Props {
  parentProductTypeId?: string
  parentProductTypeTitle?: string
}

const EcommerceProductTypesPage = () => {
  const location = useLocation<Props>()
  const parentProductTypeId = location?.state?.parentProductTypeId
  const parentProductTypeTitle = location?.state?.parentProductTypeTitle

  return (
    <ProductTypesPage
      catalogType={CatalogType.Ecommerce}
      title={'Product Types'}
      addTitle={'Add Product Type'}
      loadingTitle={'Loading product types ...'}
      parentProductTypeId={parentProductTypeId}
      parentProductTypeTitle={parentProductTypeTitle}
    />
  )
}

export default EcommerceProductTypesPage
