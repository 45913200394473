import { CatalogType, ICategory } from '../../../models/interface'
import React, { FC } from 'react'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabelSmall } from '@pixieme/pixie-react-components'
import { useAppSelector } from '../../../redux/Hooks'
import { useDispatch } from 'react-redux'
import { CollectionsFilterItem } from './CollectionsFilterItem'
import useCollectionsTree from '../../../hooks/data/useCollectionsTree'

type Props = {
  title: string
  catalogType: CatalogType
}

const CollectionsFilterContainer: FC<Props> = ({ title, catalogType = CatalogType.Ecommerce }) => {
  const { filteredProductCategories } = useAppSelector(({ appReducer: reducer }) => reducer)
  const dispatch = useDispatch()
  const { data: categories, loading } = useCollectionsTree(catalogType)

  const categorySelected = (isSelected: boolean, categoryId: string) => {
    let updatedCategories: string[]

    if (isSelected) {
      updatedCategories = filteredProductCategories.concat(categoryId)
    } else {
      updatedCategories = filteredProductCategories.filter((item) => item !== categoryId)
    }

    dispatch({ type: 'filterProductByCategoriesChanged', filteredCategories: updatedCategories })
  }

  if (loading) {
    return (
      <div className="flex mt-2 items-center">
        <FontAwesomeIcon icon={faSpinner} color="dark" spin={true} className="mr-2" />
        <LabelSmall variant="dark" label="loading" />
      </div>
    )
  }

  return (
    <>
      {categories && categories.length > 0 && (
        <div className="text-base font-medium mt-6">{title}</div>
      )}
      {categories
        ?.sort(function (a, b) {
          return a.displayOrder !== undefined &&
            b.displayOrder !== undefined &&
            a.displayOrder > b.displayOrder
            ? 1
            : -1
        })
        .map((category: ICategory) => {
          return (
            <CollectionsFilterItem
              key={category.id}
              category={category}
              categorySelected={categorySelected}
              initialSelectedCategories={filteredProductCategories}
            />
          )
        })}
    </>
  )
}

export default CollectionsFilterContainer
