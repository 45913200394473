import React, { FC } from 'react'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { useVenues } from '../../hooks'
import { Link, useHistory } from 'react-router-dom'
import VenueCard from '../../layouts/Venues/VenueCard'

const VenuesScreen: FC = () => {
  const { data: venues, loading } = useVenues()
  const history = useHistory()

  const addVenue = () => {
    history.push('/venues/add', {
      add: true,
    })
  }

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden px-4">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl rounded-md flex flex-col flex-grow pb-8">
          {loading ? (
            <LoadingIndicatorPanel message={'Loading ....'} />
          ) : (
            <>
              <CrudListHeader headerLabel={'VENUES'} subheaderLabel="ADD" addAction={addVenue} />
              <div className="bg-white min-w-full divide-y flex-1 rounded-md shadow-sm overflow-hidden">
                {venues?.map((venue) => (
                  <div
                    key={venue.id}
                    className="bg-white cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                    <Link
                      to={{
                        pathname: `/venues/${venue.id}`,
                        state: { add: false },
                      }}>
                      <VenueCard key={venue.id} venue={venue} />
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default VenuesScreen
