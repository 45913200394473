import React, { Dispatch, FC, RefObject, useEffect, useRef, useState } from 'react'
import { InputElement, InputHtmlElement } from '../../../components/form-elements/input-element'
import SaveButton from '../../../components/SaveButton'
import {
  defaultTenantDetails,
  IBusinessOpeningHours,
  IClassification,
  IDeleteTenantMediaItem,
  IEntityMediaItemUpload,
  IMediaItem,
  IMediaItemUploadResponse,
  IPaymentSettings,
  ITenantDetails,
  ITenantSortImages,
  ITenantUpdate,
} from '../../../models/interface'
import { useForm } from 'react-hook-form'
import { hourMarketApi } from '../../../Utils/api/hourmarket-api'
import { LoadingIndicatorPanel } from '../../../components/LoadingIndicatorPanel'
import CancelButton from '../../../components/CancelButton'
import { useHistory } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import ImageUpload from '../../../components/imagetool/imageUpload'
import OpeningHours from './OpeningHours'
import { useImmerReducer } from 'use-immer'
import {
  BusinessDetailsActions,
  BusinessDetailsReducer,
  BusinessDetailsState,
} from './BusinessDetailsReducer'
import BusinessClassification from './BusinessClassification'
import BusinessPaymentSettings from './BusinessPaymentSettings'

const BusinessDetailsStateContext = React.createContext<BusinessDetailsState>(
  {} as BusinessDetailsState
)
const BusinessDetailsDispatchContext = React.createContext<Dispatch<BusinessDetailsActions>>(
  {} as Dispatch<BusinessDetailsActions>
)

const BusinessSettings: FC = () => {
  const [loading, setLoading] = useState(true)
  const logoUploadRef = useRef<HTMLInputElement>()
  const imageGalleryUploadRef = useRef<HTMLInputElement>()
  const { register, handleSubmit, reset, formState, control, setValue, getValues } =
    useForm<ITenantDetails>()
  const history = useHistory()

  const [state, dispatch] = useImmerReducer(BusinessDetailsReducer, {
    isLoading: false,
    businessDetails: defaultTenantDetails,
    classifications: [],
    setValue: setValue,
    formLoaded: false,
    openingHours: {} as IBusinessOpeningHours,
  })

  useEffect(() => {
    setLoading(true)

    async function fetchData() {
      const [r1, r2, r3, r4]: [
        AxiosResponse<ITenantDetails>,
        AxiosResponse<IClassification[]>,
        AxiosResponse<IPaymentSettings>,
        AxiosResponse<IBusinessOpeningHours>
      ] = await Promise.all([
        hourMarketApi.getTenant(),
        hourMarketApi.getTenantClassifications(),
        hourMarketApi.getPaymentSettings(),
        hourMarketApi.getBusinessOpeningHoursSettings(),
      ])
      return {
        tenantDetails: r1.data,
        classifications: r2.data,
        paymentSettings: r3.data,
        openingHours: r4.data,
      }
    }

    fetchData()
      .then((result) => {
        console.log('tenantDetails', result.tenantDetails)
        dispatch({ type: 'getClassifications', data: result.classifications })
        dispatch({ type: 'getBusinessDetails', data: result.tenantDetails })
        dispatch({ type: 'getPaymentSettings', data: result.paymentSettings })
        dispatch({ type: 'getOpeningHoursSettings', data: result.openingHours })
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    reset(state.businessDetails)
  }, [state.formLoaded])

  const onSubmit = async (data: any) => {
    const updatedTenant: ITenantUpdate = {
      Name: data.name,
      Title: data.title,
      Description: data.description,
      Category1: data.category1,
      Category2: data.category2,
      Category3: data.category3,
    }

    return await Promise.all([
      hourMarketApi.updateTenant(updatedTenant),
    ]).then(() => reset(data))
  }

  const onImageUpload = async (
    uploadedFile: File,
    onUploadProgress?: (e: ProgressEvent) => void
  ): Promise<AxiosResponse<IMediaItemUploadResponse>> => {
    console.log('onImageUpload', uploadedFile)
    let mediaItemUpload: IEntityMediaItemUpload = {
      MediaType: 1,
      Title: '',
      Description: '',
      ContentType: 'image/png',
      Filename: uploadedFile.name,
      Id: '',
      MediaFile: uploadedFile,
    }
    return hourMarketApi.uploadTenantMediaItem(mediaItemUpload, onUploadProgress)
  }

  const onImageDeleted = (mediaItemId: string) => {
    console.log('onImageDeleted', mediaItemId)
    let mediaItemDelete: IDeleteTenantMediaItem = {
      MediaItemId: mediaItemId,
    }

    hourMarketApi.deleteTenantMediaItem(mediaItemDelete).then((r) => {
      console.log(r)
    })
  }

  const onResortImages = (images: any) => {
    const sortedImages = images.map((image: IMediaItem, index: number) => {
      return {
        MediaItemId: image.id,
        DisplayOrder: index,
      }
    })

    const updateTenantSortedImages: ITenantSortImages = {
      SortedMediaItems: sortedImages,
    }

    console.log('onResortImages', updateTenantSortedImages)

    hourMarketApi.updateTenantSortedImages(updateTenantSortedImages).then((r) => console.log(r))
  }

  //const disabled = (!(formState.isDirty && Object.keys(formState.dirtyFields).length > 0 )) || formState.isSubmitting
  const disabled = !formState.isDirty || formState.isSubmitting

  const detailsRef = useRef<HTMLInputElement>(null)
  const classificationRef = useRef<HTMLInputElement>(null)
  const logoRef = useRef<HTMLInputElement>(null)
  const imageGalleryRef = useRef<HTMLInputElement>(null)
  const openingHoursRef = useRef<HTMLInputElement>(null)
  const paymentSettingsRef = useRef<HTMLInputElement>(null)
  const handleSideMenuClick = (e: any, ref: RefObject<HTMLInputElement>) => {
    e.preventDefault()

    ref?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  }

  return (
    <BusinessDetailsDispatchContext.Provider value={dispatch}>
      <BusinessDetailsStateContext.Provider value={state}>
        <div className="flex flex-col flex-grow w-full overflow-hidden">
          <div className="flex-1 overflow-y-scroll">
            {loading ? (
              <LoadingIndicatorPanel message="Loading business info ..." />
            ) : (
              <div className="mx-auto mt-8 max-w-7xl flex">
                {/* Side Menu */}
                <div className="w-72">
                  <div className="stickyMenu bg-white space-y-4  px-6 py-8 mx-4 mb-8 rounded-md shadow-sm">
                    <div className="hover:font-semibold">
                      <a href="#details" onClick={(e) => handleSideMenuClick(e, detailsRef)}>
                        Details
                      </a>
                    </div>
                    <div className="hover:font-semibold">
                      <a href="#logo" onClick={(e) => handleSideMenuClick(e, logoRef)}>
                        Logo
                      </a>
                    </div>
                    <div className="hover:font-semibold">
                      <a
                        href="#imageGallery"
                        onClick={(e) => handleSideMenuClick(e, imageGalleryRef)}>
                        Image Gallery
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-grow w-full overflow-hidden">
                  {/* Details */}
                  <div ref={detailsRef} className="p-8 rounded-md shadow-sm bg-white">
                    <div className="font-bold text-gray-800/90 mb-2">DETAILS</div>
                    <form action="#" method="POST">
                      <InputElement
                        label={'Business Name'}
                        id={'name'}
                        type={'text'}
                        register={register('name')}
                      />
                      <InputElement
                        label={'Business Tag Line'}
                        id={'title'}
                        type={'text'}
                        register={register('title')}
                      />
                      <InputHtmlElement
                        label={'Description'}
                        id={'description'}
                        name="description"
                        control={control}
                        register={register('description')}
                      />
                    </form>
                  </div>
                  {/* Logo */}
                  <div ref={logoRef} className="mt-8 p-8 rounded-md shadow-sm bg-white">
                    <div className="font-bold text-gray-800/90 mb-4">LOGO</div>
                    <ImageUpload
                      title={'Drop image or click to upload image'}
                      multiple={false}
                      setref={logoUploadRef}
                      mediaItems={state.businessDetails.logo ? [state.businessDetails.logo] : []}
                    />
                  </div>
                  {/* Image Gallery */}
                  <div ref={imageGalleryRef} className="mt-8 p-8 rounded-md shadow-sm bg-white">
                    <div className="font-bold text-gray-800/90 mb-4">IMAGE GALLERY</div>
                    <ImageUpload
                      title={'Drop image or click to upload image'}
                      multiple={true}
                      setref={imageGalleryUploadRef}
                      mediaItems={state.businessDetails.mediaItems ?? []}
                      onImageUpload={onImageUpload}
                      onImageDeleted={onImageDeleted}
                      onResortImages={onResortImages}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Footer */}
          {!loading && (
            <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
              <div className="flex space-x-4 justify-end">
                <CancelButton
                  disabled={false}
                  isCancelling={false}
                  onCancel={() => history.push('/home')}
                />
                <SaveButton
                  disabled={disabled}
                  isSubmitting={formState.isSubmitting}
                  onSubmit={handleSubmit(onSubmit)}
                  buttonText="Save"
                />
              </div>
            </div>
          )}
        </div>
      </BusinessDetailsStateContext.Provider>
    </BusinessDetailsDispatchContext.Provider>
  )
}

export default BusinessSettings

export { BusinessDetailsDispatchContext, BusinessDetailsStateContext }
