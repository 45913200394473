import {
    defaultTenantDetails, IBusinessOpeningHourLine, IBusinessOpeningHours,
    IClassification, IPaymentSettings,
    ITenantDetails
} from "../../../models/interface";
import {UseFormSetValue} from "react-hook-form/dist/types/form";
import {setNestedObjectValues} from "formik";

const BusinessDetailsReducer = (state: BusinessDetailsState, action: BusinessDetailsActions) => {
    switch (action.type) {
        case 'reset':
            //console.log("BusinessDetailsReducer - reset", state)
            state = {...BusinessDetailsReducerInitialState};
            return
        case 'getBusinessDetails':
            //console.log("BusinessDetailsReducer - getTenantDetails", state)
            state.businessDetails = action.data
            return
        case 'getClassifications':
            //console.log("BusinessDetailsReducer - getClassifications", state)
            state.classifications = action.data
            return
        case 'getPaymentSettings':
            //console.log("BusinessDetailsReducer - getTenantDetails", state)
            state.businessDetails = {...state.businessDetails, paymentSettings: action.data}
            return
        case 'getOpeningHoursSettings':
            //console.log("BusinessDetailsReducer - getTenantDetails", state)
            state.businessDetails = {...state.businessDetails, openingHours: action.data}
            state.formLoaded = true
            return
        case 'updateOpeningHourLine':
            console.log("BusinessDetailsReducer - updateOpeningHourLine", action.data.dayNumber, action.data.open)
            const index = state.businessDetails.openingHours?.days.findIndex(item => item.dayNumber === action.data.dayNumber)
            if (index !== undefined && index !== -1) {
                console.log("BusinessDetailsReducer - updateOpeningHourLine index", index, state.businessDetails?.openingHours?.days[index])
                state.businessDetails!.openingHours!.days[index] = action.data
                state.setValue("name", state.businessDetails.name, { shouldDirty: true})
            }
            return
        case 'formLoaded':
            console.log("BusinessDetailsReducer - formLoaded", state)
            state.formLoaded = action.data
            return
        default:
            return
    }
}

export type BusinessDetailsState = {
    isLoading: boolean,
    formLoaded: boolean,
    businessDetails: ITenantDetails,
    classifications: IClassification[],
    openingHours: IBusinessOpeningHours,
    setValue: UseFormSetValue<ITenantDetails>,
}

export type BusinessDetailsActions =
    | { type: 'reset' }
    | { type: 'getBusinessDetails', data: ITenantDetails }
    | { type: 'getPaymentSettings', data: IPaymentSettings }
    | { type: 'getOpeningHoursSettings', data: IBusinessOpeningHours }
    | { type: 'updateOpeningHourLine', data: IBusinessOpeningHourLine }
    | { type: 'getClassifications', data: IClassification[] }
    | { type: 'formLoaded', data: boolean }

const BusinessDetailsReducerInitialState: BusinessDetailsState = {
  isLoading: false,
  formLoaded: false,
  businessDetails: defaultTenantDetails,
  classifications: [],
  openingHours: {} as IBusinessOpeningHours,
  setValue: (name) => {}
}

export {
    BusinessDetailsReducerInitialState,
    BusinessDetailsReducer
}
