import axios, { AxiosInstance } from 'axios'
import LocalStorageService from '../localstorageservice'
import { acquireAccessToken } from '../../index'

abstract class HttpClient {
  protected readonly instance: AxiosInstance

  protected constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    })

    this.instance.interceptors.request.use(
      async (config) => {
        const accessToken = await acquireAccessToken()
        //const token = LocalStorageService.getAccessToken();
        if (accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken
        }
        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )
  }
}

export default HttpClient
