import React, { useState, useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBallPile } from '@fortawesome/pro-duotone-svg-icons'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'
import { faCloudUpload } from '@fortawesome/pro-duotone-svg-icons'
import { faImage } from '@fortawesome/pro-duotone-svg-icons'
import ZoomSlider from './zoomslider.component'
import Cropper from 'react-easy-crop'
import Dropzone from 'react-dropzone'
import { getCroppedImg, getCroppedImgAsBlob } from './crop-image-utils'
import { uploadImage } from './image-upload-service'
import FocalPointPicker from './FocalPointPicker'
import { clamp } from './helpers'
import { Placeholder } from 'react-select/animated'

export const ImageEditor = () => {
  const [focalPoint, setFocalPoint] = useState({ x: 50, y: 50 })
  const [image, setImage] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)

  // Cropping Logic
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [croppedImage, setCroppedImage] = useState(null)

  const onCropCompleteAreaChanged = useCallback(
    async (croppedArea, croppedAreaPixels) => {
      console.log('onCropCompleteAreaChanged')
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [croppedAreaPixels, rotation]
  )

  const onCropComplete = useCallback(
    async (croppedArea, croppedAreaPixels2) => {
      console.log('onCropComplete')
      const croppedImage = await getCroppedImg(image, croppedAreaPixels2, rotation)
      console.log('showCroppedImage2 done', { croppedImage })
      setCroppedImage(croppedImage)
    },
    [croppedAreaPixels]
  )

  // Drop Zone logic.
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log(acceptedFiles)
    console.log(acceptedFiles[0])
    setUploadedFile(acceptedFiles[0])
    setImage(URL.createObjectURL(acceptedFiles[0]))

    // const reader = new FileReader()
    // reader.onload = () => {
    //     // Do whatever you want with the file contents
    //       const binaryStr = reader.result
    //       console.log(binaryStr)
    //     }
    // reader.readAsArrayBuffer(acceptedFiles[0])
  }, [])

  const onClick = () => {
    setImage(null)
  }

  const onClickCrop = () => {
    console.log('onClickUpload')
    showCroppedImage()
  }

  const onClickUpload = () => {
    if (croppedImage) {
      uploadCroppedImage(uploadedFile.name, croppedImage)
    }
  }

  const uploadCroppedImage = async () => {
    try {
      const blob = await getCroppedImgAsBlob(image, croppedAreaPixels, rotation)
      console.log('blob done', { blob })
      uploadImage(uploadedFile.name, blob)
    } catch (e) {
      console.error(e)
    }
  }

  const onZoomChange = (value) => {
    setZoom(value)
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation)
      console.log('showCroppedImage done', { croppedImage })
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  return (
    <div className="w-full bg-gray-100 pb-8 overflow-scroll">
      <div className="flex mt-5 overflow-visible">
        <div className="flex flex-col w-96">
          <div className="ml-10">
            <div className="flex items-center space-x-2 mb-4">
              <FontAwesomeIcon icon={faBallPile} size="1x" className="text-primary" />
              <div className="font-extrabold text-lg text-gray-800 uppercase">Image Editor</div>
            </div>
            <div className="w-80 bg-white border-2 shadow-lg p-4">
              <div className="text-lg mb-2">Layout Guide</div>
              <div className="text-sm text-gray-500 mb-2">Upload your image to optimise at different sizes.</div>
              <ImageUploader image={image} uploadedFile={uploadedFile} onClick={onClick} onDrop={onDrop} />
              <br className="mt-2" />
              <div className="text-lg mb-2">Zoom & Crop</div>
              <div className="text-sm text-gray-500">Zoom and crop your image to capture what you want to show.</div>
              <CroppingImage
                image={image}
                crop={crop}
                setCrop={setCrop}
                rotation={rotation}
                setRotation={setRotation}
                zoom={zoom}
                aspect={4 / 3}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onCropCompleteArea={onCropCompleteAreaChanged}
                setZoom={setZoom}
              />
              <br className="mt-2" />
              <div className="flex space-x-3">
                <button
                  onClick={onClickCrop}
                  className="border border-gray-200 px-4 py-2 text-primary text-sm font-bold bg-gray-50 rounded-md mb-4 focus:outline-none hover:bg-gray-100">
                  Crop
                </button>
                <button
                  onClick={onClickUpload}
                  className="border border-gray-200 px-4 py-2 text-primary text-sm font-bold bg-gray-50 rounded-md mb-4 focus:outline-none hover:bg-gray-100">
                  Upload
                </button>
              </div>
              <ZoomSlider onChange={onZoomChange} zoom={zoom} />

              <br className="mt-2" />
              <div className="flex flex-col">
                <div className="text-lg mb-2">Focal Point</div>
                <div className="text-sm text-gray-500 mb-2">Set the focal point in your image which will be auto cropped around.</div>
                {/*<div className="font-semibold text-lg text-gray-800 mb-4">Focal Point</div>*/}
                {image ? (
                  <FocalPointPicker
                    className=""
                    focalPoint={focalPoint}
                    onChange={(fp) => setFocalPoint(fp)}
                    url={image}
                    //url="https://marathonshopping.blob.core.windows.net/images/fdbfb4e5-66ec-4981-ab1f-c3cc2c2be93f"
                  />
                ) : (
                  <div className="flex items-center justify-center h-48 bg-gray-50 border-4 border-gray-100">
                    <FontAwesomeIcon icon={faImage} size="4x" className="text-primary opacity-20" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-8 pr-12 ml-8">
          <ImagePanel image={croppedImage} width="50px" height="50px" title="Thumbnail" placeholderSize={'2x'} />
          <ImagePanel image={croppedImage} width="400px" height="300px" title="4x3" />
          <ImagePanel image={croppedImage} width="300px" height="400px" title="3x4" />
          <ImagePanel image={croppedImage} width="400px" height="400px" title="4x4" />
          <ImagePanel image={croppedImage} width="600px" height="100px" title="Landscape" />
        </div>
      </div>
    </div>
  )
}

const ImageUploader = ({ image, uploadedFile, onClick, onDrop }) => {
  return image ? <ImageUploadMainImage image={image} uploadedFile={uploadedFile} onClick={onClick} /> : <ImageUploadControl onDrop={onDrop} />
}

const ImageUploadMainImage = ({ image, uploadedFile, onClick }) => {
  return (
    <div className="flex flex-col">
      <div className="inline-block relative mt-2">
        <img className="block hover:opacity-70" src={image} alt="" />
        {/*<div className="absolute top-0 w-full h-full bg-gray-800 opacity-20"></div>*/}
        <button onClick={onClick} className="absolute top-3 right-3 cursor-pointer focus:outline-none">
          <FontAwesomeIcon icon={faTimesCircle} size="lg" style={{ '--fa-primary-color': 'transparent', '--fa-secondary-color': 'white' }} />
        </button>
      </div>
      <div className="p-2 bg-gray-100 mt-2 border border-gray-200">
        <div className="text-xs text-gray-500 font-semibold">Image Info</div>
        <div className="text-xs text-gray-500 mt-2">{uploadedFile.name}</div>
        <div className="text-xs text-gray-500">{uploadedFile.size}</div>
      </div>
    </div>
  )
}

const ImageUploadControl = ({ onDrop }) => {
  return (
    <div className="">
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className="flex flex-col items-center space-y-5 justify-center w-full h-48  border-4 border-gray-100 bg-gray-50 hover:bg-gray-100 hover:border-4 hover:border-gray-200 cursor-pointer focus:outline-none">
              <input {...getInputProps()} />
              <FontAwesomeIcon icon={faCloudUpload} size="3x" className="text-primary opacity-40" />
              <div className="text-xs text-gray-400">Drop files or click to upload files</div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

const ImagePanel = ({ image, title, width, height, placeholderSize = '4x' }) => {
  return (
    <div className="">
      <div className="font-semibold text-lg text-gray-800 mb-4">{title}</div>
      <div className="shadow-lg" style={{ width, height }}>
        {image ? <img className="object-cover w-full h-full" src={image} alt="" /> : <PlaceHolderImage width={width} height={height} size={placeholderSize} />}
      </div>
    </div>
  )
}

const CroppingImage = ({ image, crop, setCrop, rotation, setRotation, onCropComplete, onCropCompleteArea, zoom, setZoom }) => {
  return (
    <div className="mt-2">
      <div className="relative w-full h-48">
        {image ? (
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onCropAreaChange={onCropCompleteArea}
            onZoomChange={setZoom}
          />
        ) : (
          <div className="flex items-center justify-center h-48 bg-gray-50 border-4 border-gray-100">
            <FontAwesomeIcon icon={faImage} size="4x" className="text-primary opacity-20" />
          </div>
        )}
      </div>
    </div>
  )
}

const PlaceHolderImage = ({ width, height, size = '4x' }) => (
  <div className="flex items-center justify-center border-0 border-gray-100 bg-gray-50" style={{ width, height }}>
    <FontAwesomeIcon icon={faImage} size={size} className="text-primary opacity-20" />
  </div>
)

const EditorFooter = () => (
  <div className="bg-white h-8 text-sm text-gray-800 flex items-center pl-2">
    <FontAwesomeIcon icon={faBallPile} className="text-primary" />
    <span className="ml-2">Powered by HourMarket</span>
  </div>
)

/**
 * Get viewBox coordinates suitable for rendering an image inside an SVG.
 * In many ways, the viewBox approach is the inverse of the transformCoordinates approach:
 * The SVG viewBox acts as a frame: `left top width height`. You change the coordinates the
 * frame for which you want to focus on an image. To handle zoom, you simply shrink the frame
 * and adjust its coordinates.
 */
export function getViewBoxCoordinates({ inputDimensions, outputDimensions, focalPoint, zoom }) {
  // Find out, in natural pixels, the desired visual center of the original image
  const idealCenter = {
    x: inputDimensions.width * (focalPoint.x / 100),
    y: inputDimensions.height * (focalPoint.y / 100),
  }

  // Determine how much we need to scale the ouput image to fit the input constraints.
  const scale = Math.min(inputDimensions.width / outputDimensions.width, inputDimensions.height / outputDimensions.height)

  // Factor in both `scale` and `zoom` to find out how large our "frame" needs to be
  const width = (outputDimensions.width * scale) / zoom
  const height = (outputDimensions.height * scale) / zoom

  // Get the ideal top and left coordinates, and then turn them into realistic
  // coordinates so we don't run out of "image" to show.
  const idealTop = idealCenter.y - height / 2
  const idealLeft = idealCenter.x - width / 2

  const maxTop = inputDimensions.height - height
  const maxLeft = inputDimensions.width - width

  const top = clamp(idealTop, 0, maxTop)
  const left = clamp(idealLeft, 0, maxLeft)

  return {
    top: Math.floor(top),
    left: Math.floor(left),
    width: Math.floor(width),
    height: Math.floor(height),
  }
}
