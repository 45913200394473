import { IMediaItem, IProductDetails, IProductVariant } from '../../../models/interface'
import React, { Dispatch, FC, useContext, useState } from 'react'
import { ProductActions } from '../context/productReducer'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'
import ProductVariantLineImagePicker from './ProductVariantLineImagePicker'

type ProductVariantLinePanelProps = {
  product: IProductDetails
  productVariant: IProductVariant
}

export const ProductVariantLinePanel: FC<ProductVariantLinePanelProps> = ({
  product,
  productVariant,
}) => {
  let [showVariantImage, setShowVariantImage] = useState(false)
  const productOptionsDispatch: Dispatch<ProductActions> = useContext(ProductDispatchContext)
  const [productVariantToUpdate, setProductVariantToUpdate] = useState<IProductVariant | undefined>(
    productVariant
  )
  const productOptionsState = useContext(ProductStateContext)

  console.log('ProductVariantLinePanel', productVariant, showVariantImage)

  const onInputBlurred = () => {
    if (productVariantToUpdate !== undefined) {
      productOptionsDispatch({
        type: 'updateProductVariant',
        productVariant: productVariantToUpdate,
      })
    }
  }

  const onValueForPriceChanged = (e: any) => {
    const { value } = e.target
    if (productVariantToUpdate !== undefined) {
      setProductVariantToUpdate({ ...productVariantToUpdate, price: parseFloat(value) })
    }
  }

  const onValueForSalePriceChanged = (e: any) => {
    const { value } = e.target
    if (productVariantToUpdate !== undefined) {
      setProductVariantToUpdate({ ...productVariantToUpdate, salePrice: parseFloat(value) })
    }
  }

  const onValueForInStockChanged = (e: any) => {
    if (productVariantToUpdate !== undefined) {
      setProductVariantToUpdate({ ...productVariantToUpdate, inStock: e.target.checked })
    }
  }

  const onValueForSkuChanged = (e: any) => {
    if (productVariantToUpdate !== undefined) {
      setProductVariantToUpdate({ ...productVariantToUpdate, sku: e.target.value })
    }
  }

  const onValueForImageChanged = (mediaItem: IMediaItem) => {
    if (productVariantToUpdate !== undefined) {
      setProductVariantToUpdate({ ...productVariantToUpdate, mediaItem: mediaItem })
      productOptionsDispatch({
        type: 'updateProductVariant',
        productVariant: { ...productVariantToUpdate, mediaItem: mediaItem },
      })
    }
  }

  const onImagePickerClosed = () => {
    console.log('Closed', showVariantImage)
    setShowVariantImage(false)
  }

  return (
    <>
      <div className="flex items-center flex-nowrap space-x-4 px-2">
        <div className="flex-shrink-0 text-gray-800 font-normal w-32">
          {productVariant.displayOrder}
        </div>
        {/*<div className="flex-shrink-0 w-24">*/}
        {/*  <input*/}
        {/*    type="checkbox"*/}
        {/*    className="h-4 w-4 text-pixie focus:outline-none"*/}
        {/*    checked={productVariantToUpdate?.inStock}*/}
        {/*    onChange={onValueForInStockChanged}*/}
        {/*    onBlur={onInputBlurred}*/}
        {/*    id="inStock"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex-shrink-0 text-gray-800 font-normal w-96">{productVariant.name}</div>
        <div className="flex-shrink-0  w-64">
          <input
            type="number"
            pattern="[0-9,.\-]+"
            placeholder="Price"
            id="price"
            value={productVariantToUpdate?.price}
            onChange={onValueForPriceChanged}
            onBlur={onInputBlurred}
            className="block w-36 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="flex-shrink-0  w-64">
          <input
            type="number"
            pattern="[0-9]{0,5}"
            placeholder="Sale Price"
            id="saleprice"
            value={productVariantToUpdate?.salePrice}
            onChange={onValueForSalePriceChanged}
            onBlur={onInputBlurred}
            className="block w-36 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="flex-shrink-0 w-48">
          <input
            type="text"
            placeholder="SKU"
            id="sku"
            value={productVariantToUpdate?.sku}
            onChange={onValueForSkuChanged}
            onBlur={onInputBlurred}
            className="block w-48 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div className="flex-shrink-0 w-48">
          <button
            onClick={() => {
              setShowVariantImage(true)
            }}>
            <img
              src={
                productOptionsState.product?.variants.find((item) => item.id === productVariant.id)
                  ?.mediaItem?.location ??
                'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
              }
              className="h-16 w-16 hover:opacity-60 cursor-pointer border-2 border-gray-200 object-cover hover:border-2 hover:border-gray-400"
              alt="variant"
            />
          </button>
        </div>
      </div>
      <ProductVariantLineImagePicker
        showDialog={showVariantImage}
        onClosed={onImagePickerClosed}
        mediaItems={product?.mediaItems ?? []}
        OnImageSelected={onValueForImageChanged}
        productVariant={
          productOptionsState.product?.variants.find((item) => item.id === productVariant.id) ?? {}
        }
      />
    </>
  )
}
