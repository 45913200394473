import { ProductsPage } from '../../products/products.page'
import { CatalogType } from '../../../models/interface'

const ExperiencesPage = () => {
  return (
    <ProductsPage
      catalogType={CatalogType.Experience}
      loadingTitle={'Loading events ...'}
      title={'Events'}
      addTitle={'Add Event'}
      searchPlaceHolder={'event name'}
    />
  )
}

export default ExperiencesPage
