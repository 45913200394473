import { CatalogType, IProductTypeTree } from '../../models/interface'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import useFetch, { ReturnedFetchData } from './useFetch'

const useProductTypesTree = (catalogType: CatalogType): ReturnedFetchData<IProductTypeTree[]> => {
  return useFetch<IProductTypeTree[]>(() =>
    catalogType === CatalogType.Ecommerce
      ? hourMarketApi.getProductTypeFullTree()
      : hourMarketApi.getExperienceProductTypeFullTree()
  )
}

export default useProductTypesTree
