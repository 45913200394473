import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { CatalogType, ICategoryProducts } from '../../models/interface'
import { useParams } from 'react-router'
import CategoryProductCard from './categoryproductcard.component'
import { CrudListHeader } from '../../components/crudlistheader.component'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import LoadingIndicator from '../../components/LoadingIndicator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

interface CategoryProductsLocationState {
  categoryName: string
  catalogType: CatalogType
}

const CategoryProducts = () => {
  const history = useHistory()
  const { categoryId } = useParams<{ categoryId: string }>()
  const [category, setCategory] = useState<ICategoryProducts | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const location = useLocation<CategoryProductsLocationState>()
  const categoryName = location.state.categoryName
  const catalogType = location.state.catalogType ?? CatalogType.Ecommerce
  const addProduct = () => history.push('/products/add', { add: true, categoryId: categoryId })

  useEffect(() => {
    setLoading(true)
    hourMarketApi
      .getCategoryProducts(categoryId)
      .then((r) => setCategory(r.data))
      .finally(() => setLoading(false))
  }, [refresh, categoryId])

  const showProductPreview = async (id: string) => {
    history.push(`/products/preview/${id}`)
  }

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md flex flex-col flex-grow">
          {loading ? (
            <div className="flex flex-col justify-center items-center">
              <LoadingIndicator size={'small'} fill={true} />
              <div className="text-pixie text-sm font-medium">{'Loading products ...'}</div>
            </div>
          ) : (
            <>
              <button
                onClick={history.goBack}
                className="mb-4 flex items-center focus:outline-none">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="1x"
                  className="text-gray-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-500">{categoryName}</span>
              </button>
              <CrudListHeader
                headerLabel={`PRODUCTS FOR ( ${categoryName} )`}
                subheaderLabel={''}
                addAction={addProduct}
              />
              <div className="bg-white min-w-full divide-y flex-1 rounded-md shadow-sm overflow-hidden">
                {category &&
                  category.products &&
                  category.products
                    ?.sort(function (a, b) {
                      return a.displayOrder !== undefined &&
                        b.displayOrder !== undefined &&
                        a.displayOrder > b.displayOrder
                        ? 1
                        : -1
                    })
                    .map((product) => (
                      <div
                        key={product.id}
                        className="bg-white cursor-pointer hover:border-gray-200 hover:bg-pixie hover:bg-opacity-10">
                        <Link
                          to={{
                            pathname: `/products/${product.id}`,
                            state: {
                              add: false,
                              categoryId: categoryId,
                              catalogType: catalogType,
                              displayOrderWithinCategory: product.displayOrder,
                            },
                          }}>
                          <CategoryProductCard
                            key={product.id}
                            product={product}
                            showProductPreview={showProductPreview}
                          />
                        </Link>
                      </div>
                    ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default CategoryProducts
