import React, {useEffect} from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react'
import SidebarMenu from './components/sidebarmenu.component'
import CategoriesPage from './pages/categories/categories.page'
import { ProductsPage } from './pages/products/products.page'
import CategoryDetails from './pages/category/categorydetails.page'
import CategoryProducts from './pages/categoryproducts/category-products.page'
import { Home } from './pages/home/home.page'
import { MediaPage } from './pages/media/media.page'
import MediaDetailsPage from './pages/media/media-details.page'
import ShopifySettings from './pages/Shopify/Index'
import ShopifyCollectionsPage from './pages/Shopify/ShopifyCollectionsPage'
import ShopifyProducts from './pages/Shopify/shopifyproducts'
import { ProductAttributesPage } from './pages/productattributes/product-attributes.page'
import ProductAttributesDetailsPage from './pages/productattributes/product-attribute-details.page'
import { ImageEditor } from './components/imageeditor/image-editor.component'
import Logistics from './pages/Logistics'
import OrdersScreen from './pages/Orders'
import OrderDetails from './pages/Orders/OrderDetails'
import OrderSettings from './pages/Orders/Settings/OrderSettings'
import LocalDelivery from './pages/DeliveryAndCollection/localdelivery/LocalDelivery'
import BusinessSettings from './pages/Settings/General'
import ClickAndCollect from './pages/DeliveryAndCollection/clickandcollect/ClickAndCollect'
import { NationalDelivery } from './pages/DeliveryAndCollection/nationaldelivery/NationalDelivery'
import ExperienceDetails from './pages/experiences/ExperienceDetails'
import ProductDetails from './pages/product/productdetails'
import ProductPreview from './pages/product/Preview'
import PaymentsStripeTest from './pages/Payment/Settings/PaymentsStripeTest'
import CategoryPreview from './pages/category/Preview'
import WhiteLabelScreen from './pages/WhiteLabel'
import { AppConfig } from './config/appconfig'
import Authenticated from './auth/Authenticated'
import LoginB2CDev from './auth/LoginB2CDev'
import ExperienceAttributesPage from './pages/experiences/New/ExperienceAttributesPage'
import ExperiencesPage from './pages/experiences/New/ExperiencesPage'
import ExperienceCategoriesPage from './pages/experiences/New/ExperienceCategoriesPage'
import ExperienceProductTypesPage from './pages/ProductTypes/experiences/ExperienceProductTypesPage'
import ProductTypesPage from './pages/ProductTypes/producttypes.page'
import ProductTypeDetailsPage from './pages/ProductTypes/producttype-details.page'
import EcommerceProductTypesPage from './pages/ProductTypes/ecommerce/EcommerceProductTypesPage'
import VenuesScreen from './pages/Venues/VenuesScreen'
import VenueScreen from './pages/Venues/VenueScreen'
import {hourMarketApi} from "./Utils/api/hourmarket-api";
import {useAppDispatch, useAppSelector} from "./redux/Hooks";

//export const AppContext = React.createContext({ tenantId: '' })

export default function App() {
  const dispatch = useAppDispatch()

  useEffect(() => {
      const getBusiness = async () =>  {
        const business = await hourMarketApi.getTenant()
        console.log("App", business)
        dispatch({type: 'setBusiness', businessName: business.data.name})
      }
      getBusiness()
  }, [])

  return (
    <>
      <AuthenticatedTemplate>
        <MainPage />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Switch>
          <Route path="/login" component={LoginB2CDev} />
          <Route path="/authenticated" exact={true} component={Authenticated} />
        </Switch>
        <div className="bg-gray-200">
          <LoginB2CDev />
        </div>
      </UnauthenticatedTemplate>
    </>
  )
}

const MainPage = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <SidebarMenu navigation={AppConfig.menuNavigation} />
      <Switch>
        <Route path="/home" exact={true} component={Home} />
        <Route path="/login" component={LoginB2CDev} />
        <Route path="/categories/preview/:id" exact={true} component={CategoryPreview} />
        <Route path="/categories" component={CategoriesPage} />
        <Route path="/categoryproducts/:categoryId" component={CategoryProducts} />
        <Route path="/media" exact={true} component={MediaPage} />
        <Route path="/products" exact={true} component={ProductsPage} />
        <Route path="/products/preview/:id" exact={true} component={ProductPreview} />
        <Route path="/products/:id" component={ProductDetails} />
        <Route path="/productattributes" exact={true} component={ProductAttributesPage} />
        <Route
          path="/productattributes/add"
          exact={true}
          component={ProductAttributesDetailsPage}
        />
        <Route
          path="/productattributes/edit/:id"
          exact={true}
          component={ProductAttributesDetailsPage}
        />
        <Route path="/mediadetails/:id" component={MediaDetailsPage} />
        <Route path="/categorydetails/:id" component={CategoryDetails} />
        <Route path="/producttypes" component={ProductTypesPage} />
        <Route path="/producttypedetails/:id" component={ProductTypeDetailsPage} />
        <Route path="/shopify" component={ShopifySettings} />
        <Route path="/shopifycollections" component={ShopifyCollectionsPage} />
        <Route path="/shopifyproducts" component={ShopifyProducts} />
        <Route path="/imageeditor" component={ImageEditor} />
        <Route path="/logistics" component={Logistics} />
        <Route path="/businesssettings" component={BusinessSettings} />
        <Route path="/orders" component={OrdersScreen} />
        <Route path="/orderdetails" component={OrderDetails} />
        <Route path="/ordersettings" component={OrderSettings} />
        <Route path="/localdelivery" component={LocalDelivery} />
        <Route path="/clickandcollect" component={ClickAndCollect} />
        <Route path="/nationaldelivery" component={NationalDelivery} />
        <Route path="/PaymentsStripeTest/:paymentStatus" component={PaymentsStripeTest} />
        <Route path="/whitelabel" component={WhiteLabelScreen} />
        <Route path="/authenticated" component={Authenticated} />
        <Route path="/experiences" component={ExperiencesPage} />
        <Route path="/experiencedetails" component={ExperienceDetails} />
        <Route path="/experienceattributes" component={ExperienceAttributesPage} />
        <Route path="/experiencecategories" component={ExperienceCategoriesPage} />
        <Route path="/experienceproducttypes" component={ExperienceProductTypesPage} />
        <Route path="/ecommerceproducttypes" component={EcommerceProductTypesPage} />
        <Route path="/experienceproducttypes" component={ExperienceProductTypesPage} />
        <Route path="/venues/:id" component={VenueScreen} />
        <Route path="/venues" component={VenuesScreen} />
      </Switch>
    </div>
  )
}
