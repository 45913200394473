import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropzone from 'react-dropzone'
import { faCloudUpload } from '@fortawesome/pro-duotone-svg-icons'
import { faGripVertical, faTrash } from '@fortawesome/pro-light-svg-icons'
import ImageToolImageService from './image-tool-image-service'
import { v4 as uuidv4 } from 'uuid'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd'
import { appConfigPrimaryColor } from '../../config/appconfig'

const ImageToolNew = ({ onImageSelected, entityImages, onImageDeleted, onResortImages, title = 'IMAGES' }) => {
  const [images, setImages] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null)

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = images[dragIndex]

      setImages((prevState) => {
        const copiedStateArray = [...prevState]
        const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem)
        copiedStateArray.splice(dragIndex, 1, prevItem[0])

        console.log('moveCard')
        console.log(copiedStateArray)

        onResortImages(copiedStateArray)

        return copiedStateArray
      })
    },
    [images]
  )

  const onDrop = useCallback((acceptedFiles) => {
    console.log('acceptedFiles[0]', acceptedFiles[0])
    setUploadedFile(acceptedFiles[0])
    addImage({ id: uuidv4(), location: URL.createObjectURL(acceptedFiles[0]), filename: acceptedFiles[0].path })
    onImageSelected(acceptedFiles[0])
  })

  useEffect(() => {
    setImages(entityImages)
  }, [entityImages])

  const addImage = (item) => {
    ImageToolImageService.createItem(item).then((item) => {
      setImages(images.concat(item))
    })
  }

  const deleteImage = (item) => {
    onImageDeleted(item)

    ImageToolImageService.deleteItem(item).then((id) => {
      setImages(images.filter((x) => x.id !== id).map((item) => item))
    })
  }

  return (
    <div className="">
      <div className="font-bold text-gray-800/90 mb-4">{title}</div>
      <div className="flex w-full">
        <ImageUploadPanel onDrop={onDrop} />
      </div>
      <div className="flex flex-col space-y-4 divide-y">
        <DndProvider backend={HTML5Backend}>
          {images &&
            images
              //.sort(function(a,b){ return (a.displayOrder > b.displayOrder) ? 1 : -1})
              .map((image, index) => {
                return <ImageToolCard key={image.id} index={index} data={image} onClick={deleteImage} moveCard={moveCard} />
              })}
        </DndProvider>
      </div>
    </div>
  )
}

export default ImageToolNew

const ImageUploadPanel = ({ onDrop }) => {
  return (
    <div className="mt-2 mb-4 w-full">
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              {...getRootProps()}
              className="flex flex-col h-32 items-center justify-center overflow-hidden rounded-md p-4 bg-gray-100 hover:bg-pixie hover:bg-opacity-10 cursor-pointer focus:outline-none">
              <input {...getInputProps()} />
              <FontAwesomeIcon
                icon={faCloudUpload}
                size="2x"
                style={{ '--fa-primary-color': appConfigPrimaryColor, '--fa-secondary-color': appConfigPrimaryColor }}
              />
              <div className="text-sm mt-2 text-gray-500  p-1 text-center">Drop image or click to upload image</div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

const ImageToolCard = ({ data, index, onClick, moveCard }) => {
  const ref = useRef(null)
  const id = data.id

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  //console.log("image data", data)

  return (
    <div className="flex text-gray-800 font-semibold items-center py-2" data-handler-id={handlerId} ref={ref} style={{ opacity }}>
      {/*<div>{data.displayOrder}</div>*/}
      <button className="cursor-pointer focus:outline-none mr-4">
        <FontAwesomeIcon icon={faGripVertical} size="1x" style={{ '--fa-primary-color': 'transparent', '--fa-secondary-color': 'black' }} />
      </button>
      <img
        src={data.location}
        className="block h-16 w-16 hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400"
        alt="upload file"
      />
      <button onClick={() => onClick(data.id)} className="cursor-pointer focus:outline-none ml-10">
        <FontAwesomeIcon icon={faTrash} size="1x" style={{ '--fa-primary-color': 'transparent', '--fa-secondary-color': 'black' }} />
      </button>
      <div className="px-4 text-gray-500 font-medium text-sm">{data.filename}</div>
    </div>
  )
}

const ItemTypes = {
  CARD: 'card',
}
