import React, { useEffect, useState } from 'react'
import { Prompt, useParams } from 'react-router'
import { hourMarketApi } from '../../Utils/api/hourmarket-api'
import { useHistory, useLocation } from 'react-router-dom'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm } from 'react-hook-form'
import {
  CatalogType,
  defaultCategory,
  ICategory,
  ICategoryProducts,
  IDeleteCategoryMediaItem,
  IMediaItem,
} from '../../models/interface'
import ImageToolNew from '../../components/imagetool/imagetool'
import {
  InputCheckBoxElement,
  InputElement,
  InputHtmlElement,
  InputNumberElement,
} from '../../components/form-elements/input-element'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { AlertDialog, YesNoDialog } from '../../components/dialogs'
import NavigateBackLayout from '../../layouts/navigateBackLayout'
import FooterSaveCancelDeleteLayout from '../../layouts/FooterSaveCancelDeleteLayout'

interface CategoryDetailsLocationProps {
  add: boolean
  parentCategoryId?: string
  parentCategory?: string
  catalogType: CatalogType
}

interface CategoryDetailsParamsProps {
  id: string
}

const CategoryDetails = () => {
  const [category, setCategory] = useState<ICategory | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams<CategoryDetailsParamsProps>()
  const location = useLocation<CategoryDetailsLocationProps>()
  const add = location.state.add
  const catalogType = location.state.catalogType
  const parentCategoryId = location.state?.parentCategoryId ?? undefined
  const parentCategory = location.state?.parentCategory ?? undefined
  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [showCategoryHasProductsModal, setShowCategoryHasProductsModal] = React.useState(false)

  const { register, handleSubmit, reset, formState, control } = useForm<ICategory>({
    defaultValues: defaultCategory,
  })

  useEffect(() => {
    if (!add) {
      setLoading(true)
      hourMarketApi
        .getCategory(id)
        .then((r) => {
          setCategory(r.data)
          reset(r.data)
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const onSubmit = (data: ICategory) => {
    if (add) {
      data.parentId = parentCategoryId
      if (catalogType === CatalogType.Ecommerce) {
        return hourMarketApi.createCategory(data).then(() => {
          reset(data)
          history.goBack()
        })
      }
      if (catalogType === CatalogType.Experience) {
        return hourMarketApi.createExperienceCategory(data).then(() => {
          reset(data)
          history.goBack()
        })
      }
    } else {
      return hourMarketApi.updateCategory(data).then(() => {
        reset(data)
      })
    }
  }

  const handleImageDeleted = (mediaItemId: string) => {
    let mediaItemDelete: IDeleteCategoryMediaItem = {
      categoryId: id,
      mediaItemId: mediaItemId,
    }

    hourMarketApi.deleteCategoryMediaItem(mediaItemDelete).then((r) => {
      console.log(r)
    })
  }

  const handleImageUpload = (uploadedFile: Blob) => {
    let mediaItemUpload = {
      MediaType: 1,
      Title: '',
      Description: '',
      ContentType: 'image/png',
      Filename: '',
      Id: id ?? '',
      MediaFile: uploadedFile,
    }

    hourMarketApi.uploadCategoryMediaItem(mediaItemUpload).then((r) => {})
  }

  const disabled =
    !(formState.isDirty && Object.keys(formState.dirtyFields).length > 0) || formState.isSubmitting

  const onResortImages = (images: any) => {
    console.log('onResortImages')
    console.log(images)

    const sortedImages = images.map((image: IMediaItem, index: number) => {
      return {
        MediaItemId: image.id,
        DisplayOrder: index,
      }
    })

    const updateCategorySortedImages = {
      CategoryId: id,
      SortedMediaItems: sortedImages,
    }

    console.log('onResortImages', updateCategorySortedImages)

    hourMarketApi.updateCategorySortedImages(updateCategorySortedImages).then((r) => console.log(r))
  }

  const showDeleteCategoryModal = () => {
    if (category?.id) {
      hourMarketApi.getCategoryProducts(category?.id!).then((r) => {
        if (hasProducts(r.data)) {
          setShowCategoryHasProductsModal(true)
        } else {
          setShowDeleteModal(true)
        }
      })
    }
  }

  const hasProducts = (categoryProducts: ICategoryProducts) =>
    categoryProducts.products && categoryProducts.products.length > 0

  const yesDeleteAction = async () => {
    setShowDeleteModal(false)
    if (category?.id) {
      await deleteCategory(category.id)
    }
  }

  const deleteCategory = async (id: string) => {
    await hourMarketApi.deleteCategory(id).finally(() => history.goBack())
  }

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <YesNoDialog
        modalIsOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        yesAction={yesDeleteAction}
        alertType={'warning'}
        noAction={() => setShowDeleteModal(false)}
        title="Delete Category"
        subtitle="Do you want to delete category"
        description={category?.title ?? ''}
      />
      <AlertDialog
        modalIsOpen={showCategoryHasProductsModal}
        closeModal={() => setShowCategoryHasProductsModal(false)}
        okAction={() => setShowCategoryHasProductsModal(false)}
        title={category?.title ?? ''}
        subtitle="This category has products assigned to it and these need to be un-assigned first !"
        okDescription="OK"
      />
      <div className="flex-1 overflow-y-scroll">
        {loading ? (
          <LoadingIndicatorPanel message="Loading category ..." />
        ) : (
          <div className="mx-auto mt-8 max-w-7xl rounded-md">
            <NavigateBackLayout title={'COLLECTIONS'} onPressed={history.goBack} />
            <div className="mx-auto max-w-7xl p-8 rounded-md shadow-sm bg-white">
              <div className="font-bold text-gray-800/90">DETAILS</div>
              <form>
                <Prompt
                  message={(location, action) => {
                    if (action === 'POP') {
                      console.log('Backing up...')
                    }
                    return !formState.isDirty
                      ? true
                      : `You have unsaved changes are sure you want to exit ?`
                  }}
                />
                <InputElement
                  label={'Title'}
                  id={'title'}
                  type={'text'}
                  register={register('title')}
                />
                <InputHtmlElement
                  label={'Description'}
                  id={'description'}
                  name="description"
                  control={control}
                  register={register('description')}
                />
                <InputNumberElement
                  label={'Display Order'}
                  id={'displayOrder'}
                  register={register('displayOrder')}
                />
                <InputCheckBoxElement
                  label={'Active'}
                  id={'active'}
                  register={register('active')}
                />
              </form>
            </div>
            {!add && (
              <div className="mt-8 max-w-7xl p-8 rounded-md shadow-sm bg-white">
                <ImageToolNew
                  onImageSelected={handleImageUpload}
                  entityImages={category?.mediaItems}
                  onImageDeleted={handleImageDeleted}
                  onResortImages={onResortImages}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {/* Footer */}
      {!loading && (
        <FooterSaveCancelDeleteLayout
          onCancel={history.goBack}
          onDelete={showDeleteCategoryModal}
          disabled={disabled}
          isSubmitting={formState.isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          showDeleteButton={!add}
        />
      )}
    </div>
  )
}

export default CategoryDetails
