import React, {FC} from "react";
import Modal, {Styles} from "react-modal";
import classNames from "classnames";

export interface YesNoDialogProps {
    modalIsOpen: boolean;
    title: string;
    subtitle: string;
    description: string;
    closeModal: () => void;
    noAction: () => void;
    yesAction: () => void;
    alertType?: "normal" | "warning"
}

const YesNoDialogStyles: Styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#DDDDDDCC',
        zIndex: 50
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '0px none #000',
        background: '',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
    }
}

const YesNoDialog: FC<YesNoDialogProps> = ({modalIsOpen, closeModal, noAction, yesAction, title, subtitle, description, alertType = 'normal'}) => {

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                shouldReturnFocusAfterClose={false}
                style={YesNoDialogStyles}
                contentLabel="">
                <div className="shadow-lg rounded-b-md overflow-hidden bg-white m-4 z-50">
                    <div className={classNames(
                        alertType === 'warning'
                            ? 'bg-transparent'
                            : 'bg-transparent',
                            "w-full h-2")} />
                    <div className="flex flex-col w-full outline-none focus:outline-none pl-6 pr-16 py-6">
                        <div className="w-full text-lg text-gray-700 uppercase font-semibold">
                            {title}
                        </div>
                        <div className="relative py-2 flex-auto">
                            <div className="my-2 text-gray-500 text-base">
                                {subtitle}
                            </div>
                            <div className="my-2 text-gray-500 text-base">
                                {description}
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100 border-t border-solid py-4">
                        <div className="flex justify-end space-x-4 w-full pr-4">
                            <button
                                className="w-24 text-sm  font-bold text-gray-500/90 px-4 py-1 rounded-md hover:text-gray-300"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    noAction()
                                }}>
                                Cancel
                            </button>
                            <button
                                className="w-24 text-sm bg-red-500 font-bold text-white/90 px-4 py-1 rounded-md hover:bg-red-300 hover:text-white/50"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    yesAction()
                                }}>
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export interface AlertDialogProps {
    modalIsOpen: boolean;
    title: string;
    subtitle: string;
    closeModal: () => void;
    okAction: () => void;
    okDescription: string;
}

const AlertDialogStyles:Styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#DDDDDDCC'
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '0px solid #ccc',
        background: '',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '0px'
    }
}

const AlertDialog: FC<AlertDialogProps> = ({modalIsOpen, closeModal, okAction, title, subtitle, okDescription}) => {

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={false}
                style={AlertDialogStyles}
                contentLabel="">
                <div className="shadow-lg rounded-md bg-white m-4">
                    <div className="outline-none flex flex-col w-full outline-none focus:outline-none px-8 py-6">
                        <div className="flex items-start justify-between p-2">
                            <h3 className="w-full text-lg text-gray-700 uppercase font-semibold">
                                {title}
                            </h3>
                        </div>
                        <div className="relative p-2 flex-auto text-center">
                            <div className="my-2 text-gray-500 text-base">
                                {subtitle}
                            </div>
                        </div>
                    </div>
                    <div className="w-full bg-gray-100 border-t border-solid py-4">
                        <div className="flex items-center space-x-2 justify-center px-6">
                            <button
                                className="px-8 text-sm bg-pixie font-semibold text-white/90 py-1 rounded-md hover:bg-opacity-40"
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault()
                                    okAction()
                                }}>
                                {okDescription}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export {
    YesNoDialog,
    AlertDialog
}
