import React, { useEffect, useState } from "react";
import PageHeaderComponent from "../../components/pageHeaderComponent";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";
import { useFormFields } from "../../Utils/form-utils";
import {
  IShopifyApiKeys,
  IShopifyConnection,
  IShopifyConnectionAuth,
  IShopifyProduct,
} from "../../models/interface";
import shopifyIcon from "../../assets/images/shopify-avatar.png";
import axios, { AxiosResponse } from "axios";
import LocalStorageService from "../../Utils/localstorageservice";
import { hourMarketApi } from "../../Utils/api/hourmarket-api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import {Link} from "react-router-dom";
import {faSpinner} from "@fortawesome/pro-duotone-svg-icons";
import {BaseUrl} from "../../Utils/api/api.config";

const ShopifySettings = () => {
  const {
    formFields,
    setFormFields,
    createChangeHandler,
  } = useFormFields<IShopifyConnection>({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    hourMarketApi.getShopifyConnector().then((response) => {
      setFormFields(response.data);
    });
  }, []);

  function onSubmit(e: React.BaseSyntheticEvent) {
    e.preventDefault();

    const body: IShopifyConnection = {
      name: formFields.name,
      url: formFields.url,
    };

    setLoading(true)

    axios
      .post<IShopifyConnection, AxiosResponse<IShopifyConnectionAuth>>(
          `${BaseUrl}shopifyauth`,
        body,
        {
          headers: {
            Authorization: `Bearer ${LocalStorageService.getAccessToken()}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        window.location.href = response.data.authurl;
      });
  }

  return (
    <>
      <div className="m-10 ml-16 max-w-7xl p-4 rounded-md">
        <PageHeaderComponent title="Index" icon={faAddressCard} />
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 rounded-lg  sm:rounded-md bg-white ">
          <section aria-labelledby="payment_details_heading">
            <form action="#" method="POST">
              <div className="sm:overflow-hidden">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <div className="flex items-center space-x-2">
                    <img
                      src={shopifyIcon}
                      className="h-12 w-12"
                      alt="Index"
                    />
                    <div>
                      <h2
                        id="payment_details_heading"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Index Account Information
                      </h2>
                    </div>
                  </div>
                  <p className="mt-1 text-sm text-gray-500 mt-5">
                    Enter your Index information below to connect your Index
                    Account.
                  </p>
                  {formFields?.authorised && (
                    <div className="mt-5">
                      <div className="w-full">
                        <label
                          htmlFor="store_name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Shop Id
                        </label>
                        <input
                          type="text"
                          name="store_name"
                          id="store_name"
                          disabled={formFields?.authorised}
                          value={formFields.shopId}
                          onChange={createChangeHandler("name")}
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                        />
                      </div>
                    </div>
                  )}
                  <div className="mt-5">
                    <div className="w-full">
                      <label
                        htmlFor="store_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        name="store_name"
                        id="store_name"
                        disabled={formFields?.authorised}
                        value={formFields.name}
                        onChange={createChangeHandler("name")}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="store_description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Index URL (*.myshopify.com)
                      </label>
                      <input
                        type="text"
                        name="store_description"
                        id="store_description"
                        disabled={formFields?.authorised}
                        value={formFields?.url}
                        onChange={createChangeHandler("url")}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="store_authorised"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Authorised
                      </label>
                      <input
                        type="checkbox"
                        name="store_authorised"
                        id="store_authorised"
                        disabled={true}
                        checked={formFields?.authorised}
                        onChange={createChangeHandler("authorised")}
                        className="mt-1 block border border-gray-300 rounded-md shadow-sm py-2 px-2 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
                {!formFields?.authorised && (
                  <div className="px-4 py-3 bg-gray-50 text-left sm:px-6">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      {
                        loading ? <FontAwesomeIcon icon={faSpinner} color="white" spin={true} /> : "Save"
                      }
                    </button>
                  </div>
                )}
              </div>
            </form>

            {formFields?.authorised && (
              <>
                <Link to={{pathname: `/shopify/`, state: {}}}>
                  <button className="mx-5 mb-5 bg-green-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                    View Index Catalog
                  </button>
                </Link>
              </>
            )}
          </section>
        </div>

        {<ApiKeysCard />}
      </div>
    </>
  );
};

export default ShopifySettings;

export const ProductCard = (products: { products: IShopifyProduct[] }) => {
  return (
    <>
      <div>
        {products.products.map((product) => (
          <div
            key={product.id}
            className="flex items-center space-x-2 text-sm mb-4"
          >
            <img
              className="object-cover w-32 h-32"
              src={product.images[0].src}
            />
            <div className="flex flex-col">
              <div className="font-semibold">{product.title}</div>
              <div className="font-normal">{product.handle}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export const ApiKeysCard = () => {
  const { formFields, setFormFields, createChangeHandler } = useFormFields<IShopifyApiKeys>({ id: "", accessToken: "", shopifyShopId: "" });
  const [apiKey, setApiKey] = useState<IShopifyApiKeys | null>(null);

  const getData = () => {
    hourMarketApi.getShopifyApiKeys().then((response) => {
      const data = response.data;
      console.log(data);
      if (data?.length > 0) {
        setApiKey(data[0]);
        setFormFields(data[0]);
      } else {
        setFormFields({ id: "", accessToken: "", shopifyShopId: "" });
        setApiKey(null);
      }
    });
  }

  useEffect(() => {
    getData();
  }, [setFormFields]);

  const hasApiKey = apiKey != null || false;

  function deleteToken(e: React.BaseSyntheticEvent)
  {
    e.preventDefault();
     console.log("Delete Token");
    hourMarketApi.deleteShopifyApiKeys(apiKey?.id!).then(r => {
      console.log(r);
      getData();
    });
  }

  function onSubmit(e: React.BaseSyntheticEvent) {
    e.preventDefault();
    console.log("submit");
    console.log(formFields);

    const body: IShopifyApiKeys = {
      accessToken: formFields.accessToken,
      shopifyShopId: formFields.shopifyShopId,
    };

    hourMarketApi.createShopifyApiKeys(body).then(r => {
      console.log(r);
      getData();
    });
  }

  return (
    <>
      <div className="mt-5 py-4 space-y-2 sm:px-6 lg:px-0 lg:col-span-9 rounded-lg  sm:rounded-md bg-white">
        <div className="bg-white py-6 px-4 sm:p-6">
        <h2 id="payment_details_heading" className="text-lg leading-6 font-medium text-gray-900">
          API Keys
        </h2>
        <form action="#" method="POST">
          <div className="mt-5">
            <div>
              <label htmlFor="store_name" className="block text-sm font-medium text-gray-700">
                API Key
              </label>
              <div className="flex space-x-2 items-center">
                <input
                    type="text"
                    name="AccessToken"
                    id="AccessToken"
                    disabled={hasApiKey}
                    value={formFields.accessToken}
                    onChange={createChangeHandler("accessToken")}
                    className="mt-1 block w-96 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                />
                {
                  hasApiKey &&
                      <button onClick={deleteToken} className="focus:outline-none ">
                        <FontAwesomeIcon icon={faTrash} size="1x" color="gray" />
                      </button>
                }
              </div>
            </div>
          </div>
          {
            !hasApiKey &&
            <div className="py-3 bg-gray-50 text-left mt-3">
              <button
                  type="submit"
                  onClick={onSubmit}
                  className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                Save
              </button>
            </div>
          }
        </form>
      </div>
      </div>
    </>
  );
};
