import { faHome, faTh, faTicket, faTruck, faWrench } from '@fortawesome/pro-solid-svg-icons'

export const defaultNavigation = [
  { name: 'Home', icon: faHome, current: true, open: false, href: '/home', enabled: false },
  {
    name: 'Ecommerce',
    icon: faTh,
    current: false,
    open: false,
    enabled: false,
    children: [
      { name: 'Collections', href: '/categories', enabled: true },
      { name: 'Products', href: '/products', enabled: true },
      { name: 'Product Options', href: '/productattributes', enabled: true },
      { name: 'Product Types', href: '/ecommerceproducttypes', enabled: true },
      { name: 'Media', href: '/media', enabled: true },
    ],
  },
  { name: 'Collections', href: '/experiencecategories', enabled: true },
  { name: 'Events', href: '/experiences', enabled: true },
  { name: 'Event Options', href: '/experienceattributes', enabled: true },
  { name: 'Event Types', href: '/experienceproducttypes', enabled: true },
  { name: 'Venues', href: '/venues', enabled: true },
  {
    name: 'Events',
    icon: faTicket,
    current: false,
    open: false,
    enabled: false,
    children: [
      { name: 'Collections', href: '/experiencecategories', enabled: true },
      { name: 'Events', href: '/experiences', enabled: true },
      { name: 'Event Options', href: '/experienceattributes', enabled: true },
      { name: 'Event Types', href: '/experienceproducttypes', enabled: true },
      { name: 'Venues', href: '/venues', enabled: true },
    ],
  },
  {
    name: 'Fulfillment',
    icon: faTruck,
    current: false,
    open: false,
    enabled: false,
    children: [{ name: 'Orders', href: '/orders', enabled: true }],
  },
  {
    name: 'Settings',
    icon: faWrench,
    current: false,
    open: false,
    enabled: false,
    children: [
      { name: 'General', href: '/businesssettings', enabled: true },
      { name: 'Click and Collect', href: '/clickandcollect', enabled: true },
      { name: 'Local Delivery', href: '/localdelivery', enabled: true },
      { name: 'National Delivery', href: '/nationaldelivery', enabled: true },
      { name: 'Orders', href: '/ordersettings', enabled: true },
      { name: 'WhiteLabel', href: '/whitelabel', enabled: true },
    ],
  },
  { name: 'Business Details', href: '/businesssettings', enabled: false },
]

