import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { CatalogType, ICategory } from '../../models/interface'
import { useHistory } from 'react-router-dom'
import { faImage } from '@fortawesome/pro-duotone-svg-icons'
import { faEllipsisVAlt } from '@fortawesome/pro-solid-svg-icons'
import { Menu, Transition } from '@headlessui/react'
import ImageWithFallback from '../../components/ImageWithFallback'

interface CategoryCardProps {
  category: ICategory
  deleteCategory: (id: string) => void
  showCategoryPreview: (id: string) => void
  catalogType: CatalogType
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const CategoryCardComponent: React.FC<CategoryCardProps> = ({
  category,
  deleteCategory,
  showCategoryPreview,
  catalogType,
}) => {
  const history = useHistory()

  const showProducts = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    history.push(`/categoryproducts/${category.id}`, { categoryName: category.title })
  }

  const showChildCategories = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    history.push(`/categories`, {
      parentCategoryId: category.id,
      parentCategory: category.title,
      catalogType: catalogType,
    })
  }

  const showPreview = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    showCategoryPreview(category?.id!)
  }

  return (
    <div className="group flex justify-between px-2 py-2">
      <div className="flex">
        {category.image ? (
          <ImageWithFallback
            fallbackImage={category.image}
            mainImage={category.thumbImage ?? ''}
            altText=""
            additionalCss="h-16 w-16 object-cover"
          />
        ) : (
          <div className="flex items-center justify-center h-16 w-16">
            <FontAwesomeIcon icon={faImage} size="3x" className="text-gray-200" />
          </div>
        )}
        <div className="ml-4 text-base text-gray-800 font-normal">{category.title}</div>
      </div>
      <div className="flex flex-col justify-center ml-4">
        <Menu as="div" className="inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm  hover:bg-gray-50 focus:outline-none">
                  <FontAwesomeIcon icon={faEllipsisVAlt} size={'1x'} className="text-gray-400" />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items
                  static
                  className="origin-top-right absolute mr-10 right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showChildCategories}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full  text-left'
                          )}>
                          Child Categories
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showProducts}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}>
                          {catalogType === CatalogType.Ecommerce ? 'Products' : 'Events'}
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={showPreview}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full  text-left'
                          )}>
                          Preview
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </div>
  )
}
