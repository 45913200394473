import styled from 'styled-components'

const Wrapper = styled.div`
  //background: #eeeeee;
  font-weight: 400;
  //border-radius: 20px;
  border: #cccccc 1px solid;
  margin-bottom: 15px;
  padding: 10px;
  font-size: medium;
  position: relative;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  width: 70%;
`;

export default Wrapper;