import { IOrder } from '../../models/interface'

const orderStatusText = (order: IOrder): string => {
  switch (order.orderStatus) {
    case 10:
      return 'Pending'
    case 20:
      return 'Processing'
    case 30:
      return 'Complete'
    case 40:
      return 'Cancelled'
  }
  return 'Not specified'
}

const paymentStatusText = (order: IOrder): string => {
  switch (order.orderStatus) {
    case 10:
      return 'Pending'
    case 20:
      return 'Authorised'
    case 30:
      return 'Paid'
    case 35:
      return 'Partially Refunded'
    case 40:
      return 'Refunded'
    case 50:
      return 'Voided'
  }
  return 'Not specified'
}

const shippingStatusText = (order: IOrder): string => {
  switch (order.shippingStatus) {
    case 10:
      return 'Shipping Not Required'
    case 20:
      return 'Not Yet Shipped'
    case 25:
      return 'Partially Shipped'
    case 30:
      return 'Shipped'
    case 40:
      return 'Delivered'
  }
  return 'Not specified'
}

export { orderStatusText, paymentStatusText, shippingStatusText }
