import React, { FC, MouseEventHandler } from 'react'
import DeleteButton from '../../../components/DeleteButton'
import CancelButton from '../../../components/CancelButton'
import SaveButton from '../../../components/SaveButton'

interface Props {
  buttonText: string
  isAddMode: boolean
  disabled: boolean
  isSubmitting: boolean
  onDelete: () => void
  onCancel: () => void
  onSubmit: () => void
}

const SaveCancelDeleteFooter: FC<Props> = ({
  isAddMode,
  disabled,
  isSubmitting,
  onDelete,
  onCancel,
  onSubmit,
  buttonText,
}) => {
  console.log('SaveCancelDeleteFooter', disabled)

  return (
    <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
      <div className="flex space-x-4 justify-between items-center">
        {!isAddMode ? (
          <DeleteButton disabled={false} isCancelling={false} onDelete={onDelete} />
        ) : (
          <div></div>
        )}
        <div className="flex space-x-4">
          <CancelButton disabled={false} isCancelling={false} onCancel={onCancel} />
          <SaveButton
            disabled={disabled}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
            buttonText={buttonText}
          />
        </div>
      </div>
    </div>
  )
}

export default SaveCancelDeleteFooter
