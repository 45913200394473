import React, { FC } from 'react'
import { IOrder } from '../../models/interface'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { deliveryMethodIcon, deliveryMethodText, deliveryStatusText } from './DeliveryMethodUtils'
import { orderStatusText, paymentStatusText, shippingStatusText } from './OrderUtils'
import { LoadingIndicatorPanel } from '../../components/LoadingIndicatorPanel'
import { useOrder } from '../../hooks'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import AddressLayout from '../../components/addressLayout'

type OrderDetailsProps = {
  id: string
}

const OrderDetails: FC = () => {
  const location = useLocation<OrderDetailsProps>()
  const { data: order, loading } = useOrder(location.state.id)
  const history = useHistory()

  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl p-4 rounded-md flex flex-col flex-grow">
          {loading ? (
            <LoadingIndicatorPanel message="Loading order ..." />
          ) : (
            <>
              <button
                onClick={history.goBack}
                className="mb-4 flex items-center focus:outline-none">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  size="1x"
                  className="text-gray-500 cursor-pointer"
                />
                <span className="ml-2 text-gray-500">ORDERS</span>
              </button>
              <div className="flex flex-col bg-white p-6 shadow-sm rounded-md overflow-clip">
                <div className="text-gray-800 font-bold mb-4">ORDER: {order?.orderNumber}</div>
                <div className="flex flex-col space-y-4">
                  <div className="flex">
                    <div className="font-bold text-gray-700 text-sm w-36">Order Date:</div>
                    <div className="font-medium text-gray-700 text-sm">
                      {moment(order?.created).format('dddd DD/MM/YYYY HH:MM')}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="font-bold text-gray-700 text-sm w-36">Order Status:</div>
                    <div className="font-medium text-gray-700 text-sm">
                      {orderStatusText(order!)}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="font-bold text-gray-700 text-sm w-36">Payment Status:</div>
                    <div className="font-medium text-gray-700 text-sm">
                      {paymentStatusText(order!)}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="font-bold text-gray-700 text-sm w-36">Shipping Status:</div>
                    <div className="font-medium text-gray-700 text-sm">
                      {shippingStatusText(order!)}
                    </div>
                  </div>

                  {order && order.shippingStatus > 10 && (
                    <>
                      <div className="flex">
                        <div className="font-bold text-gray-700 text-sm w-36">Delivery Method:</div>
                        <div className="font-medium flex items-center text-gray-700 text-sm">
                          <FontAwesomeIcon
                            icon={deliveryMethodIcon(order!)}
                            size="lg"
                            className="mr-2 text-pixie"
                          />
                          <div>{deliveryMethodText(order!)}</div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="font-bold text-gray-700 text-sm w-36">Delivery Status:</div>
                        <div className="font-medium text-gray-700 text-sm">
                          {deliveryStatusText(order!)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="flex justify-between bg-white mt-10 p-6 rounded-md shadow-sm overflow-clip">
                {order?.shippingAddress && (
                  <AddressLayout title="Shipping Address" address={order.shippingAddress} />
                )}

                {order?.billingAddress && (
                  <AddressLayout title="Billing Address" address={order.billingAddress} />
                )}
              </div>

              <div className="bg-white mt-10 p-6 rounded-md shadow-sm overflow-clip">
                <span className="text-gray-800 font-bold">Details</span>{' '}
                <span className="text-sm">({OrderTotalItems(order!)} items)</span>
                <table className="table-auto w-full mt-7">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left text-gray-900">Description</th>
                      <th className="text-right">Qty</th>
                      <th className="text-right">Price</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  {order?.orderItems?.map((orderItem) => (
                    <tr>
                      <td className="pt-4 text-left">{orderItem?.product.name}</td>
                      <td className="text-right">{orderItem.quantity}</td>
                      <td className="text-right">£{orderItem.unitPriceInclTax.toFixed(2)}</td>
                      <td className="text-right">
                        £{(orderItem.quantity * orderItem.unitPriceInclTax).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                </table>
                <div className="flex justify-between items-center mt-10">
                  <div></div>
                  {/*<button className="focus:outline-none bg-transparent border-0 bg-pixie text-gray-100 font-medium text-sm rounded-md px-4 py-2 hover:bg-opacity-60">*/}
                  {/*  <FontAwesomeIcon*/}
                  {/*    icon={deliveryMethodIcon(order!)}*/}
                  {/*    size="lg"*/}
                  {/*    className="mr-2 text-gray-100"*/}
                  {/*  />*/}
                  {/*  {deliveryMethodCompleteText(order!)}*/}
                  {/*</button>*/}
                  <div className="font-bold text-xl">
                    Total: £{OrderTotalPrice(order!)?.toFixed(2)}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderDetails

const OrderTotalItems = (order: IOrder) => order?.orderItems.reduce((a, b) => a + b.quantity, 0)

const OrderTotalPrice = (order: IOrder) => {
  return order?.orderItems.reduce((a, b) => a + b.quantity * b.unitPriceInclTax, 0)
}
