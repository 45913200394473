import { ICategory, IProductTypeTree } from '../../../models/interface'
import React, { FC, useState } from 'react'

type Props = {
  productType: IProductTypeTree
  initialSelectedProductTypes: string[]
  productTypeSelected: (isSelected: boolean, productTypeId: string) => void
}
const ProductTypesFilterItem: FC<Props> = ({
  productType,
  initialSelectedProductTypes,
  productTypeSelected,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(
    initialSelectedProductTypes.some((x) => x === productType.id)
  )

  const onCategorySelected = (checked: boolean) => {
    setIsChecked(checked)
    productTypeSelected(checked, productType.id!)
  }

  return (
    <div className="flex flex-col">
      <div className="flex space-x-2 my-2 items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onCategorySelected(e.target.checked)}
          className="h-4 w-4 text-pixie selected:outline-none focus:outline-none focus:ring-0"
        />
        <div className="text-sm">{productType.title}</div>
      </div>
      {productType?.childProductTypes && (
        <div className="pl-8">
          {productType?.childProductTypes
            ?.sort(function (a, b) {
              return a.displayOrder !== undefined &&
                b.displayOrder !== undefined &&
                a.displayOrder > b.displayOrder
                ? 1
                : -1
            })
            .map((category: ICategory) => {
              return (
                <ProductTypesFilterItem
                  key={category.id}
                  productType={category}
                  productTypeSelected={productTypeSelected}
                  initialSelectedProductTypes={initialSelectedProductTypes}
                />
              )
            })}
        </div>
      )}
    </div>
  )
}

export default ProductTypesFilterItem
