import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import HeaderTitleLayout from '../layouts/headerTitleLayout'

export const CrudListHeader = ({ headerLabel, subheaderLabel, addAction }) => {
  return (
    <div className="flex justify-between items-center mb-6">
      <HeaderTitleLayout title={headerLabel} />
      {/*<h1 className="text-lg font-GeomanistBlack text-gray-600 uppercase">{headerLabel}</h1>*/}
      {subheaderLabel && (
        <div>
          <button
            className="focus:outline-none bg-transparent border-0 bg-pixie text-white/90 font-medium text-sm rounded-md px-4 py-2 hover:opacity-40"
            onClick={addAction}>
            <FontAwesomeIcon icon={faPlus} size="lg" className="mr-2 text-white/90" />
            {subheaderLabel}
          </button>
        </div>
      )}
    </div>
  )
}
