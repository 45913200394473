import React, { FC, useContext } from 'react'
import { CatalogType, IProductDetails, IProductType } from '../../../models/interface'
import { ProductStateContext } from '../productdetails'
import ProductTypeLozenge from './productTypeLozenge'

export interface Props {
  catalogType: CatalogType
  product?: IProductDetails
  productTypes?: IProductType[]
  manageProductTypes: () => void
}

const ProductTypesContainer: FC<Props> = ({ catalogType, manageProductTypes }) => {
  const productState = useContext(ProductStateContext)

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-bold text-gray-800/90 mb-5">
          {catalogType === CatalogType.Ecommerce ? 'PRODUCT TYPES' : 'EVENT TYPES'}
        </div>
        <button
          onClick={manageProductTypes}
          className="bg-pixie text-sm font-medium text-white/90 px-4 py-1 rounded-md hover:bg-opacity-40">
          Manage
        </button>
      </div>
      <div className="flex flex-wrap">
        {productState.product?.productTypes?.map((item) => (
          <div className="mr-4">
            <ProductTypeLozenge
              id={item.id}
              label={item.title}
              isChild={true}
              selected={false}
              disabled={true}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductTypesContainer
