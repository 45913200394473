import React, { FC } from 'react'

interface Props {
  menuLabel: string
  anchorRef: string
  onClicked: (e: any) => void
}

const SideMenuItem: FC<Props> = ({ menuLabel, anchorRef, onClicked }) => {
  return (
    <div className="hover:text-pixie">
      <a href={anchorRef} onClick={onClicked}>
        {menuLabel}
      </a>
    </div>
  )
}

export default SideMenuItem
