import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type PageHeaderComponentProps = {
    title: String;
    icon: IconProp;
}

const PageHeaderComponent: React.FC<PageHeaderComponentProps> = ({title, icon}) => {
    return (
        <div className="flex items-center mb-4">
            {/*<FontAwesomeIcon*/}
            {/*    icon={icon}*/}
            {/*    size="2x"*/}
            {/*    className="mr-4 text-blue-400"*/}
            {/*/>*/}
            <h1 className="text-2xl font-bold text-pixie">{title}</h1>
        </div>
    )
};

export default PageHeaderComponent;