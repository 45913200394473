import { CatalogType, IProductDetails } from '../../../models/interface'
import { FormState } from 'react-hook-form'
import React, { FC } from 'react'
import { ProductOptionsPanel } from './productOptionsPanel'
import CancelButton from '../../../components/CancelButton'
import SaveButton from '../../../components/SaveButton'

type Props = {
  catalogType: CatalogType
  title: string
  product: IProductDetails
  hideVariantOptions: () => void
  formState: FormState<IProductDetails>
}

const ProductOptionsManage: FC<Props> = ({
  catalogType,
  title,
  product,
  hideVariantOptions,
  formState,
}) => {
  return (
    <div className="flex flex-col flex-grow w-full overflow-hidden">
      <div className="flex-1 overflow-y-scroll">
        <div className="mx-auto mt-8 max-w-7xl m-4 rounded-md shadow-sm flex flex-col flex-grow overflow-hidden">
          <div className="max-w-7xl p-4 bg-white rounded-md">
            <ProductOptionsPanel catalogType={catalogType} title={title} product={product} />
          </div>
        </div>
      </div>
      <div className="pb-6 px-4 flex-none bg-gray-50 border-t-2 w-full">
        <div className="flex space-x-4 justify-end">
          <CancelButton disabled={false} isCancelling={false} onCancel={hideVariantOptions} />
          <SaveButton
            disabled={false}
            isSubmitting={formState.isSubmitting}
            onSubmit={() => {
              hideVariantOptions()
            }}
            buttonText="Save Options"
          />
        </div>
      </div>
    </div>
  )
}

export default ProductOptionsManage
