import React, {useCallback, useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Dropzone from "react-dropzone";
import {faCloudUpload} from "@fortawesome/pro-duotone-svg-icons";
import ImageToolImageService from './image-tool-image-service';
import {faTimesCircle} from "@fortawesome/pro-duotone-svg-icons";
import {v4 as uuidv4} from "uuid"
import {DndProvider} from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDrag, useDrop } from 'react-dnd';

const ImageTool = ({ onImageSelected, entityImages, onImageDeleted, onResortImages }) => {
    const [images, setImages] = useState([]);
    const [uploadedFile, setUploadedFile] = useState(null)

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragItem = images[dragIndex]

        setImages((prevState => {
            const copiedStateArray = [...prevState]
            const prevItem = copiedStateArray.splice(hoverIndex, 1, dragItem)
            copiedStateArray.splice(dragIndex, 1, prevItem[0])

            console.log("moveCard")
            console.log(copiedStateArray)

            onResortImages(copiedStateArray)

            return copiedStateArray
        }))

        // setImages(update(images, {
        //     $splice: [
        //         [dragIndex, 1],
        //         [hoverIndex, 0, dragCard],
        //     ],
        // }))

    }, [images]);

    const onDrop = useCallback(acceptedFiles => {
        setUploadedFile(acceptedFiles[0])
        addImage({id: uuidv4(), location: URL.createObjectURL(acceptedFiles[0])})
        onImageSelected(acceptedFiles[0]);
    });

    useEffect(() => {
        getImages()
    }, [entityImages])

    const getImages = () => {
        setImages(entityImages);

        // ImageToolImageService.retrieveItems().then(items => {
        //     console.log(items);
        //     setImages(items);
        // });
    }

    const addImage = (item) => {
        ImageToolImageService.createItem(item).then(item => {
            setImages(images.concat(item))
        });
    }

    const deleteImage = (item) => {
        onImageDeleted(item)

        ImageToolImageService.deleteItem(item).then(id => {
            setImages(images.filter(x => x.id !== id).map(item => item))
        });
    }

    return (
        <div className="">
            <div className="font-bold text-gray-900 text-xl my-2">Images</div>
            <div className="flex w-full">
                <ImageUploadPanel onDrop={onDrop} />
            </div>
            <DndProvider backend={HTML5Backend}>
                <div className="flex flex-wrap justify-start space-x-4">
                        {images && images
                            //.sort(function(a,b){ return (a.displayOrder > b.displayOrder) ? 1 : -1})
                            .map((image, index) => {
                            return image ? <ImageToolCard key={image.id} index={index} data={image} onClick={deleteImage} moveCard={moveCard} /> : <div>empty</div>
                        })}
                </div>
            </DndProvider>
        </div>
    )
};

export default ImageTool;

const ImageUploadPanel = ({onDrop}) => {
    return (
        <div className="mt-2 mb-4 w-full">
            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()} className="flex flex-col h-32 items-center justify-center overflow-hidden rounded-md p-4 bg-gray-100 hover:bg-pixie hover:bg-opacity-10 cursor-pointer focus:outline-none">
                            <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faCloudUpload} size="2x" style={{"--fa-primary-color":"#d6372c", "--fa-secondary-color":"#d6372c"}}  />
                            <div className="text-sm mt-2 text-gray-500  p-1 text-center">Drop image or click to upload image</div>
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    )
};

const ImageToolCard = ({data, index, onClick, moveCard}) => {

    const ref = useRef(null);
    const id = data.id

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div className="inline-block relative text-gray-800 font-semibold mr-2 mt-2"  data-handler-id={handlerId} ref={ref} style={{ opacity }}>
            <img src={data.location} className="block h-32 w-32  hover:opacity-60 cursor-pointer border-2 border-gray-200 hover:border-2 hover:border-gray-400" alt="upload file" />
            <button onClick={() => onClick(data.id)} className="absolute top-3 right-3 cursor-pointer focus:outline-none">
                <FontAwesomeIcon icon={faTimesCircle} size="lg" style={{"--fa-primary-color":"transparent", "--fa-secondary-color":"black"}}  />
            </button>
            {/*<div>{data.displayOrder}</div>*/}
        </div>
    )
}

const ItemTypes = {
    CARD: 'card',
}
