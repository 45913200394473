import React from 'react'
import { useParams } from 'react-router'

const PaymentsStripeTest = () => {
  const { paymentStatus } = useParams<{ paymentStatus: string }>()

  return (
    <div className="px-4 mx-auto max-w-7xl w-full bg-white my-8">
      <div className="w-full p-8">
        <div className="text-lg font-bold">Stripe Payment Status</div>
        <div className="mt-4 text-lg font-bold text-green-400">{paymentStatus}</div>
      </div>
    </div>
  )
}

export default PaymentsStripeTest
