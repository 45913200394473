import { SlotScheduleDay, SlotSchedule, SlotTimeType, SlotDayType } from './models'
import addDays from 'date-fns/addDays'
import { addMinutes, format, getDay, startOfWeek } from 'date-fns'

export function GenerateSchedule(date: Date, slotCount: number = 1): SlotSchedule {
  const dateFrom = startOfWeek(date)
  let schedule: SlotSchedule

  const dayScheduleTypes: SlotScheduleDay[] = [0, 1, 2, 3, 4, 5, 6].map((day) => {
    const date = addDays(dateFrom, day)
    return {
      DayType: { Number: getDay(date), Display: format(date, 'EEEE') },
      Times: GenerateTimesBySlotCountPerHour(date, slotCount),
    } as SlotScheduleDay
  })

  schedule = {
    DateFrom: dateFrom,
    DateTo: addDays(dateFrom, 7),
    SlotsPerHour: 1,
    DayScheduleTypes: dayScheduleTypes,
  } as SlotSchedule

  return schedule
}

export function GenerateScheduleDay(dayNumber: number, dayDisplay: string, times: SlotTimeType[]): SlotScheduleDay {
  let day: SlotScheduleDay

  day = {
    DayType: {
      Number: dayNumber,
      Display: dayDisplay,
    } as SlotDayType,
    Times: times,
  } as SlotScheduleDay

  return day
}

export function GenerateTimesBySlotCountPerHour(date: Date, slotCount: number = 1): SlotTimeType[] {
  const times = GenerateTimes(date, ConvertSlotCountPerHourToMinutes(slotCount))
  console.log(times)

  return times
}

function GenerateTimes(date: Date, minuteInterval: number = 30): SlotTimeType[] {
  let times: SlotTimeType[] = []
  let tt = 0
  let ap = ['AM', 'PM']

  let nextDate: Date = date
  for (let i = 0; tt < 24 * 60; i++) {
    let hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    let mm = tt % 60 // getting minutes of the hour in 0-55 format
    let dateFrom = nextDate
    nextDate = addMinutes(nextDate, minuteInterval)
    let dateTo = nextDate
    const time = ('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12)] // pushing data in array in [00:00 - 12:00 AM/PM format]
    times[i] = {
      Display: time,
      Open: false,
      Hours: hh,
      Minutes: mm,
      DateFrom: dateFrom,
      DateTo: dateTo,
    }
    tt = tt + minuteInterval
  }

  return times
}

function ConvertSlotCountPerHourToMinutes(slotCount: number) {
  let minuteInterval: number
  switch (slotCount) {
    case 1:
      minuteInterval = 60
      break
    case 2:
      minuteInterval = 30
      break
    case 3:
      minuteInterval = 20
      break
    case 4:
      minuteInterval = 15
      break
    default:
      minuteInterval = 60
  }

  return minuteInterval
}

export { GenerateTimes, ConvertSlotCountPerHourToMinutes }
