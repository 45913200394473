import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import {
  CatalogType,
  IProductAttribute,
  IProductAttributeMapping,
  IProductDetails,
} from '../../../models/interface'
import { ProductOptionPanel } from './productOptionPanel'
import update from 'immutability-helper'
import _uniqueId from 'lodash/uniqueId'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'
import _ from 'lodash'

interface ProductOptionsPanelProps {
  catalogType: CatalogType
  title: string
  product: IProductDetails
}

class ProductOption {
  id: any
  name?: string
  productOption?: IProductAttributeMapping

  constructor(id: any, name?: string, productOption?: IProductAttributeMapping) {
    this.id = id
    this.name = name
    this.productOption = productOption
  }
}

const ProductOptionsPanel: FC<ProductOptionsPanelProps> = ({ catalogType, title, product }) => {
  const [productOptions, setProductOptions] = useState<ProductOption[]>([])
  const productOptionsDispatch = useContext(ProductDispatchContext)
  const productOptionsState = useContext(ProductStateContext)

  useEffect(() => {}, [product])

  useEffect(() => {
    if (_.size(productOptionsState.product?.attributes) > 0) {
      const attributes = _.concat(productOptionsState.product?.attributes!)
      const attributeProductOptions = attributes
        ?.sort(function (a, b) {
          return a.displayOrder > b.displayOrder ? 1 : -1
        })
        .map((attributeMapping) => {
          return new ProductOption(_uniqueId(), attributeMapping?.attribute?.name, attributeMapping)
        })
      setProductOptions(attributeProductOptions!)
    }
  }, [productOptionsState.product])

  const onAddAttribute = () => {
    if (_.size(productOptionsState.productAttributes) > 0) {
      const attributeMappings = productOptionsState.product?.attributes
      const maxDisplayOrder =
        _.size(attributeMappings) === 0
          ? 0
          : Math.max.apply(
              Math,
              attributeMappings!.map(function (o) {
                return o.displayOrder
              })
            )

      const attribute: IProductAttribute = {
        id: _.first(productOptionsState.productAttributes)?.id,
      }
      const productAttributeMapping: IProductAttributeMapping = {
        id: '00000000-0000-0000-0000-000000000000',
        attribute: attribute,
        attributeValues: [],
        displayOrder: maxDisplayOrder + 1,
        displayType: 'Dropdown',
      }
      productOptionsDispatch({
        type: 'addAttribute',
        productAttributeMapping: productAttributeMapping,
      })
    }
  }

  const moveProductOption = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = productOptions[dragIndex]
      const productOptionsReSorted = update(productOptions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
      productOptionsDispatch({
        type: 'reSortAttributes',
        productAttributes: productOptionsReSorted.map((item) => {
          return item.productOption?.attribute ?? {}
        }),
      })
    },
    [productOptions]
  )

  return (
    <>
      <div className="font-bold text-gray-800/90 mb-4">{title}</div>

      <div className="mt-2 mb-6 divide-y">
        <DndProvider backend={HTML5Backend}>
          {productOptions?.map((variantOption, index) => (
            <ProductOptionPanel
              key={variantOption.id}
              id={variantOption.id}
              index={index}
              productAttributeMapping={variantOption.productOption!}
              moveProductOption={moveProductOption}
              attributes={productOptionsState.productAttributes}
            />
          ))}
        </DndProvider>
      </div>

      <button
        className="bg-pixie px-2 text-white font-medium text-sm rounded-md py-1 mt-10"
        onClick={onAddAttribute}>
        Add Option
      </button>
    </>
  )
}

export { ProductOptionsPanel }
