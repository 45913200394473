import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import { CatalogType, IProductType } from '../../models/interface'
import { useHistory } from 'react-router-dom'
import { faEllipsisVAlt } from '@fortawesome/pro-solid-svg-icons'
import { Menu, Transition } from '@headlessui/react'

interface Props {
  productType: IProductType
  catalogType: CatalogType
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const ProductTypeCardComponent: React.FC<Props> = ({ productType, catalogType }) => {
  const history = useHistory()

  const showProducts = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    history.push(`/producttypes/${productType.id}`, {
      title: productType.title,
      catalogType: catalogType,
    })
  }

  const showChildProductTypes = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (catalogType === CatalogType.Ecommerce) {
      history.push(`/ecommerceproducttypes`, {
        parentProductTypeId: productType.id,
        parentProductTypeTitle: productType.title,
      })
    }
    if (catalogType === CatalogType.Experience) {
      history.push(`/experienceproducttypes`, {
        parentProductTypeId: productType.id,
        parentProductTypeTitle: productType.title,
      })
    }
  }

  return (
    <div className="flex justify-between px-2 py-2">
      <div className="ml-4 text-base text-gray-800 font-normal">{productType.title}</div>
      <Menu as="div" className="text-left bg-white">
        {({ open }) => (
          <>
            <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm hover:bg-gray-50 focus:outline-none">
              <FontAwesomeIcon icon={faEllipsisVAlt} size={'1x'} className="text-gray-400" />
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items
                static
                className="origin-top-right mr-10 z-20 absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={showChildProductTypes}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full'
                        )}>
                        {catalogType === CatalogType.Ecommerce
                          ? 'Child Product Types'
                          : 'Child Event Types'}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}
