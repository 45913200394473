import { ICategory, ICategoryTree, IProductDetails } from '../../../models/interface'
import React, { FC, useContext, useState } from 'react'
import { ProductDispatchContext, ProductStateContext } from '../productdetails'

type Props = {
  product: IProductDetails
  category: ICategoryTree
}

const CategoryItem: FC<Props> = ({ product, category }) => {
  const [productDetails, setProductDetails] = useState<IProductDetails>(product)
  const [isChecked, setIsChecked] = useState<boolean>(
    product.categories?.findIndex((x) => x.id === category.id) !== -1
  )
  const productOptionsDispatch = useContext(ProductDispatchContext)
  const productOptionsState = useContext(ProductStateContext)

  const onCategorySelected = (checked: boolean) => {
    setIsChecked(checked)
    if (checked) {
      productOptionsDispatch({ type: 'addCategory', category: category })
    } else {
      productOptionsDispatch({ type: 'deleteCategory', category: category })
    }
  }

  return (
    <div className="flex flex-col">
      <div className="flex space-x-2 my-2 items-center">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => onCategorySelected(e.target.checked)}
          className="h-4 w-4 text-pixie focus:outline-none"
        />
        <div className="text-sm">{category.title}</div>
      </div>
      {category?.childCategories && (
        <div className="pl-8">
          {category?.childCategories
            .slice()
            ?.sort(function (a, b) {
              return a.displayOrder !== undefined &&
                b.displayOrder !== undefined &&
                a.displayOrder > b.displayOrder
                ? 1
                : -1
            })
            .map((category: ICategory) => {
              return <CategoryItem key={category.id} product={product} category={category} />
            })}
        </div>
      )}
    </div>
  )
}

export default CategoryItem
